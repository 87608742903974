import React from 'react';
import { Box, Flex } from '../Rebass';
import Heading from '../Heading';
import theme from '../../theme';
/** Component for rendering announcements */
const Announcement = ({ headline, children, sx }) => (React.createElement(Box, { "data-testid": "announcement", sx: sx },
    React.createElement(Flex, { flexDirection: "column", justifyContent: "space-between" },
        headline && (React.createElement(Box, { sx: { mb: 4 } },
            React.createElement(Heading, { size: "h1", color: theme.colors.textBlack, fontFamily: theme.fonts.variable, sx: { mb: 1, fontSize: '36px' } }, headline))),
        children && React.createElement(Box, null, children))));
export default Announcement;
