import { colors, spacing } from '../../../common';

export const displayStyles = {
  base: {
    borderColor: 'transparent',
  },
  border: {
    borderColor: colors.borderLight,
  },
  background: {
    backgroundColor: colors.bgLight,
    borderColor: 'transparent',
  },
  backgroundDark: {
    backgroundColor: colors.bgDark,
    borderColor: 'transparent',
  },
  backgroundBrand: {
    backgroundColor: colors.bgBrand,
    borderColor: 'transparent',
  },
  backgroundBrandBiege: {
    backgroundColor: colors.brandBeige,
    borderColor: 'transparent',
  },
  backgroundLightNew: {
    backgroundColor: colors.bgLightNew,
    borderColor: 'transparent',
  },
  backgroundBaseWhite: {
    backgroundColor: colors.baseWhite,
    borderColor: 'transparent',
  },
};

export const sizeStyles = {
  base: {
    padding: spacing.space3,
  },
  small: {
    padding: spacing.space1,
  },
  none: {
    padding: '0px',
  },
};
