import styled, { css } from 'styled-components';

import { spacing } from '../../../common/styles/variables';

const display = (props) => {
  return css``;
};

export const StyledCard = styled.div`
  ${display};
  display: inline-block;
  width: 100%;
  margin-bottom: ${spacing.space6};
`;

export const StyledFigure = styled.figure`
  background-size: cover;
  background-position: center center;
  margin: 0;
  padding-bottom: 60%;
`;
