import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../common';
import { Space, Border, Text } from '../../components';

class ResultUnit extends React.Component {
  render() {
    const {
      unit,
      units,
      rate,
      children,
      renderAction,
      renderDetails,
    } = this.props;

    return (
      <Space>
        <Space bottom size={spacing.space1}>
          <Border />
        </Space>

        <div className="d-flex justify-content-between">
          <div>
            {unit && (
              <Space inline right size={spacing.space2}>
                <Text display="secondary" level="span" inline>
                  {unit}
                </Text>
              </Space>
            )}
            {rate && (
              <Text display="small" inline level="small">
                {rate}
              </Text>
            )}
            {units && (
              <Text display="formLabel" level="small">
                {units}
              </Text>
            )}

            {renderDetails && (
              <Space bottom size={spacing.space1}>
                {renderDetails}
              </Space>
            )}
          </div>

          {renderAction && (
            <Space
              bottom
              size={spacing.space05}
              className="d-flex align-items-end"
            >
              {renderAction()}
            </Space>
          )}
        </div>
        {children}
      </Space>
    );
  }
}

ResultUnit.propTypes = {
  /** Unit Name */
  unit: PropTypes.string,
  /** Rate of unit */
  rate: PropTypes.string,
  /** Suggested dates */
  dates: PropTypes.string,
  /** Render prop for details links */
  renderDetails: PropTypes.node,
};

ResultUnit.defaultProps = {
  unit: null,
  rate: null,
  dates: null,
  renderAction: null,
  renderDetails: null,
};

export default ResultUnit;
