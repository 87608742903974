import gql from 'graphql-tag';

export const QUERY_PAGE = gql`
  query viewPage($slug: String!) {
    viewPage(slug: $slug) {
      slug
      title
      content
      acf
      hero {
        source_url
      }
    }
  }
`;
