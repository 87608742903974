import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import styled from 'styled-components';

const FrameProps = {
  init: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
  },
  zoom: {
    applyAtStart: { display: 'block' },
    opacity: 1,
  },
};

const StyledFrame = styled(posed.div(FrameProps))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: white;
  transform: translateZ(0);
  z-index: 99;
`;

const transition = {
  duration: 400,
  ease: [0.08, 0.69, 0.2, 0.99],
};

const ImageProps = {
  init: {
    position: 'static',
    width: 'auto',
    height: 'auto',
    transition,
    flip: true,
  },
  zoom: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition,
    flip: true,
  },
};

const StyledImageZoom = styled(posed.img(ImageProps))`
  margin: 0;
  padding: 0;
  flex: none;
  width: ${(props) => (props.fluid ? '100%' : 'auto')};
  height: auto;
  max-width: 100%;
  display: ${(props) => (props.fixed ? 'inline-block' : 'block')};
  margin: auto;
  z-index: 999;
  &:hover {
    cursor: ${(props) => (props.zoom ? 'zoom-out' : 'zoom-in')};
  }
`;

class ImageZoom extends React.Component {
  state = { isZoomed: false };

  zoomIn() {
    window.addEventListener('scroll', this.zoomOut);
    this.setState({ isZoomed: true });
  }

  zoomOut = () => {
    window.removeEventListener('scroll', this.zoomOut);
    this.setState({ isZoomed: false });
  };

  toggleZoom = () => (this.state.isZoomed ? this.zoomOut() : this.zoomIn());

  render() {
    const { isZoomed } = this.state;
    const { src, alt, fixed, fluid, imageWidth, imageHeight, ...props } =
      this.props;

    const pose = isZoomed ? 'zoom' : 'init';

    return (
      <div
        style={{ width: imageWidth, height: imageHeight }}
        onClick={this.toggleZoom}
      >
        <StyledFrame pose={pose} />

        <StyledImageZoom
          fixed={fixed}
          src={src}
          alt={alt}
          fluid={fluid}
          onClick={this.toggleZoom}
          pose={pose}
          zoom={this.state.isZoomed}
          {...props}
        />
      </div>
    );
  }
}

ImageZoom.defaultProps = {
  fixed: false,
  src: null,
  fluid: true,
};

ImageZoom.propTypes = {
  /** Alt text for the image */
  alt: PropTypes.string.isRequired,
  /** Image src */
  src: PropTypes.string.isRequired,
  /** Is this image not fluid */
  fixed: PropTypes.bool.isRequired,
  /** Is the Image responsive? */
  fluid: PropTypes.bool.isRequired,
};

export default ImageZoom;
