import * as React from 'react';
import {
  Space,
  Box,
  Text,
  Button,
  ImageZoom,
  Border,
  // ButtonText,
  Tabs,
  Tab,
  Hero,
} from '../../../components';
import { spacing, language } from '../../../common';
import WistiaEmbed from './WistiaEmbed';
import { TabWrapper } from '../../Navigation/TabContainer';
import { HeaderModal } from '../../Navigation/ui/HeaderModal';

export const LodgePage = ({ lodge, history }) => {
  const {
    lodgeDetails: {
      title,
      content,
      hero,
      videos,
      activities,
      mapImage,
      acf: layout,
    },
  } = lodge;

  const brochure = layout.downloads.find((d) => d.key === 'brochure');

  return (
    <React.Fragment>
      <HeaderModal
        closeModal={() => history.goBack()}
        title={<Text display="title">{lodge.name}</Text>}
      />
      <Hero
        small
        items={[
          {
            subTitle: layout.subtitle,
            title: layout.headline,
            image: hero ? hero.source_url : null,
            bgPosition: layout.hero_image_hotspot
              ? layout.hero_image_hotspot.split(',').join(' ')
              : null,
          },
        ]}
      />
      <Space top bottom size={spacing.space5}>
        <Space bottom size={spacing.space5}>
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <Space bottom size={spacing.space2}>
                  <Space top size={spacing.space3}>
                    <Text display="meta">
                      {lodge.region.name} - {lodge.region.country}
                    </Text>
                  </Space>
                  <Text display="headline" html={content} />
                </Space>
                {layout.introduction.map((c, index) => (
                  <Text
                    key={`content${index}`}
                    display="intro"
                    html={c.content}
                  />
                ))}
              </div>
              <div className="col-lg-4 offset-lg-1">
                <Box display="border" renderTitle={language.lodge.unitTypes}>
                  <Space bottom size={spacing.space3}>
                    {lodge.units
                      .filter((u) => u.numberOfUnits > 0)
                      .map((unit) => (
                        <Text display="secondary" level="h5" key={unit.code}>
                          {unit.numberOfUnits} x {unit.name}
                        </Text>
                      ))}
                  </Space>
                  <Space>
                    {brochure && (
                      <Space top size={spacing.space2}>
                        <a
                          href={brochure.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button display="outline" block>
                            Download {brochure.title}
                          </Button>
                        </a>
                      </Space>
                    )}
                    {layout.downloads
                      .filter((r) => r.key === 'rates')
                      .map((rate, index) => (
                        <a
                          key={`ratesDownload${index}`}
                          href={rate.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Space top size={spacing.space2}>
                            <Button display="outline" block>
                              Download {rate.title}
                            </Button>
                          </Space>
                        </a>
                      ))}
                  </Space>
                </Box>
              </div>
            </div>
            <Space bottom top size={spacing.space5}>
              <Border />
            </Space>
          </div>
        </Space>

        <Space bottom size={spacing.space5}>
          <div className="container">
            <Space bottom size={spacing.space2}>
              <Text display="headline">{language.lodge.titleTour}</Text>
            </Space>
            <div className="row">
              <div className="col-12">
                <Text display="title" level="p">
                  {language.lodge.titleImages}
                </Text>
              </div>
              {layout.images.slice(0, 4).map((image) => (
                <div className="col-6 col-sm-3">
                  <Space bottom size={spacing.space3}>
                    <Space bottom size={spacing.space1}>
                      <ImageZoom
                        fluid
                        src={image.sizes['medium_large']}
                        alt={image.alt}
                      />
                    </Space>
                    <Text display="body">{image.alt}</Text>
                  </Space>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-12">
                <Text display="title" level="p">
                  {language.lodge.titleVideos}
                </Text>
              </div>
              {videos.map((v) => (
                <div className="col-6 col-sm-3">
                  <Space bottom size={spacing.space3}>
                    <Space bottom size={spacing.space1}>
                      <WistiaEmbed
                        hashedId={v.videoId}
                        controlsVisibleOnLoad={false}
                      />
                    </Space>
                    <Text display="body" html={v.title} />
                  </Space>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-12">
                <Text display="title" level="p">
                  {language.lodge.titleWtd} {title}
                </Text>
              </div>
              {activities.slice(0, 4).map((act) => (
                <div className="col-6 col-sm-3">
                  <Space bottom size={spacing.space3}>
                    <Space bottom size={spacing.space1}>
                      <ImageZoom
                        fluid
                        src={
                          act.featured_media
                            ? act.featured_media.source_url
                            : null
                        }
                        alt={act.title}
                      />
                    </Space>
                    <Text display="body">{act.title}</Text>
                  </Space>
                </div>
              ))}
            </div>
            <Space bottom top size={spacing.space5}>
              <Border />
            </Space>
          </div>
        </Space>

        <Space bottom size={spacing.space5}>
          <div className="container">
            <Space bottom size={spacing.space2}>
              <Text display="headline">{layout.location.headline}</Text>
            </Space>
            <div className="row">
              <div className="col-12">
                <Text display="small" level="p">
                  {lodge.region.name}, {lodge.region.country}
                </Text>
              </div>
              <div className="col-lg-7">
                <Text display="body" html={layout.location.content} />
              </div>

              <div className="col-12">
                <Space top bottom size={spacing.space3}>
                  <ImageZoom
                    fluid
                    src={mapImage ? mapImage.source_url : null}
                    alt={'Map'}
                  />
                </Space>
              </div>
            </div>
            <Space bottom top size={spacing.space5}>
              <Border />
            </Space>
          </div>
        </Space>

        <Space bottom size={spacing.space5}>
          <div className="container">
            <Space bottom size={spacing.space2}>
              <div className="d-flex flex-column">
                <Text display="headline">{language.lodge.titleRates}</Text>
                <Space top bottom size={spacing.space2}>
                  {layout.downloads
                    .filter((r) => r.key === 'rates')
                    .map((rate, index) => (
                      <Space
                        key={`ratesDownload${index}`}
                        right
                        inline
                        size={spacing.space2}
                      >
                        <a
                          href={rate.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* <ButtonText iconAfter="download-thin">
                            Download {rate.title}
                          </ButtonText> */}
                        </a>
                      </Space>
                    ))}
                </Space>
              </div>
            </Space>
          </div>
        </Space>

        <Space bottom size={spacing.space5}>
          <div className="container">
            <Space bottom size={spacing.space2}>
              <div className="d-flex flex-column">
                <Text display="headline">{language.lodge.titleInfo}</Text>
                <Space top bottom size={spacing.space2}>
                  {layout.downloads
                    .filter((r) => r.key !== 'rates')
                    .map((rate, index) => (
                      <Space
                        key={`ratesDownload${index}`}
                        inline
                        right
                        size={spacing.space2}
                      >
                        <a
                          href={rate.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* <ButtonText iconAfter="download-thin">
                            Download {rate.title}
                          </ButtonText> */}
                        </a>
                      </Space>
                    ))}
                </Space>
              </div>
            </Space>

            <TabWrapper
              render={(currentTab, setTab) => (
                <React.Fragment>
                  <Tabs>
                    {layout &&
                      layout.sections &&
                      layout.sections.map((s, index) => (
                        <Tab
                          active={index === currentTab}
                          onClick={() => setTab(index)}
                        >
                          {s.heading}
                        </Tab>
                      ))}
                  </Tabs>
                  <Space padding top size={spacing.space0}>
                    <div className="row">
                      {layout.sections &&
                        layout.sections[currentTab].content.map((c, index) => {
                          let classN;

                          if (
                            layout.sections[currentTab].content.length === 1
                          ) {
                            classN = 'col-md-8';
                          } else {
                            classN = `col-md-${
                              12 / layout.sections[currentTab].content.length
                            }`;
                          }

                          return (
                            <div key={`tabs${index}`} className={classN}>
                              <Space bottom size={spacing.space2}>
                                {c.title ? (
                                  <Text display="title" level="p">
                                    Children and Infants
                                  </Text>
                                ) : null}
                                <Text display="body" html={c.content} />
                              </Space>
                            </div>
                          );
                        })}
                    </div>
                  </Space>
                </React.Fragment>
              )}
            />
          </div>
        </Space>
      </Space>
    </React.Fragment>
  );
};
