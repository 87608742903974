import { sizes, colors } from '../../../../common';

export const displayStyles = {
  base: {
    color: colors.textBrand,
    borderBottomColor: 'transparent',
    // For CSS hover
    colorHover: colors.textBrand,
    hover: {
      color: colors.textBrand,
      borderBottomColor: colors.textBrand,
    },
  },
  white: {
    color: colors.textWhite,
    borderBottomColor: 'transparent',
    // For CSS hover
    colorHover: colors.textWhite,
    hover: {
      color: colors.textWhite,
      borderBottomColor: colors.textWhite,
    },
  },
};

export const sizeStyles = {
  base: {
    fontSize: sizes.textMedium,
  },
  small: {
    fontSize: sizes.textSmall,
  },
  mini: {
    fontSize: sizes.textMini,
  },
};
