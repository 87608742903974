import React, { Component } from 'react';
import querystring from 'query-string';

const updateAfter = 700;
const searchStateToUrl = (searchState) =>
  searchState
    ? `${window.location.pathname}?${querystring.stringify(searchState, {
        arrayFormat: 'bracket',
      })}`
    : '';

export const withUrlSync = (App) =>
  class urlSync extends Component {
    constructor() {
      super();
      this.state = {
        searchState: querystring.parse(window.location.search.slice(1)),
      };
      window.addEventListener('popstate', ({ state: searchState }) =>
        this.setState({ searchState }),
      );
    }

    onSearchStateChange = (searchState) => {
      window.scrollTop = 0;
      clearTimeout(this.debouncedSetState);
      this.debouncedSetState = setTimeout(() => {
        window.history.pushState(
          searchState,
          null,
          searchStateToUrl(searchState),
        );
      }, updateAfter);
      this.setState({ searchState });
    };

    render() {
      return (
        <App
          {...this.props}
          searchState={this.state.searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={searchStateToUrl}
        />
      );
    }
  };
