import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  return {
    headers,
  };
});

const contentfulLink = createHttpLink({
  uri: process.env.REACT_APP_CONTENTFUL_API,
});

export const client = new ApolloClient({
  link: authLink.concat(
    ApolloLink.split(
      (operation) => operation.getContext().clientName === 'contentful',
      contentfulLink,
      httpLink,
    ),
  ),
  cache: new InMemoryCache(),
});
