import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { Loader } from '../../components';
import { Redirect } from 'react-router-dom';

export const AuthenticateUser = (props) => {
  const authToken = JSON.parse(localStorage.getItem('UserAuth'));

  if (!authToken) return <Redirect to="/access" from="/calendar" />;

  return (
    <Query
      query={AUTH_QUERY}
      variables={{ token: authToken.token }}
      fetchPolicy="no-cache"
    >
      {({ data, loading, error }) => {
        if (error) return <Redirect to="/access" from="/calendar" />;
        if (loading) return <Loader />;

        if (!data && !data.verifyToken) {
          localStorage.removeItem('userAuth');
          return <Redirect to="/access" />;
        }

        return props.render();
      }}
    </Query>
  );
};

export const AUTH_QUERY = gql`
  query verifyUser($token: String!) {
    verifyToken(token: $token) {
      _id
      email
    }
  }
`;
