import React from 'react';
import Space from '../Base/Space';
import Text from '../Base/Text/Text';
import { spacing } from '../../common';
import InputNumber from '../InputNumber';

class SelectIncrement extends React.PureComponent {
  render() {
    const { label, sub, left, value, onIncrement, onDecrement } = this.props;

    let checkOptions = {};

    if (onIncrement) {
      checkOptions.onIncrement = () => onIncrement();
    }

    if (onDecrement) {
      checkOptions.onDecrement = () => onDecrement();
    }

    let check = <InputNumber {...checkOptions} value={value} />;

    return (
      <Space bottom size={spacing.space2}>
        <div className="d-flex justify-content-between align-items-center">
          {left ? check : null}
          <div>
            <Text display="label" level="div">
              {label}
            </Text>
            {sub && (
              <Space top size={spacing.space1}>
                <Text display="meta" level="div">
                  {sub}
                </Text>
              </Space>
            )}
          </div>
          {!left ? check : null}
        </div>
      </Space>
    );
  }
}

SelectIncrement.defaultProps = {
  value: null,
  left: false,
  label: null,
  onClick: null,
  checked: false,
};

export default SelectIncrement;
