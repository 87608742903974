import * as React from 'react';
import { ContentModal, Text, Space } from '../../../components';

import { spacing } from '../../../common';
import { Button as NewButton } from '@singita/components';
import { RichTextRenderer } from '../../../components/RichTextRenderer';

export class UnitInformation extends React.PureComponent {
  state = {
    open: false,
  };

  render() {
    const { lodge, buttonText, contentfulLodge, contentfulUnit } = this.props;

    const headline = contentfulUnit ? contentfulUnit.headline : null;

    return (
      <React.Fragment>
        <NewButton
          variant="text"
          size={'default'}
          colorScheme={'textDark'}
          onClick={() => this.setState({ open: true })}
        >
          {buttonText}
          <Space inline left size={spacing.space1}>
            <i className="icon icon-chevron-right" />
          </Space>
        </NewButton>
        <ContentModal
          isOpen={this.state.open}
          closeModal={() => this.setState({ open: false })}
          renderTitle={() => <Text display="title">{lodge.name}</Text>}
          render={() => (
            <React.Fragment>
              <Space top bottom size={spacing.space10}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <Space bottom size={spacing.space4}>
                        <Space bottom size={spacing.space3}>
                          <Space bottom size={spacing.space3}>
                            <Text display="meta">
                              {contentfulLodge.region.name} -{' '}
                              {contentfulLodge.region.country}
                            </Text>
                          </Space>
                          <Space bottom size={spacing.space3}>
                            <Text display="headline" html={headline} />
                          </Space>
                          {contentfulUnit ? (
                            <RichTextRenderer
                              document={contentfulUnit.description.json}
                            />
                          ) : null}
                        </Space>
                      </Space>
                    </div>
                    <div className="col-lg-4 offset-lg-1"></div>
                  </div>
                </div>
              </Space>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

UnitInformation.defaultProps = {
  buttonText: 'Room & Rate Details',
};
