import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../common';
import { Space, Image, Text } from '../../components';
import { Slider } from '../../components';

/** Result lodge descriptions */
class ResultLodge extends React.Component {
  render() {
    const {
      lodge,
      img,
      images,
      imgTitle,
      region,
      children,
      renderAction,
      size,
    } = this.props;

    return (
      <Space bottom size={spacing.space8}>
        <div className="row">
          {img && !images ? (
            <div className="col-sm-4">
              <Space bottom size={spacing.space2}>
                <Image fluid src={img} alt={imgTitle} />
              </Space>
            </div>
          ) : null}
          {images && images.length > 1 ? (
            <div className="col-sm-4">
              <Space bottom size={spacing.space2}>
                <Slider pagerType="arrows" autoplay={false} lazyLoad={true}>
                  {images.map((i) => (
                    <Image key={i.id} fluid src={i.sizes[size]} alt={i.title} />
                  ))}
                </Slider>
              </Space>
            </div>
          ) : null}
          <div className="col-sm-8">
            <Space>
              <div className="row justify-content-between">
                <div className="col-8">
                  {lodge && (
                    <Text display="title" level="h5">
                      {lodge}
                    </Text>
                  )}
                </div>
                {renderAction && (
                  <div className="col-4 text-right">{renderAction}</div>
                )}
              </div>

              {region && (
                <Text display="meta" level="span">
                  {region}
                </Text>
              )}

              {children}
            </Space>
          </div>
        </div>
      </Space>
    );
  }
}

ResultLodge.propTypes = {
  /** Lodge Name */
  lodge: PropTypes.string,
  /** Image URL */
  img: PropTypes.string,
  /** Image Title */
  imgTitle: PropTypes.string,
  /** Lodge Region */
  region: PropTypes.string,
  /** Availible units in Lodge */
  results: PropTypes.node.isRequired,
  /** Render prop for actions */
  renderAction: PropTypes.node,
};

ResultLodge.defaultProps = {
  lodge: null,
  img: null,
  imgTitle: null,
  region: null,
  results: null,
  renderAction: null,
  size: 'blog-image-size',
};

export default ResultLodge;
