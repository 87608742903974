import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from './Style/ButtonStyle';

class Button extends React.Component {
  render() {
    const { children, display, block, size, onClick, hover, as, ...props } =
      this.props;

    return (
      <StyledButton
        onClick={onClick}
        hover={hover}
        display={display}
        block={block}
        size={size}
        as={as}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
}

Button.propTypes = {
  /** Sets the display style */
  size: PropTypes.oneOf(['base', 'small']),
  /** Sets the size of the component */
  display: PropTypes.oneOf(['base', 'outline', 'select']),
  /** Contents of the Box */
  children: PropTypes.node.isRequired,
  /** Renders the Title */
  block: PropTypes.bool,
  /** Click action */
  onClick: PropTypes.func,
  /** Hover action */
  hover: PropTypes.bool,
  /** Change the HTML element that is rendered */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Button.defaultProps = {
  display: 'base',
  size: 'base',
  children: null,
  block: false,
  hover: false,
  as: 'button',
  onClick: () => {},
};

export default Button;
