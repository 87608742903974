import React from 'react';
import Text from '../../../components/Base/Text/Text';
import Space from '../../../components/Base/Space';
import { spacing } from '../../../common';
import styled from 'styled-components';
const StyledHeader = styled.div`
  width: 100%;
  display: block;
  position: relative;
  background-repeat: repeat;
  background-position: center center;
  background-image: url(https://469uj5355hpj1cwksq2n1n1a-wpengine.netdna-ssl.com/wp-content/themes/singita/assets/img/video-bg.png);
`;
export const PageHeader = (props) => {
  return (
    <StyledHeader>
      <div className="container">
        <div className="row">
          <div className="col-md-7 m-auto">
            <Space padding top bottom size={spacing.space15}>
              {props.title ? (
                <Space bottom size={spacing.space3}>
                  <Text display="hero" align="center">
                    {props.title}
                  </Text>
                </Space>
              ) : null}
              {props.description ? (
                <Space bottom size={spacing.space3}>
                  <Text display="intro" align="center">
                    A comprehensive library of imagery and relevant information
                    in "easy-to-use", downloadable files
                  </Text>
                </Space>
              ) : null}
            </Space>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};
