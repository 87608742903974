import * as React from 'react';
import {
  Space,
  Text,
  Box,
  Button,
  ButtonText,
  Border,
  SelectCheckbox,
  Loader,
} from '../../../components';
import { spacing, language } from '../../../common';
import Sticky from 'react-stickynode';
import { BestMatchContainer } from '../../BestMatch/BestMatchContainer';
import { OtherLodges } from '../../Filters/OtherLodges';
import { formatCurrency } from '../../../utils/dates';
import { ItenaryResults } from '../../Cart/ui/ItenaryResults';
import { ContentModal } from '../../../components';
import Media from 'react-media';
import styled from 'styled-components';
import { AvailabilityContainer } from '../../Availability/AvailabilityContainer';
import { FormContainer } from '../../Form/FormContainer';
import EnquiryForm from '../../Form/ui/EnquiryForm';
import {
  Header,
  theme,
  ResultLodge,
  ResultLodgeNotification,
  Button as NewButton,
  Announcement,
  Card,
  Modal,
  Heading,
  Meta,
} from '@singita/components';
import parse from 'html-react-parser';
import { groupPromotions, shuffle } from '../../../utils/promotions';
import { RichTextRenderer } from '../../../components/RichTextRenderer';

const villaRegex = new RegExp('villa', 'i');

function filterPromotions(userStart, userEnd, promotions) {
  const startInput = new Date(userStart);
  const endInput = new Date(userEnd);

  const filteredPromotions = promotions.filter((promotion) => {
    if (promotion.promotionsDates) {
      return promotion.promotionsDates.some((dateRange) => {
        const [startPromotion, endPromotion] = dateRange
          .split('|')
          .map((dateStr) => new Date(dateStr));

        if (
          (startInput >= startPromotion && startInput <= endPromotion) ||
          (endInput >= startPromotion && endInput <= endPromotion)
        ) {
          return true;
        }

        return false;
      });
    }

    return false;
  });

  return groupPromotions(shuffle(filteredPromotions));
}
export class ResultsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      form: { startDate, endDate },
    } = this.props;

    this.state = {
      startDate: startDate,
      endDate: endDate,
      bestMatch: true,
      suggested: true,
      otherLodges: false,
      promotionsModal: { isOpen: false, promotionId: undefined },
      promotions: [],
    };
  }

  filterStateRegions(arr) {
    const {
      form: { regions },
    } = this.props;

    if (!regions.length) return arr;

    return arr.filter((r) => regions.includes(r.code));
  }

  getUnits = (units) => {
    const { bestMatch, suggested } = this.state;

    return units
      .filter((u) => {
        if (bestMatch && u.available.available) {
          return true;
        }

        if (suggested && u.bestMatch.length > 0) {
          return true;
        }

        return false;
      })
      .sort((a, b) => {
        return a.available.available === b.available.available
          ? 0
          : a.available.available
          ? -1
          : 1;
      });
  };

  getAlerts() {
    const {
      hasLodges,
      hasBestMatch,
      toddlerSearch,
      toddlerSearchResult,
    } = this.checkForResults();

    const alerts = [];
    let noResult = false;
    let title = language.results.title;

    if (!hasLodges && hasBestMatch) {
      alerts.push(language.results.noresult);
      title = language.results.bestMatchTitle;
    }

    if (toddlerSearch) {
      if (toddlerSearchResult) {
        alerts.push(language.results.toddleOtherResults);
      } else {
        alerts.push(language.results.zeroResult);
        alerts.push(language.formSelection.childrenNote);
      }
    } else {
      if (!hasLodges && !hasBestMatch) {
        noResult = true;
        alerts.push(language.results.zeroResult);
      }
    }
    if (alerts.length) {
      return (
        <React.Fragment>
          <Announcement
            headline={title}
            children={alerts.map((a) => (
              <Text display="label" html={a} />
            ))}
          />

          {noResult ? (
            <FormContainer
              formId="enquiry"
              render={(form) => (
                <Box renderTitle={'Submit Enquiry'}>
                  <Space bottom size={spacing.space3}>
                    <Text display="intro" html={form.description} />
                  </Space>
                  <EnquiryForm
                    form={form}
                    search={this.props.form}
                    onSubmit={this.props.onEnquirySubmit}
                    onSubmitComplete={() =>
                      this.props.history.push('/confirmation')
                    }
                    renderAfterFields={() => (
                      <>
                        <Space top size={spacing.space3}>
                          <Text display="formLabel">
                            <b>PLEASE NOTE:</b> These results indicate
                            availability and do not guarantee a booking. One of
                            our Singita Travel Advisors will contact you shortly
                            to plan your trip.
                          </Text>
                        </Space>
                      </>
                    )}
                  />
                </Box>
              )}
            />
          ) : null}
        </React.Fragment>
      );
    }

    return (
      <Announcement
        headline={title}
        children={<Text display="label" html={language.results.success} />}
      />
    );
  }

  checkForResults = () => {
    const {
      loading,
      results,
      form: { guests },
    } = this.props;

    let hasLodges = false;
    let hasBestMatch = false;
    let toddlerSearch = false;
    let hasFamily = false;
    let toddlerSearchResult = false;

    if (loading)
      return {
        hasLodges,
        hasBestMatch,
        toddlerSearch,
        toddlerSearchResult,
      };

    results.findRegions.forEach((r) => {
      const lodges = r.lodges.filter((l) => {
        return l.units.filter((u) => u.available.available).length;
      });

      const lodgesBestMatch = r.lodges.filter((l) => {
        return l.units.filter((u) => u.bestMatch.length).length;
      });

      const familyLodges = r.familyLodges.filter((l) => {
        return l.units.filter((u) => u.available.available).length;
      });

      if (lodges.length) {
        hasLodges = true;
      }

      if (familyLodges.length) {
        hasFamily = true;
      }

      if (lodgesBestMatch.length) {
        hasBestMatch = true;
      }
    });

    if (guests.toddler > 0 || guests.baby > 0) {
      if (!hasLodges && !hasBestMatch) {
        // no results for query
        toddlerSearch = true;
        if (hasFamily) {
          toddlerSearchResult = true;
        }
      }
    }

    return {
      hasLodges,
      hasBestMatch,
      toddlerSearch,
      toddlerSearchResult,
    };
  };

  renderOtherResults = () => {
    const { hasLodges } = this.checkForResults();

    if (!hasLodges) {
      return true;
    }

    return false;
  };

  getRate = (unit, format = false) => {
    const total = unit.available.rates.reduce((result, item) => {
      if (unit.hasGreenSeason && !item.isHighSeason) {
        result += item.green;
      } else {
        result += item.high;
      }
      return result;
    }, 0);

    if (format) {
      return formatCurrency(total, unit.currency);
    }

    return total;
  };

  sortRegions = (regions, toddlerSearch) => {
    if (!toddlerSearch) return regions;

    const { form } = this.props;

    if (!form.regions.length && form.lodges.length) {
      return regions.sort((a, b) => {
        const inArrayX = a.lodges
          .map((l) => l.code)
          .filter((l) => form.lodges.includes(l)).length;
        const inArrayY = b.lodges
          .map((l) => l.code)
          .filter((l) => form.lodges.includes(l)).length;

        return inArrayX === inArrayY ? 0 : inArrayX ? -1 : 1;
      });
    }

    return regions;
  };

  renderAvailabilityContainer() {
    const { onAdditional } = this.props;

    return (
      <AvailabilityContainer
        openFilterModal={this.props.openFilterModal}
        form={this.props.form}
        {...this.props}
        render={(regionsList) => (
          <React.Fragment>
            <Border />
            <SelectCheckbox
              value={'dates'}
              checked={this.state.suggested}
              label={language.formSelection.filterAlternateDate}
              onClick={() =>
                this.setState((state) => ({
                  suggested: !state.suggested,
                }))
              }
            />
            <OtherLodges
              loading={this.props.loading}
              results={regionsList}
              form={this.props.form}
              checked={this.props.loading ? false : this.renderOtherResults()}
              onSelectUpdate={(updates) => {
                this.setState(
                  (state) => ({
                    otherLodges: !state.otherLodges,
                  }),
                  () => onAdditional('additionalLodges', updates),
                );
              }}
            />
          </React.Fragment>
        )}
      />
    );
  }

  render() {
    const {
      results,
      loading,
      cart,
      form: { guests, startDate, endDate },
      promotions: ungroupedPromotions,
      lodges: contentfulLodges,
    } = this.props;
    const ac = this.renderAvailabilityContainer();

    const { toddlerSearch } = this.checkForResults();

    const promotions = filterPromotions(
      startDate,
      endDate,
      this.props.promotions,
    );

    return (
      <React.Fragment>
        <Header
          logoColor={theme.colors.brandBrown}
          renderButton={() => (
            <ButtonText
              as={'a'}
              href="https://singita.com"
              iconBefore="login"
              size="small"
            >
              Back to Singita.com
            </ButtonText>
          )}
        />
        <Space bottom top size={spacing.space6}>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-7 resultsLayout">
                {loading ? (
                  <Loader />
                ) : (
                  <React.Fragment>
                    <Space bottom size={spacing.space4}>
                      {this.getAlerts()}
                    </Space>
                    <Border />

                    <Space bottom size={spacing.space6} />

                    <Space bottom size={spacing.space8}>
                      {this.sortRegions(results.findRegions, toddlerSearch).map(
                        (r) => {
                          const lodges = toddlerSearch
                            ? r.familyLodges
                            : r.lodges;

                          const results = lodges.filter((l) => {
                            const u = l.units.filter((u) => {
                              if (u.available.available) {
                                return true;
                              }

                              if (this.state.suggested) {
                                return u.bestMatch.length;
                              }

                              return false;
                            });

                            return u.length;
                          });

                          return (
                            <>
                              {results.map((l) => {
                                const lodgePromotions =
                                  promotions.lodges[l.code] ?? [];

                                const contentfulLodge = contentfulLodges.find(
                                  (lodge) =>
                                    lodge.code === l.code &&
                                    !villaRegex.test(lodge.fullTitle),
                                );

                                const prom =
                                  lodgePromotions && lodgePromotions.length > 0
                                    ? lodgePromotions[0]
                                    : undefined;

                                const images = contentfulLodge.highlights.items.map(
                                  (l) => ({
                                    id: l.sys.id,
                                    src: l.image.url + '?w=300&q=75',
                                    alt: l.altText,
                                  }),
                                );

                                return (
                                  <Box size={'none'}>
                                    <ResultLodge
                                      key={l.code}
                                      img={contentfulLodge?.image?.url}
                                      imgTitle={null}
                                      lodge={contentfulLodge.fullTitle}
                                      region={contentfulLodge.region.name}
                                      results={[]}
                                      images={images}
                                      renderCta={
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={`https://singita.com/lodge/${contentfulLodge.slug}`}
                                        >
                                          <NewButton
                                            variant="text"
                                            size={'large'}
                                          >
                                            {language.lodge.btnDetails}
                                            <Space
                                              inline
                                              left
                                              size={spacing.space1}
                                            >
                                              <i className="icon icon-chevron-right" />
                                            </Space>
                                          </NewButton>
                                        </a>
                                      }
                                    >
                                      {this.getUnits(l.units).filter(
                                        (u) => u.available.available,
                                      ).length ? (
                                        <>
                                          <Border />
                                          <ResultLodgeNotification
                                            variant="match"
                                            availableTitle={
                                              language.lodge.availableTitle
                                            }
                                            availableDescription={parse(
                                              language.lodge
                                                .availableDescription,
                                            )}
                                          />
                                        </>
                                      ) : null}
                                      {this.getUnits(l.units)
                                        .filter((u) => u.available.available)
                                        .map((u) => {
                                          const contentfulUnit = contentfulLodge.units.items.find(
                                            (unit) =>
                                              unit.unitCode ===
                                              u.bookingEngineCode,
                                          );

                                          return (
                                            <BestMatchContainer
                                              key={u.code}
                                              suggested={this.state.suggested}
                                              unit={u}
                                              lodge={l}
                                              startDate={startDate}
                                              guests={guests}
                                              onGetRate={this.getRate}
                                              cart={cart}
                                              contentfulLodge={contentfulLodge}
                                              contentfulUnit={contentfulUnit}
                                            />
                                          );
                                        })}
                                      <Border />
                                      {this.getUnits(l.units).filter(
                                        (u) => !u.available.available,
                                      ).length ? (
                                        <Space>
                                          <ResultLodgeNotification
                                            variant={
                                              !this.getUnits(l.units).filter(
                                                (u) => u.available.available,
                                              ).length
                                                ? 'bestMatch'
                                                : 'otherMatch'
                                            }
                                            availableTitle={
                                              !this.getUnits(l.units).filter(
                                                (u) => u.available.available,
                                              ).length
                                                ? language.lodge
                                                    .noteNotAvailibleTitle
                                                : language.lodge
                                                    .otherAvailableTitle
                                            }
                                            availableDescription={
                                              !this.getUnits(l.units).filter(
                                                (u) => u.available.available,
                                              ).length
                                                ? parse(
                                                    language.lodge
                                                      .noteNotAvailible,
                                                  )
                                                : parse(
                                                    language.lodge
                                                      .otherAvailable,
                                                  )
                                            }
                                          />
                                          {this.getUnits(l.units)
                                            .filter(
                                              (u) => !u.available.available,
                                            )
                                            .map((u) => {
                                              const contentfulUnit = contentfulLodge.units.items.find(
                                                (unit) =>
                                                  unit.unitCode ===
                                                  u.bookingEngineCode,
                                              );
                                              return (
                                                <BestMatchContainer
                                                  key={u.code}
                                                  startDate={startDate}
                                                  suggested={
                                                    this.state.suggested
                                                  }
                                                  unit={u}
                                                  lodge={l}
                                                  guests={guests}
                                                  onGetRate={this.getRate}
                                                  cart={cart}
                                                  contentfulLodge={
                                                    contentfulLodge
                                                  }
                                                  contentfulUnit={
                                                    contentfulUnit
                                                  }
                                                />
                                              );
                                            })}
                                        </Space>
                                      ) : null}
                                      {prom ? (
                                        <>
                                          <LodgePromotionContainer>
                                            <Card
                                              variant="strip"
                                              size="standard"
                                              meta={[
                                                {
                                                  text: 'Exclusive Offer',
                                                  color: theme.colors.baseBlack,
                                                },
                                              ]}
                                              headline={prom.headline}
                                              renderImage={() => (
                                                <PromotionImage
                                                  src={prom.featuredImage.url}
                                                />
                                              )}
                                              imagePosition="right"
                                              bg="bgLight"
                                              renderCta={() => (
                                                <NewButton
                                                  variant="text"
                                                  colorScheme={'brandBrown'}
                                                  size="small"
                                                  onClick={() => {
                                                    this.setState((state) => ({
                                                      ...state,
                                                      promotionsModal: {
                                                        isOpen: true,
                                                        promotionId:
                                                          prom.sys.id,
                                                      },
                                                    }));
                                                  }}
                                                >
                                                  View Exclusive Offer
                                                </NewButton>
                                              )}
                                            />
                                          </LodgePromotionContainer>
                                          <Border />
                                        </>
                                      ) : null}
                                    </ResultLodge>
                                    <Space bottom size={'space4'} />
                                  </Box>
                                );
                              })}
                            </>
                          );
                        },
                      )}
                    </Space>
                  </React.Fragment>
                )}
              </div>

              <div className="col-lg-3">
                <Box
                  display="backgroundLightNew"
                  renderTitle={language.formSelection.title}
                  renderDescription={language.formSelection.description}
                >
                  <Space top size={spacing.space2}>
                    {ac}
                  </Space>
                </Box>

                <Sticky
                  enabled={true}
                  top={'.Header'}
                  innerZ={0}
                  bottomBoundary={'.resultsLayout'}
                >
                  <Space padding top size={spacing.space4}>
                    <ItenaryResults
                      lodges={contentfulLodges}
                      cart={this.props.cart}
                      onBookSubmit={this.props.onBookSubmit}
                      onContactSubmit={this.props.onContactSubmit}
                      renderTitle={language.itinerary.title}
                      renderDescription={language.itinerary.description}
                    />
                  </Space>
                </Sticky>

                <FormContainer
                  formId="enquiry"
                  render={(form) => (
                    <ContentModal
                      isOpen={this.props.openBookModal}
                      closeModal={this.props.onBookSubmit}
                      size="large"
                      renderTitle={() => (
                        <Text display="title">{form.title}</Text>
                      )}
                      render={() => (
                        <Space padding top bottom size={spacing.space3}>
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-7">
                                <Space bottom size={spacing.space3}>
                                  <Text
                                    display="intro"
                                    html={form.description}
                                  />
                                </Space>
                              </div>

                              <div className="col-lg-7">
                                <EnquiryForm
                                  form={form}
                                  search={this.props.form}
                                  onSubmit={this.props.onEnquirySubmit}
                                  onSubmitComplete={() =>
                                    this.props.history.push('/confirmation')
                                  }
                                  renderAfterFields={() => (
                                    <Space top size={spacing.space3}>
                                      <Text display="formLabel">
                                        <b>PLEASE NOTE:</b> These results
                                        indicate availability and do not
                                        guarantee a booking. One of our Travel
                                        Advisors will contact you shortly to
                                        plan your trip.
                                      </Text>
                                    </Space>
                                  )}
                                />
                              </div>
                              <div className="col-lg-4 ml-auto">
                                <ItenaryResults
                                  lodges={contentfulLodges}
                                  renderTitle={
                                    language.formEnquiryItinirary.title
                                  }
                                  renderDescription={
                                    language.formEnquiryItinirary.description
                                  }
                                  showSubmit={false}
                                  showContact={false}
                                  cart={this.props.cart}
                                  onBookSubmit={this.props.onBookSubmit}
                                />
                              </div>
                            </div>
                          </div>
                        </Space>
                      )}
                    />
                  )}
                />
                <FormContainer
                  formId="speak"
                  render={(form) => (
                    <ContentModal
                      isOpen={this.props.openContactModal}
                      closeModal={this.props.onContactSubmit}
                      size="large"
                      renderTitle={() => (
                        <Text display="title">{form.title}</Text>
                      )}
                      render={() => (
                        <Space padding top bottom size={spacing.space3}>
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-7">
                                <Space bottom size={spacing.space3}>
                                  <Text
                                    display="intro"
                                    html={form.description}
                                  />
                                </Space>
                              </div>

                              <div className="col-lg-7">
                                <EnquiryForm
                                  form={form}
                                  search={this.props.form}
                                  onSubmit={this.props.onEnquirySubmit}
                                  onSubmitComplete={() =>
                                    this.props.history.push('/confirmation')
                                  }
                                  // renderAfterFields={() => (
                                  //   <Space top size={spacing.space2}>
                                  //     <a
                                  //       href={`https://469uj5355hpj1cwksq2n1n1a-wpengine.netdna-ssl.com/wp-content/uploads/2020/05/Singita-Coronavirus-update-28-May-2020.pdf`}
                                  //       target="_blank"
                                  //       rel="noopener noreferrer"
                                  //     >
                                  //       <ButtonText
                                  //         size="small"
                                  //         className="noline"
                                  //       >
                                  //         {
                                  //           language.formEnquiryItinirary
                                  //             .covidTerms
                                  //         }
                                  //         <Space
                                  //           inline
                                  //           left
                                  //           size={spacing.space1}
                                  //         >
                                  //           <i className="icon icon-long-arrow-right" />
                                  //         </Space>
                                  //       </ButtonText>
                                  //     </a>
                                  //   </Space>
                                  // )}
                                />
                              </div>
                              <div className="col-lg-4 ml-auto">
                                {cart && cart.items.length > 0 ? (
                                  <ItenaryResults
                                    renderTitle={
                                      language.formEnquiryItinirary.title
                                    }
                                    renderDescription={
                                      language.formEnquiryItinirary.description
                                    }
                                    showSubmit={false}
                                    showContact={false}
                                    cart={this.props.cart}
                                    onContactSubmit={this.props.onContactSubmit}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </Space>
                      )}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </Space>
        <Media query={{ maxWidth: 992 }}>
          <React.Fragment>
            <StyledWrapper>
              <StyledButtonText onClick={this.props.onFilterSubmit}>
                {language.formSelection.filterResults}
              </StyledButtonText>

              <Button block onClick={this.props.onBookSubmit}>
                {language.itinerary.sendEnquiry} ({cart.items.length})
              </Button>
            </StyledWrapper>
          </React.Fragment>
        </Media>
        <Modal
          isOpen={this.state.promotionsModal.isOpen}
          size="medium"
          handleClose={() =>
            this.setState((state) => ({
              ...state,
              promotionsModal: { isOpen: false, promotionId: undefined },
            }))
          }
        >
          {(() => {
            if (ungroupedPromotions) {
              const promotion = ungroupedPromotions.find(
                (p) => p.sys.id === this.state.promotionsModal.promotionId,
              );
              if (!promotion) return null;

              return (
                <>
                  <Meta
                    meta={
                      promotion.regions && promotion.regions.items
                        ? [
                            {
                              text: promotion.regions.items[0].country,
                              color: 'brandBrown',
                            },
                          ]
                        : []
                    }
                  />
                  <Space bottom size={spacing.space4} />
                  <Heading size="h1">{promotion.headline}</Heading>
                  <Space bottom size={spacing.space4} />
                  <PromotionModalImage src={promotion.featuredImage.url} />
                  <Space bottom size={spacing.space4} />
                  <RichTextRenderer document={promotion.content.json} />
                </>
              );
            }
            return null;
          })()}
        </Modal>
      </React.Fragment>
    );
  }
}

const StyledButtonText = styled(ButtonText)`
  display: flex;
  justify-content: center;
`;

const StyledWrapper = styled.div`
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 100%;
`;

const PromotionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PromotionModalImage = styled.img`
  width: 100%;
`;

const LodgePromotionContainer = styled.div`
  margin-top: 1rem;
`;
