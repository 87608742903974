export const api = {
  months: [
    {
      value: '01',
      label: 'January',
    },
    {
      value: '02',
      label: 'February',
    },
    {
      value: '03',
      label: 'March',
    },
    {
      value: '04',
      label: 'April',
    },
    {
      value: '05',
      label: 'May',
    },
    {
      value: '06',
      label: 'June',
    },
    {
      value: '07',
      label: 'July',
    },
    {
      value: '08',
      label: 'August',
    },
    {
      value: '09',
      label: 'September',
    },
    {
      value: '10',
      label: 'October',
    },
    {
      value: '11',
      label: 'November',
    },
    {
      value: '12',
      label: 'December',
    },
  ],
  data: {
    title: 'Sasakwa Lodge',
    region: 'Singita Grumeti',
    content: 'test content',
  },
  header: {
    secondary: [
      {
        name: 'About',
        url: 'https://singita.com/about/',
      },
      {
        name: 'Rates',
        url: 'https://singita.com/rates/',
      },
      {
        name: 'Blog',
        url: 'https://singita.com/blog/',
      },
      {
        name: 'Videos',
        url: 'https://singita.com/videos/',
      },
      {
        name: 'Contact',
        url: 'https://singita.com/contact/',
      },
    ],
    primary: [
      {
        name: 'Lodges',
        url: 'https://singita.com/lodges/',
      },
      {
        name: 'Conservation',
        url: 'https://conservation.singita.com/',
      },
      {
        name: 'Regions',
        url: 'https://singita.com/regions/',
      },
      {
        name: 'Wildlife',
        url: 'https://wildlife.singita.com/',
      },
    ],
    tertiary: {
      show: false,
      title: 'Tertiary Menu Title',
      items: [
        {
          name: 'Lodges',
          url: 'https://singita.com/lodges/',
        },
        {
          name: 'Conservation',
          url: 'https://conservation.singita.com/',
        },
        {
          name: 'Regions',
          url: 'https://singita.com/regions/',
        },
        {
          name: 'Wildlife',
          url: 'https://wildlife.singita.com/',
        },
      ],
    },
  },
  footer: {
    legal: [
      {
        label: 'Privacy Policy',
        url: 'https://singita.com/privacy-policy',
      },
      {
        label: 'Terms & Conditions',
        url: 'https://www.singita.com/terms-conditions/',
      },
      {
        label: 'Website Terms of Use',
        url: 'https://469uj5355hpj1cwksq2n1n1a-wpengine.netdna-ssl.com/wp-content/uploads/2017/11/SingitaTermsofUse.pdf',
      },
      {
        label: 'PAIA',
        url: 'https://www.singita.com/paia/',
      },
      {
        label: 'Rates',
        url: 'https://www.singita.com/rates/',
      },
      {
        label: 'Promotions',
        url: 'https://www.singita.com/promotions/',
      },
    ],
    contact: [
      {
        label: 'reservations@singita.com',
        type: 'text',
        url: 'mailto:reservations@singita.com',
      },
      {
        label: '+27 (0)21 683 3424',
        type: 'text',
        url: 'tel:+27(0)216833424',
      },
      {
        label: 'View all contact info',
        type: 'button',
        url: 'https://www.singita.com/contact',
      },
    ],
    sub: [
      {
        label: 'Singita Website',
        type: 'text',
        url: 'https://singita.com',
      },
      {
        label: 'Singita Blog',
        type: 'text',
        url: 'https://singita.com/blog',
      },
      {
        label: 'About Singita',
        type: 'text',
        url: 'https://singita.com/about/',
      },
    ],
    social: [
      {
        name: 'twitter',
        url: 'https://twitter.com/Singita_',
        icon: 'twitter',
      },
      {
        name: 'facebook',
        url: 'https://www.facebook.com/singita.gamereserves',
        icon: 'facebook',
      },
      {
        name: 'instagram',
        url: 'http://instagram.com/singita_',
        icon: 'instagram',
      },
      {
        name: 'vimeo',
        url: 'http://vimeo.com/singita/videos',
        icon: 'vimeo',
      },
      {
        name: 'pinterest',
        url: 'http://pinterest.com/singitareserves/',
        icon: 'pinterest',
      },
      {
        name: 'podcast',
        url: 'https://open.spotify.com/show/0MEzl7srAsHkXgott0eXKW',
        icon: 'podcast',
      }
    ],
  },
  library: {
    photography: {
      resultsLodge: [
        {
          lodge: 'Boulders Lodge',
          count: '65',
        },
        {
          lodge: 'Ebony Lodge',
          count: '65',
        },
        {
          lodge: 'Castleton Lodge',
          count: '65',
        },
        {
          lodge: 'Sweni Lodge',
          count: '65',
        },
        {
          lodge: 'Lebombo Lodge',
          count: '65',
        },
      ],
      resultsTag: [
        {
          lodge: 'Experience',
          count: '65',
        },
        {
          lodge: 'Lodge',
          count: '65',
        },
        {
          lodge: 'Wildlife',
          count: '65',
        },
        {
          lodge: 'Leopard',
          count: '65',
        },
        {
          lodge: 'Food',
          count: '65',
        },
        {
          lodge: 'Zebra',
          count: '65',
        },
      ],
      cards: [
        {
          name: 'Card Title Lorem',
          region: 'Kruger National Park',
          image:
            'https://images.unsplash.com/photo-1517486430290-35657bdcef51?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=48e124c4b3b1b63b73b11f572f5216e0&auto=format&fit=crop&w=1355&q=80',
        },
        {
          name: 'Card Title Ipsum Lorem polin sin Cordana',
          region: 'Grumeti',
          image:
            'https://images.unsplash.com/photo-1517003142208-feb84c80413e?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=2fef02d91e78badcfb02c110000f8313&auto=format&fit=crop&w=1352&q=80',
        },
        {
          name: 'Card Title Dollar',
          region: 'Grumeti',
          image:
            'https://images.unsplash.com/photo-1534528489707-ef61ee67b566?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=1aa0da12ad1a7ccf0c0b28308f6f7193&auto=format&fit=crop&w=1350&q=80',
        },
        {
          name: 'Card Title Set',
          region: 'Kwitonda',
          image:
            'https://images.unsplash.com/photo-1527436826045-8805c615a6df?ixlib=rb-0.3.5&s=51c3d95985c64e95d69c401c34b18e07&auto=format&fit=crop&w=1350&q=80',
        },
      ],
    },
  },
  results: {
    itinerary: [
      {
        lodge: 'Sasakwa Lodge',
        unit: '1-Bedroom Cottage',
        rate: 'USD 12 450',
        dates: '07/22/2018 - 07/25/2018',
      },
      {
        lodge: 'Sasakwa Lodge',
        unit: '2-Bedroom Cottage',
        rate: 'USD 14 450',
        dates: '07/22/2018 - 07/25/2018',
      },
      {
        lodge: 'Sasakwa Lodge',
        unit: '3-Bedroom Cottage',
        rate: 'USD 16 450',
        dates: '07/22/2018 - 07/25/2018',
      },
    ],
    promotions: [
      {
        name: 'Grumeti Air – A Serengeti Safari the Singita Way',
        region: 'Singita Serengeti, Tanzania',
        img: {
          url: 'https://images.unsplash.com/photo-1515914560649-8fe5d631aa62?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b7b6e475c17e36834453e4a9cff0209c&auto=format&fit=crop&w=2250&q=80',
          title: 'Singita Promotion',
        },
      },
      {
        name: 'Singita Grumeti / Fregate Island Private Combo – 2018',
        region: 'Singita Serengeti, Tanzania',
        img: {
          url: 'https://images.unsplash.com/photo-1496963729609-7d408fa580b5?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=08b4a1080fb625e001a410a9ec1e34b9&auto=format&fit=crop&w=2251&q=80',
          title: 'Singita Promotion',
        },
      },
      {
        name: 'Singita Kruger National Park / Fregate Island Private Combo – 2018',
        region: 'Singita Kruger, South Africa',
        img: {
          url: 'https://images.unsplash.com/photo-1507823160955-056fcbf921c5?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=89b65bc6c2d8ad4dcb19c101063488b2&auto=format&fit=crop&w=2250&q=80',
          title: 'Singita Promotion',
        },
      },
    ],
    lodges: [
      {
        name: 'Sasakwa Lodge',
        region: 'SINGITA SERENGETI, TANZANIA',
        img: {
          url: 'https://images.unsplash.com/photo-1527436553949-ea84cd6cf13c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=a0d1c79b978b51ee8d4689bd4f52616a&auto=format&fit=crop&w=2550&q=80',
          title: 'Sasakwa lodge',
        },
        units: [
          {
            name: '3-Bedroom Cottage',
            rate: 'USD 12 450',
            dates: '07/22/2018 - 07/25/2018',
          },
          {
            name: '2-Bedroom Cottage',
            rate: 'USD 12 450',
            dates: '07/22/2018 - 07/25/2018',
          },
          {
            name: '1-Bedroom Cottage',
            rate: 'USD 12 450',
            dates: '07/22/2018 - 07/25/2018',
          },
        ],
      },
      {
        name: 'Ebony Lodge',
        region: 'SINGITA SERENGETI, TANZANIA',
        img: {
          url: 'https://images.unsplash.com/photo-1516497084411-042e90c17be1?ixlib=rb-0.3.5&s=ab92788e592ebf23e0fd0d5454d594bc&auto=format&fit=crop&w=2933&q=80',
          title: 'Sasakwa lodge',
        },
        units: [
          {
            name: '3-Bedroom Cottage',
            rate: 'USD 12 450',
            dates: '07/22/2018 - 07/25/2018',
          },
          {
            name: '2-Bedroom Cottage',
            rate: 'USD 12 450',
            dates: '07/22/2018 - 07/25/2018',
          },
          {
            name: '1-Bedroom Cottage',
            rate: 'USD 12 450',
            dates: '07/22/2018 - 07/25/2018',
          },
        ],
      },
    ],
  },
  libraryRoutes: [
    {
      name: 'Home',
      type: 'home',
      description:
        "A comprehenssssive library of imagery and relevant information in 'easy-to-use', downloadable files",
      to: '/',
      cv: true,
      exact: true,
      slug: 'home',
      component: 'Landing',
    },
    {
      name: 'Photos & Videos',
      description:
        'A selection of hi-res images and videos of our lodges, wildlife, activities and experiences are availible for download. Please remember to credit Singita whenever images / videos are used.',
      to: '/photography',
      tc: true,
      component: 'Parent',
      routes: [
        {
          name: 'Photos & Videos',
          type: 'images',
          tc: true,
          showMenu: false,
          component: 'Landing',
          slug: 'photography-2',
          to: '/',
          exact: true,
        },
        {
          name: 'All Photos & Videos',
          showMenu: true,
          to: '/search',
          component: 'Attachments',
          exact: true,
          type: 'images',
          tc: true,
          virtual: [
            {
              key: 'post_type',
              value: 'image',
            },
            {
              key: 'post_type',
              value: 'videos',
            },
          ],
          refinements: [
            {
              label: 'Tag',
              key: 'taxonomies.post_tag',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
            {
              label: 'Wildlife',
              key: 'taxonomies.wildlife',
            },
          ],
        },
        {
          name: 'Lodges',
          title: 'Photos & Videos / Lodges',
          to: '/lodges',
          component: 'Attachments',
          exact: true,
          type: 'images',
          tc: true,
          virtual: [
            {
              key: 'post_type',
              value: 'image',
            },
            {
              key: 'post_type',
              value: 'videos',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'Lodge',
            },
          ],
          refinements: [
            {
              label: 'Type',
              key: 'post_type_label',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
        {
          name: 'Wildlife',
          title: 'Photos & Videos / Wildlife',
          exact: true,
          type: 'images',
          tc: true,
          component: 'Attachments',
          to: '/wildlife',
          virtual: [
            {
              key: 'post_type',
              value: 'image',
            },
            {
              key: 'post_type',
              value: 'videos',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'Wildlife',
            },
          ],
          refinements: [
            {
              label: 'Type',
              key: 'post_type_label',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
            {
              label: 'Wildlife',
              key: 'taxonomies.wildlife',
            },
          ],
        },
        {
          name: 'Experiences',
          title: 'Photos & Videos / Experiences',
          exact: true,
          type: 'images',
          tc: true,
          component: 'Attachments',
          to: '/experiences',
          virtual: [
            {
              key: 'post_type',
              value: 'image',
            },
            {
              key: 'post_type',
              value: 'videos',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'Experiences',
            },
          ],
          refinements: [
            {
              label: 'Type',
              key: 'post_type_label',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
          ],
        },
        {
          name: 'Landscape',
          title: 'Photos & Videos / Landscape',
          to: '/landscape',
          component: 'Attachments',
          exact: true,
          type: 'images',
          tc: true,
          virtual: [
            {
              key: 'post_type',
              value: 'image',
            },
            {
              key: 'post_type',
              value: 'videos',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'landscapes',
            },
          ],
          refinements: [
            {
              label: 'Type',
              key: 'post_type_label',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
          ],
        },
        {
          name: 'Food and Wine',
          title: 'Photos & Videos / Food and Wine',
          to: '/food-and-wine',
          component: 'Attachments',
          exact: true,
          type: 'images',
          tc: true,
          virtual: [
            {
              key: 'post_type',
              value: 'image',
            },
            {
              key: 'post_type',
              value: 'videos',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'Food and Wine',
            },
          ],
          refinements: [
            {
              label: 'Type',
              key: 'post_type_label',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Rates & Promotions',
      to: '/rates',
      description:
        "A comprehensive library of imagery and relevant information in 'easy-to-use', downloadable files",
      component: 'Parent',
      routes: [
        {
          name: 'Rates & Promotions',
          meta_description:
            'Find our current rates and promotions. Use your unique login details to access your contract rates. Read more here.',
          type: 'documents',
          component: 'Attachments',
          to: '/',
          exact: true,
          virtual: [
            {
              key: 'post_type',
              value: 'docs',
            },
            {
              key: 'taxonomies.category',
              value: 'Rates and Promotions',
            },
          ],
          refinements: [
            {
              label: 'Language',
              key: 'files.language',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
        {
          name: 'Contract Rates',
          type: 'documents',
          component: 'Redirect',
          href: 'https://contractrates.singita.com',
          to: '/contract-rates',
          exact: true,

          virtual: [
            {
              key: 'post_type',
              value: 'docs',
            },
            {
              key: 'taxonomies.category',
              value: 'Rates and Promotions',
            },
          ],
          refinements: [
            {
              label: 'Tags',
              key: 'taxonomies.post_tag',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
        {
          name: 'Promotions',
          type: 'documents',
          component: 'Attachments',
          to: '/promotions',
          exact: true,
          virtual: [
            {
              key: 'post_type',
              value: 'docs',
            },
            {
              key: 'taxonomies.category',
              value: 'Rates and Promotions',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'Promotions',
            },
          ],
          refinements: [
            {
              label: 'Language',
              key: 'files.language',
            },
            {
              label: 'Region',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'Selling Tools',
      to: '/selling-tools',
      type: 'documents',
      component: 'Parent',
      routes: [
        {
          name: 'Selling Tools',
          type: 'documents',
          showMenu: false,
          component: 'Landing',
          slug: 'selling-tools',
          to: '/',
          exact: true,
          virtual: [
            {
              key: 'taxonomies.category',
              value: 'Selling Tools',
            },
            {
              key: 'post_type',
              value: 'docs',
            },
          ],
          refinements: [
            {
              label: 'Types',
              key: 'taxonomies.category',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
        {
          name: 'Documents',
          showMenu: false,
          component: 'Attachments',
          type: 'documents',
          to: '/documents',
          exact: true,
          virtual: [
            {
              key: 'taxonomies.category',
              value: 'Selling Tools',
            },
            {
              key: 'post_type',
              value: 'docs',
            },
          ],
          refinements: [
            {
              label: 'Types',
              key: 'taxonomies.post_tag',
            },
            {
              label: 'Regions',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },

        {
          name: 'eBrochures',
          title: 'Selling Tools / eBrochures',
          exact: true,
          type: 'documents',
          tc: true,
          component: 'Attachments',
          to: '/documents/ebrochures',
          virtual: [
            {
              key: 'post_type',
              value: 'docs',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'eBrochure',
            },
          ],
          refinements: [
            {
              label: 'Language',
              key: 'files.language',
            },
            {
              label: 'Regions',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
        {
          name: 'Lodge Info',
          title: 'Selling Tools / Lodge Info',
          exact: true,
          type: 'documents',
          component: 'Attachments',
          to: '/documents/lodge-info',
          virtual: [
            {
              key: 'post_type',
              value: 'docs',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'Lodge Info',
            },
          ],
          refinements: [
            {
              label: 'Language',
              key: 'files.language',
            },
            {
              label: 'Regions',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },

        {
          name: 'Travel Info',
          title: 'Selling Tools / Travel Info',
          exact: true,
          type: 'documents',
          component: 'Attachments',
          to: '/documents/travel-info',
          virtual: [
            {
              key: 'post_type',
              value: 'docs',
            },
            {
              key: 'taxonomies.post_tag',
              value: 'Travel Info',
            },
          ],
          refinements: [
            {
              label: 'Language',
              key: 'files.language',
            },
            {
              label: 'Regions',
              key: 'taxonomies.region',
            },
            {
              label: 'Lodge',
              key: 'taxonomies_hierarchical.lodge',
              hierarchical: true,
              attributes: [
                'taxonomies_hierarchical.lodge.lvl0',
                'taxonomies_hierarchical.lodge.lvl1',
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Conservation',
      to: '/conservation',
      type: 'documents',
      component: 'Landing',
      slug: 'conservation',
    },
    {
      name: 'Private Villas',
      to: '/singita-private',
      type: 'documents',
      component: 'Landing',
      slug: 'singita-private',
    },
    {
      name: 'Brand Guidelines',
      to: '/brand-guidelines',
      type: 'documents',
      component: 'Landing',
      slug: 'brand-guidelines',
    },
    {
      name: 'Contact Us',
      to: '/general-information',
      type: 'documents',
      component: 'Landing',
      slug: 'general-information',
    },
  ],
};
