import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ResultsContainer } from './modules/Results/ResultsContainer';

import { HomeContainer } from './modules/Home/HomeContainer';
import { ConfirmationContainer } from './modules/Confirmation/ConfirmationContainer';
import { CalendarContainer } from './modules/Calendar/CalendarContainer';
import AccessContainer from './modules/Calendar/AccessContainer';
import AccountContainer from './modules/Calendar/AccountContainer';

export const Routes = () => (
  <BrowserRouter basename="/booking">
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={HomeContainer} />
        <Route exact path="/access" component={AccessContainer} />
        <Route exact path="/account" component={AccountContainer} />

        <Route path="/search" component={ResultsContainer} />
        <Route path="/confirmation" component={ConfirmationContainer} />

        <Route path="/calendar" component={CalendarContainer} />
      </Switch>
    </React.Fragment>
  </BrowserRouter>
);
