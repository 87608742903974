import React from 'react';
import InputCheckBox from '../InputCheckBox/InputCheckBox';
import Space from '../Base/Space';
import Text from '../Base/Text/Text';
import { spacing } from '../../common';

const SelectCheckbox = ({
  label,
  left = false,
  value,
  onClick,
  checked = false,
}) => {
  let checkOptions = {};

  if (onClick) {
    checkOptions.onClick = () => onClick(value);
  }

  let check = <InputCheckBox {...checkOptions} checked={checked} />;

  return (
    <Space top bottom size={spacing.space1}>
      <div className="d-flex justify-content-between align-items-center">
        {left ? check : null}
        <Text display="label" level="span">
          {label}
        </Text>
        {!left ? check : null}
      </div>
    </Space>
  );
};

export default SelectCheckbox;
