import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Content, Text } from '@singita/components';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Content>
        <Text as="p" size="body">
          {children}
        </Text>
      </Content>
    ),
  },
  renderText: (text) => text.replace('!', '?'),
};

export function RichTextRenderer(props) {
  return documentToReactComponents(props.document, options);
}
