import React from 'react';
import PropTypes from 'prop-types';
import StyledButtonText from './Style/ButtonTextStyle';
import IconAfter from './Style/IconAfter';
import IconBefore from './Style/IconBefore';

class ButtonText extends React.Component {
  render() {
    const {
      children,
      display,
      size,
      block,
      iconAfter,
      iconBefore,
      onClick,
      hover,
      as,
      ...props
    } = this.props;

    return (
      <StyledButtonText
        onClick={onClick}
        hover={hover}
        display={display}
        size={size}
        block={block}
        as={as}
        {...props}
      >
        {iconBefore && (
          <IconBefore>
            <i className={`icon icon-${iconBefore}`} />
          </IconBefore>
        )}
        {children}
        {iconAfter && (
          <IconAfter>
            <i className={`icon icon-${iconAfter}`} />
          </IconAfter>
        )}
      </StyledButtonText>
    );
  }
}

ButtonText.propTypes = {
  /** Sets the display style */
  size: PropTypes.oneOf(['base', 'small', 'mini']),
  /** Sets the size of the component */
  display: PropTypes.oneOf(['base', 'white']),
  /** Contents of the Box */
  children: PropTypes.node.isRequired,
  /** Renders the Title */
  block: PropTypes.bool,
  /** Renders the Description */
  iconAfter: PropTypes.string,
  /** Click action */
  onClick: PropTypes.func,
  /** Hover action */
  hover: PropTypes.bool,
  /** Change the HTML element that is rendered */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

ButtonText.defaultProps = {
  size: 'base',
  display: 'base',
  children: null,
  block: false,
  iconAfter: null,
  onClick: () => {},
  hover: false,
  as: 'span',
};

export default ButtonText;
