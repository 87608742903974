import { colors, sizes } from '../../../../common';

export const displayStyles = {
  select: {
    bgColor: colors.bgWhite,
    textColor: colors.textDark,
    borderColor: colors.borderLight,
    // For CSS hover
    bgColorHover: colors.brandBase,
    borderColorHover: colors.brandBase,
    textColorHover: colors.textWhite,
    hover: {
      bgColor: colors.brandBase,
      borderColor: colors.brandBase,
      textColor: colors.textWhite,
    },
  },
};

export const sizeStyles = {
  base: {
    padding: '18px 50px',
    fontSize: sizes.textLarge,
  },
  small: {
    padding: '10px 15px',
    fontSize: sizes.textBase,
  },
};
