import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ButtonText from '../../components/Base/ButtonText/ButtonText';
import Border from '../../components/Base/Border';
import Space from '../../components/Base/Space';
import { spacing } from '../../common';

export const bigStyles = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    zIndex: 1080,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    overlfow: 'auto',
    height: '100%',
    padding: '0px',
    border: 'none',
    borderRadius: '0px',
  },
};

export const smallStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    zIndex: 1080,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '560px',
    overflow: 'hidden',
    height: 'auto',
    padding: '0px',
    border: 'none',
    borderRadius: spacing.space05,
  },
};

export class ContentModal extends React.Component {
  state = {
    isOpen: false,
    data: {
      title: null,
      content: null,
    },
  };
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={this.props.size === 'large' ? bigStyles : smallStyles}
        size={this.props.size}
      >
        {this.props.size === 'large' && this.props.renderTitle ? (
          <React.Fragment>
            <Space top bottom size={spacing.space3}>
              <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">{this.props.renderTitle()}</div>
                  {this.props.closeModal && (
                    <ButtonText
                      onClick={this.props.closeModal}
                      iconAfter="close"
                    >
                      Close
                    </ButtonText>
                  )}
                </div>
              </div>
            </Space>
            <Border />
          </React.Fragment>
        ) : null}

        {this.props.size === 'small' && this.props.renderTitle ? (
          <React.Fragment>
            <Space padding top bottom left right size={spacing.space3}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">{this.props.renderTitle()}</div>
                {this.props.closeButton && (
                  <ButtonText onClick={this.props.closeModal} iconAfter="close">
                    Close
                  </ButtonText>
                )}
              </div>
            </Space>
            <Border margin="0px" />
          </React.Fragment>
        ) : null}

        {this.props.render ? (
          <div className="modalContent">
            {this.props.render(this.props.data)}
          </div>
        ) : null}
      </Modal>
    );
  }
}

ContentModal.defaultProps = {
  size: 'large',
  closeButton: false,
};

ContentModal.propTypes = {
  size: PropTypes.oneOf(['large', 'small']).isRequired,
};
