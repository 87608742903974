export const fonts = {
  serif: "'mrs-eaves-xl-serif', Georgia, 'Times New Roman', Times, serif",
  sans:
    "'europa', 'Futura Std Maxi Book', 'Helvetica Neue', Helvetica, Arial, sans-serif",
};

export const timing = {
  base: '.1s',
  slow: '.5s',
  slower: '.15s',
};

export const fontWeight = {
  bold: '700',
  regular: '400',
  light: '100',
};

export const fontStyles = (size, lh) => ({
  fontSize: size,
  lineHeight: lh,
});

export const tracking = {
  none: '0',
  small: '1px',
  medium: '1.5px',
};

export const sizes = {
  textTiny: '11px',
  textMini: '12px',
  textSmall: '14px',
  textMedium: '16px',
  textLarge: '19px',

  headingTiny: '16px',
  headingSmall: '22px',
  headingMedium: '30px',
  headingLarge: '32px',
  headingHuge: '42px',
};

export const colors = {
  baseBlack: '#000000',
  baseInherit: 'inherit',
  baseTransparent: 'transparent',
  baseWhite: '#FFFFFF',

  brandBase: '#cb9275',
  brandBaseHover: '#918568',
  brandBeige: '#f4f1ed',
  brandBlack: '#000000',
  brandBlackHover: '#000000',
  brandWhite: '#FFFFFF',
  brandWhiteHover: '#FFFFFF',

  borderBase: '#cb9275',
  borderBlack: '#000000',
  borderLight: '#D8d8d8',
  borderWhite: '#FFFFFF',
  borderShadow: '0px 0px 4px rgba(0,0,0,0.2)',

  bgLightNew: '#F1EDE6',
  bgLight: '#F7F7F6',
  bgBlack: '#000000',
  bgDark: '#565857',
  bgBrand: '#cb9275',
  bgWhite: '#FFFFFF',
  bgWhiteO: 'rgba(255,255,255,0.70)',

  linkBase: '#333333',
  linkHover: '#333333',

  statusDanger: '#B0153C',
  statusInfo: '#85CEFF',
  statusNeutral: '#333333',
  statusSuccess: '#52A74B',
  statusWarning: '#F5A623',

  headingBrand: '#cb9275',
  headingDark: '#555857',

  textBase: 'rgba(85,88,87,0.65)',
  textBlack: '#000000',
  textBrand: '#cb9275',
  textWhite: '#FFFFFF',
  textDark: '#555857',

  textLight: '#f2f2f2',
  textLightHover: '#000000',
  textLightO: 'rgba(255,255,255,0.7)',
};

//  TODO: Reduce colors;

export const icons = {
  none: '',
  bars: 'hint',
  close: 'close',
  arrowTop: 'arrow-top',
  arrowRight: 'arrow-right',
  arrowLeft: 'arrow-left',
  arrowBottom: 'arrow-bottom',
  twitter: 'twitter',
  facebook: 'facebook',
  instagram: 'instagram',
  pinterest: 'pinterest',
  podcast: 'podcast',
  logo: 'logo',
};

//  TODO: Choose icons;

export const opacity = {
  solid: '1',
  hover: '.75',
  transparent: '0',
};

const spacingFactor = 8;
export const spacing = {
  space0: `${spacingFactor * 0}px`, // 0
  space05: `${spacingFactor / 2}px`, // 4
  space1: `${spacingFactor * 1}px`, // 8
  space2: `${spacingFactor * 2}px`, // 16
  space3: `${spacingFactor * 3}px`, // 24
  space4: `${spacingFactor * 4}px`, // 32
  space5: `${spacingFactor * 5}px`, // 40
  space6: `${spacingFactor * 6}px`, // 48
  space7: `${spacingFactor * 7}px`, // 56
  space8: `${spacingFactor * 8}px`, // 64
  space9: `${spacingFactor * 9}px`, // 72
  space10: `${spacingFactor * 10}px`, // 80
  space11: `${spacingFactor * 11}px`, // 88
  space12: `${spacingFactor * 12}px`, // 96
  space13: `${spacingFactor * 13}px`, // 104
  space14: `${spacingFactor * 14}px`, // 168
  space15: `${spacingFactor * 15}px`, // 120
};

export const media = {
  min: {
    1430: '1430px',
    1410: '1410px',
    1350: '1350px',
    1290: '1290px',
    1200: '1200px',
    1080: '1080px',
    1024: '1024px',
    960: '960px',
    840: '840px',
    768: '768px',
    720: '720px',
    600: '600px',
    560: '560px',
    480: '480px',
    360: '360px',
    320: '320px',
  },
  max: {
    1530: '1530px',
    1430: '1430px',
    1410: '1410px',
    1350: '1350px',
    1290: '1290px',
    1200: '1200px',
    1080: '1080px',
    1024: '1024px',
    960: '960px',
    840: '840px',
    768: '768px',
    720: '720px',
    600: '600px',
    560: '560px',
    480: '480px',
    360: '360px',
    320: '320px',
  },
};
