import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../common';
import {
  Space,
  ButtonText,
  ButtonSelect,
  ResultPromotion,
  Box,
  Tabs,
  Tab,
} from '../components';

class Promotions extends React.Component {
  render() {
    const { title, results } = this.props;

    return (
      <Space bottom size={spacing.space8}>
        <Box display="background">
          <Tabs>
            <Tab active>{title}</Tab>
          </Tabs>
          {results.map((r) => (
            <ResultPromotion
              name={r.name}
              img={r.img.url}
              region={r.region}
              renderAction={<ButtonSelect />}
              renderDetails={
                <ButtonText size="small">Offer Details</ButtonText>
              }
            />
          ))}
        </Box>
      </Space>
    );
  }
}

Promotions.propTypes = {
  /** Section Title */
  title: PropTypes.string.isRequired,
  /** Availible promotions */
  results: PropTypes.node.isRequired,
};

Promotions.defaultProps = {
  title: 'Packages and Exclusive Offers',
  results: null,
};

export default Promotions;
