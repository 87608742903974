import React, { useState, useRef } from 'react';
import queryString from 'query-string';
import { Text, Box, Button, Border, ButtonIcon } from '../../../components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { ContentModal } from '../../../components';
import { Flex } from '@singita/components';

const Views = (props) => {
  const [views, setView] = useLocalStorage('views', []);
  const [showAdd, setAdd] = useState(false);
  const [copied, setCopied] = useState(false);
  const inputEl = useRef(null);

  const onSaveView = () => {
    const title = inputEl.current.value;
    if (!title) return;

    setView((views) => {
      return [...views, [title, window.location.search]];
    });
    inputEl.current.value = '';
    setAdd(false);
  };

  const onViewActivate = (view) => {
    const values = queryString.parse(view, { arrayFormat: 'bracket' });

    props.handleFormChange({
      regions: values.regions || [],
      lodges: values.lodges || [],
    });
  };

  return (
    <>
      <Border />
      <Flex alignItems="center" justifyContent="space-between" mb={[2]}>
        <Text display="formLabel">My Views</Text>

        <ButtonIcon size="small" onClick={() => setAdd(!showAdd)} icon="plus" />
      </Flex>
      {views.length ? (
        <ul
          className="view-folder__list"
          s
          style={{ maxHeight: '40px', overflowY: 'auto' }}
        >
          {views.map(([title, search]) => (
            <li className="view-folder__item">
              <span onClick={() => onViewActivate(search)}>{title}</span>
            </li>
          ))}
        </ul>
      ) : null}

      {showAdd ? (
        <ContentModal
          size="small"
          isOpen={showAdd}
          closeModal={() => setAdd(false)}
          render={() => (
            <Box display="border">
              <div className="form-group">
                <Text as="label" display="formLabel">
                  View name
                </Text>
                <div className="input-group mb-1">
                  <input type="text" className="form-control" ref={inputEl} />
                  <div className="input-group-append">
                    <Button size="small" onClick={onSaveView}>
                      Save View
                    </Button>
                  </div>
                </div>
                <Text display="small">
                  Allows quick access to frequently used availability results.
                </Text>
              </div>
              <div className="form-group">
                <Text as="label" display="formLabel">
                  Copy url
                </Text>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    placeholder={window.location}
                    readonly
                  ></input>
                  <CopyToClipboard
                    text={window.location}
                    onCopy={() => setCopied(true)}
                  >
                    <div className="input-group-append">
                      <Button size="small">Copy</Button>
                    </div>
                  </CopyToClipboard>
                </div>
                {copied ? (
                  <Text display="meta">Copied</Text>
                ) : (
                  <Text display="small">Direct link to search results</Text>
                )}
              </div>
            </Box>
          )}
        ></ContentModal>
      ) : null}
      <Border />
      <div
        size="small"
        display="background"
        style={{ lineHeight: 1, fontSize: '14px' }}
      >
        <Text display="small">
          This report is an availability snapshot and is updated hourly. For the
          latest availability, please contact:
          <br />
          <a href="mailto:reservations@singita.com">reservations@singita.com</a>
          <br />
          <a href="tel:+27216833424">+27 (0)21 683 3424</a>
        </Text>
      </div>
    </>
  );
};

export default Views;
