import styled, { css } from 'styled-components';
import { displayStyles } from './style';
import { timing } from '../../../../common';

const defaultProps = {
  hover: false,
  display: 'base',
};

const display = (props) => {
  let style = displayStyles[props.display];
  if (props.hover) {
    style = {
      ...style,
      ...displayStyles[props.display].hover,
    };
  }
  return css`
    opacity: ${style.opacity};
    :focus,
    :hover {
      opacity: 1;
    }
  `;
};

const StyledDescription = styled.span`
  ${display};
  transition: ${timing.slow};
`;

export default StyledDescription;

StyledDescription.defaultProps = defaultProps;
