import * as React from 'react';
import { Query } from 'react-apollo';
import { Loader } from '../../components';
import { QUERY_FORM } from './graphql/queries';

export class FormContainer extends React.PureComponent {
  render() {
    return (
      <Query
        query={QUERY_FORM}
        variables={{
          formId: this.props.formId,
        }}
      >
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`;
          if (loading) return <Loader />;
          const { viewForm } = data;

          if (!viewForm) return null;

          return this.props.render(viewForm);
        }}
      </Query>
    );
  }
}
