import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../../components/Base/Text/Text';
import Space from '../../components/Base/Space';
import { Logo } from '../../components/Base/Logo/Logo';
import Box from '../../components/Box/Box';
import { ButtonText } from '../../components';
import { timing, opacity, colors, spacing } from '../../common';
import { theme } from '@singita/components';

export const Footer = (props) => {
  const { subMenu, socialMenuItems, contactMenuItems, legalMenuItems } = props;

  const StyledFooter = styled.div`
    display: block;
    position: relative;
    z-index: 1;
  `;

  const StyledSocialMenu = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 0 ${spacing.space3};
    li {
      margin: 0 8px;
      .icon {
        color: ${colors.textDark};
        font-size: 22px;
      }
      a {
        text-decoration: none;
        opacity: 1;
        transition: opacity ${timing.base};
        :hover {
          text-decoration: none;
          opacity: ${opacity.hover};
        }
      }
    }
  `;

  const StyledLinks = styled(StyledSocialMenu)`
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `;

  const StyledContactMenu = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: 768px) {
      align-items: flex-end;
    }
    li {
      margin-bottom: 4px;
      display: inherit;
      a {
        text-decoration: none;
        opacity: 1;
        transition: opacity ${timing.base};
        :hover {
          text-decoration: none;
          opacity: ${opacity.hover};
        }
      }
      &.button a:hover {
        opacity: 1;
      }
    }
  `;

  const StyledLegal = styled.div`
    margin: 8px 0;
  `;

  const StyledLegalMenu = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 4px 8px;
      a {
        color: ${colors.textDark};
        text-decoration: none;
        border-bottom: 1px solid transparent;
        border-bottom-color: transparent;
        display: inherit;
        transition: all 0.5s;
        :hover {
          text-decoration: none;
          border-bottom-color: ${colors.textBase};
        }
      }
    }
  `;

  const StyledAttribution = styled.div`
    margin: 8px 0;
    a {
      color: ${colors.textDark};
      text-decoration: none;
      border-bottom: 1px solid transparent;
      border-bottom-color: transparent;
      display: inherit;
      transition: all 0.5s;
      :hover {
        text-decoration: none;
        border-bottom-color: ${colors.textBase};
      }
    }
  `;

  return (
    <StyledFooter className={'Footer'}>
      <Box display="backgroundBrandBiege">
        <Space padding top bottom size={spacing.space2}>
          <div className="container">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <Logo color={theme.colors.brandBrown} />
                {socialMenuItems && (
                  <StyledSocialMenu>
                    {socialMenuItems.map((s) => (
                      <li key={s.name}>
                        <a
                          href={s.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className={`icon icon-${s.icon}`} />
                        </a>
                      </li>
                    ))}
                  </StyledSocialMenu>
                )}
              </div>
              {subMenu && (
                <StyledLinks>
                  {subMenu.map((c, index) => (
                    <li key={`contactMenuItems${index}`}>
                      <a href={c.url}>
                        <Text display="intro">{c.label}</Text>
                      </a>
                    </li>
                  ))}
                </StyledLinks>
              )}
              {contactMenuItems && (
                <StyledContactMenu>
                  {contactMenuItems.map((c, index) => (
                    <li key={`contactMenuItems${index}`}>
                      <a href={c.url}>
                        {c.type === 'text' ? (
                          <Text display="intro">{c.label}</Text>
                        ) : (
                          <ButtonText display="base" iconAfter="chevron-right">
                            {c.label}
                          </ButtonText>
                        )}
                      </a>
                    </li>
                  ))}
                </StyledContactMenu>
              )}
            </div>
          </div>
        </Space>
      </Box>
      <Box display="backgroundBrandBiege">
        <div className="container">
          <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <StyledLegal>
              <Text align="center" display="legal">
                All rights reserved &copy; {new Date().getFullYear()} Singita
              </Text>
            </StyledLegal>
            {legalMenuItems && (
              <StyledLegalMenu>
                {legalMenuItems.map((l, index) => (
                  <li key={`legalMenuItem${index}`}>
                    <a href={l.url}>
                      <Text display="legal" align="center">
                        {l.label}
                      </Text>
                    </a>
                  </li>
                ))}
              </StyledLegalMenu>
            )}

            <StyledAttribution>
              <a href="http://www.plusplusminus.co.za/?utm_source=Singita&utm_medium=Footer&utm_campaign=Credit">
                <Text display="legal">Crafted by PlusPlusMinus</Text>
              </a>
            </StyledAttribution>
          </div>
        </div>
      </Box>
    </StyledFooter>
  );
};

Footer.propTypes = {
  /** Hover action */
  socialMenuItems: PropTypes.array,
  /** Hover action */
  contactMenuItems: PropTypes.array,
  /** Hover action */
  legalMenuItems: PropTypes.array,
};

Footer.defaultProps = {
  socialMenuItems: null,
  contactMenuItems: null,
  legalMenuItems: null,
};
