import React from 'react';
import PhoneInput from 'react-phone-input-2';
import Space from '../../components/Base/Space';
import { theme } from '@singita/components';
import Text from '../../components/Base/Text/Text';
import { spacing } from '../../common';
import 'react-phone-input-2/lib/style.css';

export const BSInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <div className="form-group">
      <label htmlFor={[field.name]}>
        <Text display="formLabel">{[props.label]}</Text>
      </label>
      <input
        className="form-control"
        type={field.type}
        id={[field.name]}
        {...field}
        {...props}
        value={
          field.name === 'email' ? field.value?.toLowerCase() : field.value
        }
      />
      <Space top size={spacing.space1}>
        <Text display="small">{[props.description]}</Text>
      </Space>
      {errors[field.name] && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export const BSPhoneComponent = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  return (
    <div className="form-group">
      <label htmlFor={[field.name]}>
        <Text display="formLabel">{[props.label]}</Text>
      </label>

      <PhoneInput
        id={[field.name]}
        inputStyle={{
          width: '100%',
          border: `border-bottom: 1px solid ${theme.colors.baseGrayLight}`,
          borderRadius: 3,
          color: theme.colors.baseGray,
          height: '38px',
          fontSize: '16px',
        }}
        country={'us'}
        value={field.value ? field.value : '1'}
        onChange={(phone) => setFieldValue(field.name, `+${phone}`)}
        inputProps={{ name: field.name, 'aria-label': field.label }}
      />

      <Space top size={spacing.space1}>
        <Text display="small">{[props.description]}</Text>
      </Space>
      {errors[field.name] && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export const BSTextareaComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <div className="form-group">
    <label htmlFor={[field.name]}>
      <Text display="formLabel">{[props.label]}</Text>
    </label>
    <textarea
      className="form-control"
      type="text"
      id={[field.name]}
      {...field}
      {...props}
      rows="3"
    />

    <Space top size={spacing.space1}>
      <Text display="small">{[props.description]}</Text>
    </Space>
    {errors[field.name] && (
      <div style={{ display: 'block' }} className="invalid-feedback">
        {errors[field.name]}
      </div>
    )}
  </div>
);

export const BSSelectComponent = ({
  field,
  form: { touched, errors },
  options,
  ...props
}) => {
  return (
    <div className="form-group">
      <label htmlFor={[field.name]}>
        <Text display="formLabel">{[props.label]}</Text>
      </label>

      <select className="form-control" id={[field.name]} {...field} {...props}>
        <option />
        {options.map((v) => (
          <option value={v.value}>{v.label}</option>
        ))}
      </select>
      {errors[field.name] && (
        <div style={{ display: 'block' }} className="invalid-feedback">
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export const BSCheckComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <div className="form-group form-check">
    <input
      type="checkbox"
      id={field.name}
      name={field.name}
      className="form-check-input"
      {...field}
      {...props}
    />
    <label htmlFor={[field.name]} className="form-check-label">
      <Text display="small">{props.label}</Text>
    </label>

    {errors[field.name] && (
      <div className="invalid-feedback" style={{ display: 'block' }}>
        {errors[field.name]}
      </div>
    )}
  </div>
);
