import React, { useState } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { AUTH_QUERY } from './AuthenticateUser';
import { Loader } from '../../components';

const AccountContainer = (props) => {
  const [authed, setAuth] = useState(false);
  const [error, setError] = useState(null);
  const { token, from = '/calendar' } = queryString.parse(
    props.location.search,
  );

  if (!token) return <Redirect to="login" from={from} />;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(AUTH_QUERY, {
    variables: { token: token },
    onCompleted: (data) => {
      const { verifyToken } = data;

      if (verifyToken) {
        window.localStorage.setItem('UserAuth', JSON.stringify({ token }));
      } else {
        setError('Invalid Token');
      }

      setAuth(true);
    },
  });

  if (!authed) return <Loader />;
  if (error) return <Redirect to={'login'} />;

  return <Redirect to={from} />;
};

export default AccountContainer;
