import styled, { css } from 'styled-components';
import { displayStyles, sizeStyles } from './style';

const defaultProps = {
  display: 'base',
  size: 'small',
};

const display = (props) => {
  let style = displayStyles[props.display];
  return css`
    border-color: ${style.borderColor};
    border-width: ${style.borderWidth};
    background-color: ${style.backgroundColor};
  `;
};

const size = (props) => {
  const style = sizeStyles[props.size];
  return css`
    padding: ${style.padding};
  `;
};

const StyledBox = styled.div`
  ${display};
  ${size};
  border-style: solid;
  border-width: 1px;
  position: relative;
  z-index: 1;
`;

export default StyledBox;

StyledBox.defaultProps = defaultProps;
