import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../common';
import { Space, Image, Text, Border } from '../../components';

class ResultPromotion extends React.Component {
  render() {
    const { name, img, imgTitle, region, renderDetails, renderAction } =
      this.props;

    return (
      <Space>
        <div className="row">
          {img && (
            <div className="col-4 col-sm-2">
              <Image fluid src={img} alt={imgTitle} />
            </div>
          )}
          <div className="col-8 col-sm-10">
            <div className="w-75">
              {name && (
                <Space right size={spacing.space2} inline>
                  <Text display="secondary" inline>
                    {name}
                  </Text>
                </Space>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div>
                {region && (
                  <Text display="meta" inline>
                    {region}
                  </Text>
                )}
                {renderDetails && <Space>{renderDetails}</Space>}
              </div>
              {renderAction && renderAction}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8 col-sm-10 offset-4 offset-sm-2">
            <Border />
          </div>
        </div>
      </Space>
    );
  }
}

ResultPromotion.propTypes = {
  /** Promotion Name */
  name: PropTypes.string,
  /** Image URL */
  img: PropTypes.string,
  /** Image Title */
  imgTitle: PropTypes.string,
  /** Lodge Region */
  region: PropTypes.string,
  /** Render prop for details */
  renderDetails: PropTypes.node,
  /** Render prop for actions */
  renderAction: PropTypes.node,
};

ResultPromotion.defaultProps = {
  name: null,
  img: null,
  imgTitle: null,
  region: null,
  renderDetails: null,
  renderAction: null,
};

export default ResultPromotion;
