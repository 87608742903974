import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../common';
import { Text } from '../../components';

export function Filters(props) {
  const StyledFilters = styled.ul`
    position: relative;
    margin-bottom: ${spacing.space6};
    list-style: none;
    text-align: center;
    padding-left: 0;
  `;

  const StyledFilter = styled.li`
    display: inline-block;
    position: relative;
    padding-left: ${spacing.space3};
    padding-right: ${spacing.space3};
    padding-bottom: ${spacing.space1};
    background-color: ${(props) => (props.active ? 'red' : 'transparent')};
    :before {
      content: '\\00b7';
      position: absolute;
      left: -1.5px;
      top: 1.5;
    }
    :first-child:before {
      display: none;
    }
  `;

  return (
    <StyledFilters>
      <StyledFilter
        active={props.current && true}
        onClick={() => props.onSetFilter(null)}
      >
        <Text display="title">All</Text>
      </StyledFilter>

      {props.items.map((item) => (
        <StyledFilter
          key={item.term_id}
          onClick={() => props.onSetFilter(item.slug)}
          active={props.current === item.slug ? true : false}
        >
          <Text current={props.current === item.slug} display="title">
            {item.name}
          </Text>
        </StyledFilter>
      ))}
    </StyledFilters>
  );
}

Filters.defaultProps = {};
