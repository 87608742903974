import moment from 'moment';

export const options = {
  guests: [
    {
      value: 'adults',
      label: 'Adults',
      min: 1,
    },
    {
      value: 'children',
      label: 'Child: 10 - 16 years',
      min: 0,
    },
    {
      value: 'toddler',
      label: 'Child: 2 - 10 years',
      min: 0,
    },
  ],
};

export const getGuestsText = (guests) => {
  const guestsCount = Object.keys(guests).reduce((result, item) => {
    result += guests[item];
    return result;
  }, 0);

  return `${guestsCount} ${guestsCount > 1 ? 'Guests' : 'Guest'}`;
};

export const formatAvailabilityCSV = (
  regions,
  dates,
  format = 'YYYY/MM/DD',
) => {
  const headers = ['Unit', 'Lodge', 'Region'].concat(
    dates.map((d) => moment(new Date(d)).format(format)),
  );
  let rows = [headers];

  regions.forEach((region) => {
    region.lodges.forEach(({ name, units, getMonthAvailbility }) => {
      const unitRows = [];

      units
        .filter((unit) => unit.numberOfUnits > 0)
        .forEach((unit) => {
          const unitRow = [unit.name, name, region.name];

          dates.forEach((date) => {
            const unitsAvailability = getUnitsDateAvailability(
              getMonthAvailbility,
              date,
            );
            const unitAvailabilityOnDay = unitsAvailability
              ? unitsAvailability.find(
                  (u) => u.bookingEngineCode === unit.bookingEngineCode,
                )
              : 0;
            unitRow.push(unitAvailabilityOnDay.isThereSpace);
          });

          unitRows.push(unitRow);
        });

      rows = [...rows, ...unitRows];
    });
  });

  return rows;
};

const getUnitsDateAvailability = (array = [], searchDate) => {
  const day = array.find(({ date }) => {
    return date === searchDate;
  });
  if (!day) return [];
  return day.units;
};
