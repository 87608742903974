import React from 'react';
import styled from 'styled-components';
import Text from '../../components/Base/Text/Text';
import ButtonText from '../../components/Base/ButtonText/ButtonText';
import Space from '../../components/Base/Space';
import { Slider } from '../../components/Slider/Slider';
import { spacing, colors } from '../../common';
import Wistia from '../../modules/video/Wistia';
import Media from 'react-media';
import { Button } from '../../components';

export const Hero = (props) => {
  const { items, small } = props;

  if (items.length > 1) {
    return (
      <Slider
        infinite={true}
        pagerType="arrow"
        slidesToShow={1}
        slidesToScroll={1}
      >
        {items.map((item, index) => {
          return (
            <div key={`hero-${index}`}>
              <HeroImage
                small={small}
                item={item}
                bgPosition={item.bgPosition}
              />
            </div>
          );
        })}
      </Slider>
    );
  }

  if (items.length === 1) {
    return (
      <HeroImage
        small={small}
        item={items[0]}
        bgPosition={items[0].bgPosition}
      />
    );
  }

  return null;
};

const HeroImage = (props) => {
  const { item, index, bgPosition } = props;
  if (!item.image) item.image = null;

  const StyledHeroImage = styled.div`
    text-align: center;
    position: relative;
  `;

  const StyledItem = styled.div`
    position: relative;
    display: flex;
    background: ${item.image ? `url('${item.image}')` : `${colors.bgLight}`};
    background-size: ${item.image ? 'cover' : '10%'};
    background-position: ${bgPosition};
    min-height: ${item.image ? '425px' : 'auto'};
  `;

  const StyledCaptionWrapper = styled.div`
    width: 100%;
    background: ${item.image
      ? 'linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.75) 100%)'
      : 'none'};
  `;

  const StyledCaption = styled.div`
    margin: auto;
    padding-top: ${spacing.space5};
    padding-bottom: ${spacing.space5};
    text-align: center;
    display: flex;
    align-items: flex-end;
    height: 100%;
    @media (min-width: 768px) {
      padding-top: ${spacing.space10};
      padding-bottom: ${spacing.space10};
    }
    a {
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    }
  `;

  return (
    <StyledHeroImage className="StyledHeroImage">
      <div className={'hero'} key={index}>
        <StyledItem key={index} className="StyledItem">
          <StyledCaptionWrapper className="StyledCaptionWrapper">
            <StyledCaption className="StyledCaption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    {item.subTitle && (
                      <Space bottom size={spacing.space1}>
                        <Text
                          align="center"
                          display={item.image ? 'bodyWhite' : 'body'}
                        >
                          {item.subTitle}
                        </Text>
                      </Space>
                    )}
                    {item.title && (
                      <React.Fragment>
                        <Media
                          query={{ minWidth: 768 }}
                          render={() => {
                            return (
                              <Text
                                align="center"
                                display={item.image ? 'heroWhite' : 'hero'}
                                level="h1"
                              >
                                {item.title}
                              </Text>
                            );
                          }}
                        />
                        <Media
                          query={{ maxWidth: 767 }}
                          render={() => {
                            return (
                              <Text
                                align="center"
                                display={
                                  item.image ? 'heroWhiteMobile' : 'heroMobile'
                                }
                                level="h1"
                              >
                                {item.title}
                              </Text>
                            );
                          }}
                        />
                      </React.Fragment>
                    )}
                    {item.description && (
                      <Space top size={spacing.space3}>
                        <Text
                          align="center"
                          display={item.image ? 'introWhite' : 'intro'}
                        >
                          {item.description}
                        </Text>
                      </Space>
                    )}
                    {item.links && item.links.length ? (
                      <Space top size={spacing.space3}>
                        {item.links.map((link) => (
                          <HeroLinks {...link} />
                        ))}
                      </Space>
                    ) : null}
                    {item.videoLink && (
                      <Wistia
                        popover
                        id={item.videoLink}
                        linkLabel={item.linkLabel}
                        hero={true}
                      />
                    )}
                    {!item.videoLink && item.link && (
                      <a href={item.link.linkUrl}>
                        <ButtonText
                          display="white"
                          iconAfter="long-arrow-right"
                        >
                          {item.link.linkLabel}
                        </ButtonText>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </StyledCaption>
          </StyledCaptionWrapper>
        </StyledItem>
      </div>
    </StyledHeroImage>
  );
};

const HeroLinks = ({ title, type, link, video_id }) => {
  if (type === 'video') {
    return <Wistia popover hashedId={video_id} title={title} />;
  }

  if (type === 'button') {
    return (
      <a href={link}>
        <Space inline left right size={spacing.space1}>
          <Button>{title}</Button>
        </Space>
      </a>
    );
  }

  return (
    <a href={link}>
      <Space inline left right size={spacing.space1}>
        <ButtonText display="white">{title}</ButtonText>
      </Space>
    </a>
  );
};

Hero.defaultProps = {
  items: [],
  item: {},
  small: false,
  bgPosition: 'center center',
};

HeroImage.defaultProps = {
  items: [],
  item: {},
  small: false,
};
