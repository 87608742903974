import React from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../common';
class Tabs extends React.Component {
  render() {
    const { activeIndex, children } = this.props;

    const StyledTabsWrapper = styled.div`
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: ${spacing.space3};
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 99%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    `;

    const StyledTabs = styled.span`
      position: relative;
      margin: 0 0 ${spacing.space4};
      display: flex;
      justify-content: flex-start;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: ${colors.borderLight};
      overflow: scroll;
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    `;

    return (
      <StyledTabsWrapper>
        <StyledTabs activeIndex={activeIndex}>{children}</StyledTabs>
      </StyledTabsWrapper>
    );
  }
}

Tabs.defaultProps = {
  children: null,
  activeIndex: false,
};

export default Tabs;
