import * as React from 'react';
import { Footer } from '../../../components';
import { api } from '../../../common';

export const Foot = (props) => (
  <Footer
    socialMenuItems={api.footer.social}
    contactMenuItems={api.footer.contact}
    legalMenuItems={api.footer.legal}
  />
);
