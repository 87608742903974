import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledInputCheckBox from './Style/InputCheckBoxStyle';

class InputCheckBox extends React.Component {
  render() {
    const { name, value, display, disabled, checked, hover, onClick } =
      this.props;

    const StyledInput = styled.input`
      width: 0;
      height: 0;
      opacity: 0;
      position: absolute;
      cursor: pointer;
    `;

    let icon;
    if (checked) {
      icon = <i className={`icon ${hover ? 'icon-close' : 'icon-check'}`} />;
    }

    return (
      <StyledInputCheckBox
        onClick={onClick}
        display={display}
        checked={checked}
        disabled={disabled}
        hover={hover}
      >
        <StyledInput
          type="checkbox"
          defaultChecked={value || undefined}
          name={name}
          disabled={disabled}
        />
        {icon}
      </StyledInputCheckBox>
    );
  }
}

InputCheckBox.propTypes = {
  /** Sets the size of the component */
  display: PropTypes.oneOf(['base']),
  /** Name of input for HTML */
  name: PropTypes.string,
  /** Value of input for HTML */
  value: PropTypes.string,
  /** Click action */
  onClick: PropTypes.func,
  /** Hover action */
  hover: PropTypes.bool,
  /** Is the input checked? */
  checked: PropTypes.bool,
  /** Is the input disabled? */
  disabled: PropTypes.bool,
};

InputCheckBox.defaultProps = {
  display: 'base',
  name: null,
  value: null,
  checked: false,
  hover: false,
  disabled: false,
  onClick: () => {},
};

export default InputCheckBox;
