//import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../common';

const StyledBorder = styled.hr`
  border-top: 1px solid transparent;
  border-color: ${(props) =>
    props.border ? colors.borderLight : 'transparent'};
  margin-top: ${(props) => (props.margin ? props.margin : '1rem')};
  margin-bottom: ${(props) => (props.margin ? props.margin : '1rem')};
`;

function Border(props) {
  const { border, margin } = props;
  return <StyledBorder border={border} margin={margin} />;
}

Border.defaultProps = {
  border: true,
  margin: null,
};

export default Border;
