import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  tracking,
  fontWeight,
  sizes,
  fonts,
  colors,
  spacing,
  timing,
} from '../../../common';
import ButtonText from '../../Base/ButtonText/ButtonText';
import Space from '../../Base/Space';

export class DropMenu extends Component {
  render() {
    const { items, footerUrl, showMenu } = this.props;
    return (
      <StyledDrop showMenu={showMenu}>
        <StyledWrapper>
          <StyledMenuWrapper>
            <StyledDropMenu>
              {items &&
                items
                  .filter((c) => c.showMenu !== false)
                  .map((c) => (
                    <li key={c.name}>
                      <Link to={`${footerUrl}${c.to}`} title={c.name}>
                        {c.name}
                      </Link>
                    </li>
                  ))}
            </StyledDropMenu>
            {footerUrl && (
              <Space top size={spacing.space1}>
                <Link to={footerUrl}>
                  <ButtonText size="small" iconAfter="long-arrow-right">
                    View All
                  </ButtonText>
                </Link>
              </Space>
            )}
          </StyledMenuWrapper>
        </StyledWrapper>
      </StyledDrop>
    );
  }
}

const StyledDrop = styled.ul`
  position: absolute;
  z-index: 9999;
  padding: ${spacing.space0};

  visibility: hidden;
  opacity: 0;
  display: none;
  transition: all ${timing.slow};
  text-align: left;
`;

const StyledWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.space2};
  background-color: ${colors.bgLight};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  white-space: normal;
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-image: initial;
  border-radius: ${spacing.space05};
`;

const StyledMenuWrapper = styled.div`
  padding: ${spacing.space3} ${spacing.space3};
`;

const StyledDropMenu = styled.ul`
  margin: ${spacing.space0};
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  transition: opacity ${timing.slow};
  &::-webkit-scrollbar {
    display: none;
  }
  li {
    &:first-child a {
      padding-top: ${spacing.space0};
    }
    text-transform: none;
    margin: 0;
    font-family: ${fonts.sans};
    font-size: ${sizes.textSmall};
    letter-spacing: ${tracking.small};
    line-height: 1;
    font-weight: ${fontWeight.normal};
    display: flex;
    transition: all 0.2s;
    a {
      color: ${colors.textBase};
      transition: color ${timing.base};
      text-decoration: none;
      padding-top: ${spacing.space1};
      padding-bottom: ${spacing.space1};
      :hover {
        color: ${colors.textDark};
        text-decoration: none;
      }
    }
    small {
      margin-left: ${spacing.space1};
    }
  }
`;

DropMenu.defaultProps = {
  showMenu: false,
};
