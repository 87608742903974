import * as React from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { isSameDay } from '../../utils/dates';
import Media from 'react-media';
import {
  Text,
  Space,
  ButtonText,
  ButtonSelect,
  ResultUnit,
} from '../../components';
import { spacing, language } from '../../common';
import { UnitInformation } from '../Results/ui/UnitInformation';
import {
  AddToCartContainer,
  RemoveFromCartContainer,
} from '../Cart/CartContainer';
import { RateContainer } from './RateContainer';

export class BestMatchContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      unit: { bestMatch },
    } = this.props;

    const bestDay = bestMatch.sort((a, b) => {
      return a.difference - b.difference;
    });

    this.state = {
      focused: false,
      startDate: bestDay.length ? moment.utc(bestDay[0].startDate) : null,
      endDate: bestDay.length ? moment.utc(bestDay[0].endDate) : null,
      showCalendar: false,
    };
  }

  onDatesChanges = (startDate) => {
    const {
      unit: { bestMatch },
    } = this.props;
    const day = bestMatch.find((day2) =>
      isSameDay(startDate, moment(day2.startDate)),
    );

    this.setState({
      startDate: moment(day.startDate),
      endDate: moment(day.endDate),
    });
  };

  cartOptions = (selected, rate) => {
    const { unit: u, lodge, guests, contentfulUnit } = this.props;
    const { startDate, endDate } = this.state;

    if (!selected) {
      return (
        <AddToCartContainer
          startDate={startDate}
          endDate={endDate}
          unit={u}
          contentfulUnit={contentfulUnit}
          rate={rate}
          guests={guests}
          lodge={lodge}
          selected={selected}
          render={(addToCart) => (
            <ButtonSelect
              selected={selected}
              addDescription="Add to itinerary"
              removeDescription="Remove"
              hover={true}
              onClick={() => addToCart()}
            />
          )}
        />
      );
    }
    return (
      <RemoveFromCartContainer
        selected={selected}
        render={(removeFromCart) => (
          <ButtonSelect
            hover={true}
            addDescription="Add to itinerary"
            removeDescription="Remove"
            selected={selected ? true : false}
            onClick={() => removeFromCart()}
          />
        )}
      />
    );
  };

  displayAvailableUnit(selected) {
    const { unit: u, lodge: l, contentfulUnit, contentfulLodge } = this.props;
    let rate = u.baseRate.replace('per adult', '');
    rate = rate.replace('per suite', '');
    rate = rate.replace('per person', '');

    return (
      <ResultUnit
        unit={contentfulUnit ? contentfulUnit.headline : u.name}
        key={u.code}
        units={`${u.available.neededUnits} available`}
        rate={`${rate} ${contentfulUnit.roomPrefix}`}
        renderAction={() =>
          this.cartOptions(selected, this.props.onGetRate(u, false))
        }
        renderDetails={
          <UnitInformation
            unit={u}
            lodge={l}
            buttonText={language.unit.btnDetails}
            {...{ contentfulLodge, contentfulUnit }}
          />
        }
      />
    );
  }

  checkSelected = (u, startDate, endDate, guests) => {
    const {
      cart: { items },
    } = this.props;

    if (!startDate || !endDate) return false;

    const testString = `${u.code}-${startDate.format(
      'YYYY-MM-DD',
    )}-${endDate.format('YYYY-MM-DD')}-${guests.children}-${guests.adults}`;

    const isSelected = items.find((i) => {
      const selectedString = `${i.unit.code}-${i.startDate}-${i.endDate}-${i.children}-${i.adults}`;

      return selectedString === testString;
    });

    return isSelected;
  };

  render() {
    const { startDate, endDate } = this.state;
    const {
      unit: {
        bestMatch,
        available: { available },
      },
      suggested,
    } = this.props;
    const {
      unit: u,
      lodge: l,
      guests,
      contentfulUnit,
      contentfulLodge,
    } = this.props;
    const selected = this.checkSelected(u, startDate, endDate, guests);

    if (available) {
      return this.displayAvailableUnit(selected);
    }

    if (!bestMatch.length || !suggested) return null;

    const calendarProps = {
      block: true,
      hideKeyboardShortcutsPanel: true,
      numberOfMonths: 3,
      date: moment(startDate),
      initialDate: this.props.startDate,
      initialVisibleMonth: () => moment(this.props.startDate).subtract(1, 'M'),
      focused: true,
      displayFormat: 'YYYY-MM-DD',
      onDateChange: (date) => this.onDatesChanges(date),
      id: 'calendar_available',
      isDayBlocked: (day1) => {
        return !bestMatch.some((day2) =>
          isSameDay(day1, moment(day2.startDate)),
        );
      },
      isDayHighlighted: (day1) => {
        return bestMatch.some((day2) =>
          isSameDay(day1, moment(day2.startDate)),
        );
      },
    };

    let rateUnit = u.baseRate.replace('per adult', '');
    rateUnit = rateUnit.replace('per suite', '');
    rateUnit = rateUnit.replace('per person', '');

    return (
      <RateContainer
        startDate={startDate}
        endDate={endDate}
        unit={u}
        {...guests}
        render={(loading, rate) => (
          <ResultUnit
            unit={contentfulUnit ? contentfulUnit.headline : u.name}
            key={u.code}
            units={`${u.available.neededUnits} available`}
            rate={`${rateUnit} ${contentfulUnit.roomPrefix}`}
            dates={null}
            renderAction={() => this.cartOptions(selected, rate.total)}
            renderDetails={
              <UnitInformation
                unit={u}
                lodge={l}
                buttonText={language.unit.btnDetails}
                {...{ contentfulLodge, contentfulUnit }}
              />
            }
          >
            <React.Fragment>
              <Space top size={spacing.space2}>
                <Text
                  display="formLabel"
                  level="span"
                  html={language.unit.labelDate}
                />
              </Space>
              <Space bottom size={spacing.space2}>
                <Text display="formSelection">
                  {moment(startDate).format('YYYY-MM-DD')}{' '}
                  <Space inline left size={spacing.space1} />
                  <i
                    style={{ fontSize: '14px' }}
                    className="icon icon-arrow-right"
                  />
                  <Space inline left size={spacing.space1} />
                  {moment(endDate).format('YYYY-MM-DD')}
                </Text>
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <Space top size={spacing.space05}>
                    <ButtonText
                      inline
                      size="small"
                      iconAfter="chevron-right"
                      onClick={() =>
                        this.setState((state) => ({
                          showCalendar: !state.showCalendar,
                        }))
                      }
                    >
                      {!this.state.showCalendar
                        ? language.unit.btnDateEdit
                        : language.unit.btnDateClose}
                    </ButtonText>
                  </Space>
                </div>
              </Space>

              {this.state.showCalendar ? (
                <Space top bottom size={spacing.space2}>
                  <Text display="formLabel" level="span">
                    Select alternative arrival date
                  </Text>
                  <Media query="(min-width: 992px)">
                    {(matches) =>
                      matches ? (
                        <DayPickerSingleDateController
                          {...calendarProps}
                          renderCalendarInfo={() => (
                            <Space padding bottom size={spacing.space2}>
                              <div className="d-flex justify-content-between align-items-baseline">
                                <Space padding left right size={spacing.space3}>
                                  <span
                                    style={{
                                      color: '#88af99',
                                    }}
                                  >
                                    &#x25cf;
                                  </span>{' '}
                                  Available arrival dates for your trip
                                </Space>
                                <Space padding left right size={spacing.space3}>
                                  <ButtonText
                                    inline
                                    size="small"
                                    iconAfter="close"
                                    onClick={() =>
                                      this.setState((state) => ({
                                        showCalendar: !state.showCalendar,
                                      }))
                                    }
                                  >
                                    {!this.state.showCalendar
                                      ? language.unit.btnDateEdit
                                      : language.unit.btnDateClose}
                                  </ButtonText>
                                </Space>
                              </div>
                            </Space>
                          )}
                          noNavButtons={true}
                          numberOfMonths={3}
                        />
                      ) : (
                        <div style={{ height: 500 }}>
                          <DayPickerSingleDateController
                            {...calendarProps}
                            noNavButtons={true}
                            orientation="verticalScrollable"
                            renderCalendarInfo={null}
                            numberOfMonths={3}
                            verticalHeight={700}
                          />
                        </div>
                      )
                    }
                  </Media>
                </Space>
              ) : null}
            </React.Fragment>
          </ResultUnit>
        )}
      />
    );
  }
}
