import React from 'react';
import { DateRangePicker } from 'react-dates';
import '../Availability/styles/datepicker.css';
export class DateWrapper extends React.Component {
  constructor(props) {
    super(props);

    const { startDate, endDate } = this.props;

    this.state = {
      focusedInput: null,
      startDate: startDate,
      endDate: endDate,
      bestMatch: true,
      suggested: true,
      otherLodges: false,
    };
  }

  render() {
    const { startDate, endDate, focusedInput } = this.state;

    return (
      <DateRangePicker
        {...this.props}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={({ startDate, endDate }) =>
          this.setState({
            startDate,
            endDate,
          })
        }
        focusedInput={focusedInput || this.props.focusedInput}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
      />
    );
  }
}
