import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Text, Space, Box } from '../../components';
import { spacing } from '../../common';
import { Head } from '../Navigation/ui/Header';

class AccessContainer extends React.Component {
  state = {
    message: null,
    error: null,
    email: '',
  };

  handleSubmit = async (e, mutate) => {
    e.preventDefault();

    if (!this.state.email) {
      this.setState({
        error: 'Email is required.',
      });
      return;
    }

    const {
      data: { authenticateUser },
    } = await mutate({
      variables: {
        email: this.state.email,
      },
    });

    this.setState({
      message: authenticateUser.message,
    });
  };

  render() {
    return (
      <>
        <Head />
        <Space top bottom size={spacing.space10}>
          <div className="container">
            <div className="d-flex justify-content-center m-3">
              <Mutation mutation={LOGIN_MUTATION}>
                {(mutate, { loading }) => {
                  return (
                    <div className="col-6">
                      <Box
                        renderTitle={'Sign in to view Singita Calendar'}
                        renderDescription={
                          'Enter your email to login. Approved users and current Singita Partners will receive a email with a link to access the calendar. For new accounts a request will be sent to Singita for approval.'
                        }
                        display="background"
                      >
                        {this.state.message ? (
                          <Box display="background" className={'match'}>
                            <Text>{this.state.message}</Text>
                          </Box>
                        ) : (
                          <form onSubmit={(e) => this.handleSubmit(e, mutate)}>
                            {this.state.error && (
                              <div className="text-danger mb-1">
                                {this.state.error}
                              </div>
                            )}
                            <div className="form-group">
                              <Text display="formLabel">Email</Text>
                              <input
                                name="email"
                                type="email"
                                className="form-control"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                              <Space top bottom size={spacing.space2}>
                                <Button size="base">
                                  {loading ? 'Loading' : 'Sign in'}
                                </Button>
                              </Space>
                            </div>
                          </form>
                        )}
                      </Box>
                    </div>
                  );
                }}
              </Mutation>
            </div>
          </div>
        </Space>
      </>
    );
  }
}

const LOGIN_MUTATION = gql`
  mutation authenticateUser($email: String!) {
    authenticateUser(email: $email) {
      message
    }
  }
`;

export default AccessContainer;
