import React from 'react';
import styled from 'styled-components';
import Space from '../Base/Space';
import { spacing } from '../../common';
import ButtonText from '../Base/ButtonText/ButtonText';
import ButtonDropdown from '../Base/ButtonDropdown/ButtonDropdown';
import useOutsideClick from '../../common/useClickOutside';

const StyledOverlay = styled.div`
  position: absolute;
  top: 48px;
  left: 0px;
  right: 0px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
  display: inline-block;
  max-height: 500px;
  min-width: 300px;
  visibility: visible;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-image: initial;
  border-radius: 4px;
  padding: 24px;
`;

const SelectBox = (props) => {
  const {
    focusedInput,
    name,
    children,
    showNext = true,
    onNextClicked,
    toggleDropDown,
    plural = 'Regions',
    placeholder = 'All Regions',
    renderLabel,
    selected = [],
  } = props;

  const isOpen = props.isOpen && focusedInput === name;
  const ref = useOutsideClick(() => toggleDropDown(false, name));
  const icon = isOpen ? 'icon-chevron-up' : 'icon-chevron-down';

  const getOption = () => {
    if (renderLabel) {
      return renderLabel();
    }

    if (selected.length > 0) {
      return `${plural}: ${selected.length}`;
    }

    if (selected.length === 0) {
      return `${placeholder}`;
    }

    return selected[0].label;
  };

  return (
    <div style={{ position: 'relative' }}>
      <ButtonDropdown
        onClick={() => toggleDropDown(!isOpen, props.name)}
        display="select"
        size="small"
        block={true}
        hover={isOpen}
      >
        <span>{getOption()}</span>
        <Space inline left size={spacing.space1}>
          <i className={`icon ${icon}`} style={{ fontSize: '10px' }} />
        </Space>
      </ButtonDropdown>

      <div className="menu_box">
        {isOpen ? (
          <div ref={ref}>
            <StyledOverlay>
              {children}

              {showNext && (
                <Space top size={spacing.space2}>
                  <div className="text-right">
                    <ButtonText
                      iconAfter="angle-right"
                      onClick={() => onNextClicked(name)}
                    >
                      Next
                    </ButtonText>
                  </div>
                </Space>
              )}
            </StyledOverlay>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SelectBox;
