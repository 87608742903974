import { Component } from 'react';

class WistiaEmbed extends Component {
  render() {
    return null;
  }
}

WistiaEmbed.defaultProps = {
  popover: false,
};

export default WistiaEmbed;
