import React, { Component } from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Media from 'react-media';

import { DropMenu } from '../../../components';
import {
  tracking,
  fontWeight,
  sizes,
  fonts,
  colors,
  spacing,
  timing,
} from '../../../common';
import { Space } from '../../../components';

export class PrimaryMenu extends Component {
  constructor() {
    super();

    this.state = {
      hoverLink: null,
    };

    this.showDrop = this.showDrop.bind(this);
    this.hideDrop = this.hideDrop.bind(this);
  }

  showDrop(event) {
    this.setState({
      hoverLink: event.target.id,
    });
  }

  hideDrop() {
    this.setState({
      hoverLink: null,
    });
  }

  render() {
    const { items } = this.props;

    const ItemProps = {
      open: { x: 0, opacity: 1 },
      closed: { x: -20, opacity: 0 },
    };

    const StyledItem = styled(posed.li(ItemProps))`
      position: relative;
    `;

    const checkLink = (link) => {
      var pattern = /^((http|https|ftp):\/\/)/;

      return pattern.test(link);
    };

    return (
      <StyledPrimaryMenu
        className="StyledPrimaryMenu"
        mobileBreakpoint={process.env.REACT_APP_MOBILE_BREAKPOINT}
      >
        {items &&
          items.map((p, index) => (
            <StyledItem key={index}>
              {!checkLink(p.url) ? (
                <Link
                  activeClassName="active"
                  to={p.url}
                  title={p.name}
                  id={index}
                >
                  {p.name}
                  {p.drop && p.drop.length && (
                    <Media
                      query={`(min-width: ${process.env.REACT_APP_MOBILE_BREAKPOINT}px)`}
                      render={() => {
                        return (
                          <Space inline left size={spacing.space1}>
                            <i className="icon icon-angle-down" />
                          </Space>
                        );
                      }}
                    />
                  )}
                </Link>
              ) : (
                <a
                  activeClassName="active"
                  href={p.url}
                  // title={p.name}
                  title="Test me"
                  id={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {p.name}

                  {p.drop && p.drop.length && (
                    <Media
                      query={`(min-width: ${process.env.REACT_APP_MOBILE_BREAKPOINT}px)`}
                      render={() => {
                        return (
                          <Space inline left size={spacing.space1}>
                            <i className="icon icon-angle-down" />
                          </Space>
                        );
                      }}
                    />
                  )}
                </a>
              )}

              {p.drop && p.drop.length && (
                <Media
                  query={`(min-width: ${process.env.REACT_APP_MOBILE_BREAKPOINT}px)`}
                  render={() => {
                    return (
                      <DropMenu
                        key={index}
                        showMenu={true}
                        items={p.drop}
                        footerUrl={p.url}
                      />
                    );
                  }}
                />
              )}
            </StyledItem>
          ))}
      </StyledPrimaryMenu>
    );
  }
}

const StyledPrimaryMenu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${(props) => props.mobileBreakpoint}px) {
    flex-direction: row;
  }
  li {
    text-transform: uppercase;
    text-align: center;
    font-family: ${fonts.serif};
    font-size: ${sizes.textMedium};
    letter-spacing: ${tracking.small};
    line-height: 1;
    font-weight: ${fontWeight.regular};
    @media (min-width: ${(props) => props.mobileBreakpoint}px) {
      margin: ${spacing.space0} ${spacing.space2};
    }
    a {
      color: ${colors.textDark};
      transition: color ${timing.base};
      text-decoration: none;
      padding-top: ${spacing.space2};
      padding-bottom: ${spacing.space2};
      display: inline-block;
      @media (min-width: ${(props) => props.mobileBreakpoint}px) {
        padding: 0;
      }
      :hover,
      &.active {
        color: ${colors.textDark};
        text-decoration: none;
      }
    }
    &:hover ul,
    ul:hover {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }
`;
