import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Text from '../../components/Base/Text/Text';

export const Loader = (props) => {
  const { label } = props;

  const opacityKeyframes = keyframes`
    0% {
      opacity: 0.25;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.25;
    }
`;

  const StyledLoader = styled.div`
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    animation: ${opacityKeyframes} 2s linear infinite;
  `;

  return (
    <StyledLoader>
      <Text display="title">{label}</Text>
    </StyledLoader>
  );
};

Loader.defaultProps = {
  label: 'Loading',
};

Loader.propTypes = {
  label: PropTypes.string,
};
