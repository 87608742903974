import gql from 'graphql-tag';

export const MUTATION_ADD_TO_CART = gql`
  mutation addToCart($input: InputCartDetails) {
    addToCart(input: $input) {
      items {
        id
        startDate
        adults
        numberOfUnits
        unit {
          code
          bookingEngineCode
          lodge {
            name
          }
          baseRate
          name
          currency
        }
        children
        endDate
        rate
      }
    }
  }
`;

export const MUTATION_REMOVE_FROM_CART = gql`
  mutation addToCart($id: String!) {
    removeFromCart(id: $id) {
      items {
        id
        startDate
        adults
        unit {
          code
          bookingEngineCode
          lodge {
            name
          }
          baseRate
          name
          currency
        }
        children
        endDate
        rate
      }
    }
  }
`;
