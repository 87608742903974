import * as React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { Logo } from '../../components/Base/Logo/Logo';
import ButtonText from '../Base/ButtonText/ButtonText';
import Space from '../Base/Space';
import Media from 'react-media';
import { PrimaryMenu } from './Menus/primaryMenu';
import { SecondaryMenu } from './Menus/SecondaryMenu';
import { TertiaryMenu } from './Menus/TertiaryMenu';
import { colors, spacing } from '../../common';

const StyledLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${spacing.space1} 0;
  background-color: ${colors.bgLight};
  border-bottom: 1px solid ${colors.borderLight};
`;

const StyledHeader = styled.div`
  display: block;
  padding: ${spacing.space1} 0;
  border-bottom: 1px solid ${colors.borderLight};
  background-color: ${colors.baseWhite};
  position: relative;
`;

const HeaderCollapsedProps = {
  closed: {
    height: '0px',
    delay: 300,
    staggerChildren: 50,
  },
  open: {
    height: 'auto',
    delayChildren: 200,
    staggerChildren: 50,
  },
};

const StyledHeaderCollapsed = styled(posed.div(HeaderCollapsedProps))`
  display: block;
  overflow: hidden;
`;

const StyledHeaderCollapsedContent = styled.div`
  background-color: ${colors.bgLight};
  padding: ${spacing.space1} 0;
  border-bottom: 1px solid ${colors.borderLight};
  display: block;
  @media (min-width: ${(props) => props.mobileBreakpoint}px) {
    display: none;
  }
`;

export class Header extends React.Component {
  state = {
    isOpen: false,
  };

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;

    const {
      primaryMenuItems,
      secondaryMenuItems,
      tertiaryMenuItems,
      primaryCta,
      links,
    } = this.props;

    const menuToggle = (
      <Space left right size={spacing.space2}>
        <ButtonText
          iconBefore={this.state.isOpen ? 'close' : 'menu'}
          onClick={this.toggleOpen}
        >
          {this.state.isOpen ? 'Close' : 'Menu'}
        </ButtonText>
      </Space>
    );

    return (
      <div className={'Header'}>
        {links && (
          <StyledLinks>
            <Media
              query="(min-width: 400px)"
              render={() => {
                return links;
              }}
            />
          </StyledLinks>
        )}

        <StyledHeader>
          <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <a
                  href={this.props.url || 'https://singita.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Media
                    query="(min-width: 576px)"
                    render={() => {
                      return (
                        <Logo
                          width="140px"
                          height="65px"
                          color={colors.brandBase}
                        />
                      );
                    }}
                  />
                  <Media
                    query="(max-width: 575px)"
                    render={() => {
                      return (
                        <Logo
                          width="90px"
                          height="41px"
                          color={colors.brandBase}
                        />
                      );
                    }}
                  />
                </a>

                {primaryMenuItems && (
                  <Media
                    query={`(min-width: ${
                      this.props.breakpoint ||
                      process.env.REACT_APP_MOBILE_BREAKPOINT
                    }px)`}
                    render={() => {
                      return <PrimaryMenu items={primaryMenuItems} />;
                    }}
                  />
                )}
              </div>

              <div className="d-flex align-items-center flex-shrink-0">
                {primaryCta && (
                  <Media
                    query={`(min-width: ${
                      this.props.breakpoint ||
                      process.env.REACT_APP_MOBILE_BREAKPOINT
                    }px)`}
                    render={() => {
                      return primaryCta;
                    }}
                  />
                )}
                <Media
                  query={`(max-width: ${
                    process.env.REACT_APP_MOBILE_BREAKPOINT - 1
                  }px)`}
                  render={() => {
                    return menuToggle;
                  }}
                />

                {secondaryMenuItems && (
                  <Media
                    query={`(min-width: ${
                      this.props.breakpoint ||
                      process.env.REACT_APP_MOBILE_BREAKPOINT
                    }px)`}
                    render={() => {
                      return <SecondaryMenu items={secondaryMenuItems} />;
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </StyledHeader>

        <StyledHeaderCollapsed pose={isOpen ? 'open' : 'closed'}>
          <StyledHeaderCollapsedContent
            mobileBreakpoint={process.env.REACT_APP_MOBILE_BREAKPOINT}
          >
            <div className="container-fluid">
              {primaryMenuItems && (
                <Media
                  query={`(max-width: ${process.env.REACT_APP_MOBILE_BREAKPOINT}px)`}
                  render={() => {
                    return <PrimaryMenu items={primaryMenuItems} />;
                  }}
                />
              )}

              {secondaryMenuItems && (
                <Media
                  query={`(max-width: ${
                    process.env.REACT_APP_MOBILE_BREAKPOINT - 1
                  }px)`}
                  render={() => {
                    return <SecondaryMenu items={secondaryMenuItems} />;
                  }}
                />
              )}

              {primaryCta && (
                <div className="d-flex justify-content-center">
                  <Media
                    query={`(max-width: ${process.env.REACT_APP_MOBILE_BREAKPOINT}px)`}
                    render={() => {
                      return primaryCta;
                    }}
                  />
                </div>
              )}
            </div>
          </StyledHeaderCollapsedContent>
        </StyledHeaderCollapsed>

        {tertiaryMenuItems && <TertiaryMenu items={tertiaryMenuItems} />}
      </div>
    );
  }
}
