import gql from 'graphql-tag';

export const QUERY_LODGE = gql`
  query getLodge($id: String!) {
    viewLodge(id: $id) {
      name
      code
      region {
        name
        country
      }
      lodgeDetails {
        title
        content
        featured_media {
          source_url
        }
        hero {
          source_url
        }
        mapImage {
          source_url
        }
        acf
        videos {
          title
          videoId
        }
        activities {
          title
          content
          featured_media {
            source_url
          }
        }
      }
      code
      units {
        id
        name
        code
        bedsPerUnit
        numberOfUnits
        currency
        bookingEngineCode
        hasGreenSeason
      }
    }
  }
`;
