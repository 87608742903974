import * as React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import {
  tracking,
  fontWeight,
  sizes,
  fonts,
  colors,
  spacing,
  timing,
} from '../../../common';

const Item = posed.li({
  open: { x: 0, opacity: 1 },
  closed: { x: -20, opacity: 0 },
});

export function SecondaryMenu(items) {
  return (
    <StyledSecondaryMenu
      mobileBreakpoint={process.env.REACT_APP_MOBILE_BREAKPOINT}
    >
      {items.items.map((p) => (
        <Item key={p.name}>
          <a href={p.url} title={p.name}>
            {p.name}
          </a>
        </Item>
      ))}
    </StyledSecondaryMenu>
  );
}

const StyledSecondaryMenu = styled.ul`
  padding: 0;
  margin-top: ${spacing.space2};
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  @media (min-width: ${(props) => props.mobileBreakpoint}px) {
    flex-direction: row;
  }
  li {
    text-transform: uppercase;
    font-family: ${fonts.sans};
    font-size: ${sizes.textTiny};
    letter-spacing: ${tracking.small};
    line-height: 1;
    font-weight: ${fontWeight.bold};
    margin: ${spacing.space0} ${spacing.space2} ${spacing.space0}
      ${spacing.space3};
    display: flex;
    @media (min-width: ${(props) => props.mobileBreakpoint}px) {
      margin: ${spacing.space1} ${spacing.space2} ${spacing.space0}
        ${spacing.space2};
    }
    a {
      color: ${colors.textBrand};
      transition: color ${timing.base};
      text-decoration: none;
      padding-top: ${spacing.space1};
      padding-bottom: ${spacing.space1};
      :hover {
        color: ${colors.textDark};
        text-decoration: none;
      }
    }
  }
`;
