import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../common';

const StyledLink = styled(Link)`
  text-decoration: none !important;
  color: ${(props) => (props.color ? props.color : colors.brandBase)};
  border: none;
  border-bottom: 1px solid transparent;
  &:focus,
  &:hover {
    text-decoration: none !important;
    color: ${(props) => (props.color ? props.color : colors.brandBase)};
    border-bottom: 1px solid
      ${(props) => (props.color ? props.color : colors.brandBase)};
  }
`;

StyledLink.propTypes = {
  active: PropTypes.bool,
};

export default (props) => <StyledLink {...props} />;
