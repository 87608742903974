export const language = {
  formAvailibility: {
    title: 'Check Availability',
    description: '',
    labelStartDate: 'Arrival',
    labelEndDate: 'Departure',
  },
  formAvailabilityWizard: {
    title: 'Plan your trip',
    steps: ['Regions', 'Lodges', 'Dates', 'Guests', 'Submit'],
    stepTitle: ['Regions', 'Lodges', 'Dates', 'Guests', ''],
    description: [
      "Select the regions you're interested in:",
      "These lodges are availabile in the regions you've selected. Please select the ones you're interested in: ",
      'Select the dates which will suit you:',
      "Please indicate how many adults will need to be accommodated. <br />If your booking includes children, please enquire directly on the next step.<br /> <br />View our child policy for each lodge <a href='https://singita.com/wp-content/uploads/2019/05/SINGITA-CHILDREN-AT-A-GLANCE-.pdf' target='_blank'>here.</a>",
    ],
    btnNext: ['Lodges', 'Dates', 'Guests', 'Submit'],
    btnPrev: ['', 'Regions', 'Lodges', 'Dates', 'Guests'],
    availabilityTitle: 'Check Availabiltiy',
    availabilityDescription:
      'To see if we have availability for your preferred criteria, please click the button below. ',
    availabilityButton: 'Check Now',
    enquiryTitle: 'Speak with a Singita Travel Advisor',
    enquiryDescription:
      'Need some help to plan your trip? Enquire below to contact one of our Singita Travel Advisors.',
    enquiryButton: 'Enquire Now',
  },
  formSelection: {
    title: 'Check Availability',
    description: '',
    labelStartDate: 'Arrival',
    labelEndDate: 'Departure',
    filterAlternateDate: 'My dates are flexible, show alternatives',
    filterRegion: 'Show other lodges in the region',
    childrenNote:
      'Children must be 10 years and older to stay at Singita Boulders, Singita Sabora Tented Camp and Singita Mara River Tented Camp.',
    filterResults: 'Filter Results',
    btnSubmit: 'Check Availability',
  },
  results: {
    title: 'Your Search Results',
    bestMatchTitle: 'Your Search Results',
    success:
      '<p><b>Your preferred travel dates are available!</b> We have a team of Singita Travel Advisors who are ready to plan your trip. Simply select the results that suit your preferences best and submit your enquiry.</p><p><b>Note:</b>These results indicate availability and do not guarantee a booking.</p>',
    noresult:
      '<p>Your preferred dates are unfortunately not available, but there is availability at other Singita lodges or on alternate dates.</p><p>We have a team of Singita Travel Advisors who are ready to plan your trip. Simply select the results that suit your preferences best and submit your enquiry.</p><p><b>Note:</b> These results indicate availability and do not guarantee a booking.</p>',
    zeroResult: `<p>Unfortunately, we do not have availability for your dates.</p><p>We have a team of Singita Travel Advisors who are ready to assist you with planning your trip. Simply select your preferences and submit your enquiry below, alternatively, please try new dates.</p><p><b>Note:</b> These results indicate availability and do not guarantee a booking.</p>`,
    toddleOtherResults:
      '<p>Unfortunately, we do not have availability for the guests you have selected.</p><ul><li>Children must be 10 years and older to stay at Singita Boulders, Singita Sabora Tented Camp and Singita Mara River Tented Camp.</li><li>We have included other family friendly options in the region.</li><li>Select the result that may suit you from the list below and it will be added to the itinerary summary on the right.</li><li>You can adjust the dates by selecting the calendar picker below each result.</li></ul></p><p><b>Note:</b> This is an indication of availability and by selecting a search result you are not securing/guaranteeing a booking. <br/>Your request will be sent to a Singita Travel Advisor who will complete the booking process for you.</p>',
  },
  itinerary: {
    title: 'Your Itinerary',
    description:
      'This is a summary of the accommodation you have selected. After submitting your booking request, a Singita Travel Advisor will make contact to book and confirm your trip.',
    noteEmpty: 'You haven’t selected any options yet.',
    btnSubmit: 'Send Availability Enquiry',
    sendEnquiry: 'Send Enquiry',
    covidTerms: 'View COVID-19 Booking Terms',
  },
  formEnquiry: {
    title: 'Send Availability Enquiry',
    description:
      'Thank you for checking availability and showing interest in planning your Singita safari. Fill out your details in the below and one of our Singita Travel Advisors will receive every detail of your enquiry and contact to guide you through the planning and booking process.',
    btnContact: 'Speak with us',
  },

  formEnquiryItinirary: {
    title: 'Your Itinerary',
    description:
      'This is a summary of the accommodation you’ve selected. One of our Singita Travel Advisors will contact you shortly to plan your trip.',
    covidTerms: 'View COVID-19 Booking Terms',
  },
  lodge: {
    btnDetails: 'Lodge Details',
    unitTypes: 'Accommodation Snapshot',
    titleTour: 'Tour this lodge',
    titleImages: 'Images',
    titleVideos: 'Videos',
    titleWtd: 'What to see and do at',
    titleRates: 'Rates',
    titleInfo: 'Lodge Information',
    noteNotAvailibleTitle: 'Alternative options are available',
    availableTitle: 'Great news, we have availability!',
    availableDescription:
      'Select the result that best suits you from the list below and it will be added to the itinerary summary on the right.',
    otherAvailableTitle: 'Alternative date options',
    noteNotAvailible:
      'Unfortunately, there isn’t availability for your selected dates. However, the following alternate options may suit your requirements.',
    otherAvailable:
      'There is availability in other room categories at this lodge for <strong>dates nearby your selection</strong>. We have included the best match for your trip below.',
  },
  unit: {
    btnDetails: 'Room & Rate Details',
    noteNotAvailible:
      'There is no availability in this unit for your current date selection<br/>but we have included the best match for your trip.',
    labelDate: 'Suggested Date',
    btnDateEdit: 'View availability calendar',
    btnDateClose: 'Close calendar',
    titleRates: 'Rates',
  },
  formContact: {
    title: 'Speak with us',
    description:
      'Let us know if you have any questions. Also feel free to request a call, we will make contact within 24 hours during the week and 48 hours over the weekend.',
  },
  confirmation: {
    headline: 'You are officially one step closer to your safari!',
    copy: 'Thanks for sending us your details, keep an eye on your inbox for a message from one of our Travel Experts',
  },
  bookingInfo:
    'Thank you for taking the first step of planning your Singita experience. We have a team of Singita Travel Advisors who are ready to plan your trip. Please note that these results indicate availability and do not guarantee a booking.',
  childrenText:
    "If your booking includes children, please enquire directly. View our child policy for each lodge <a href='https://assets.ctfassets.net/wds1hqrprqxb/2zrmpggoWK5aSjfFB5ifr9/010d50c7228024c06108002070f73c26/Singita_Activities_-_Children_at_a_Glance.pdf' target='_blank'><b>here</b>.</a>",
  bookingNote:
    'These results indicate availability and do not guarantee a booking. One of our Singita Travel Advisors will contact you shortly to plan your trip.',
  calendarToc:
    "Please note that this report is an availability snapshot and is updated hourly. For the latest availability, please contact <a href='mailto:reservations@singita.com'>reservations@singita.com</a> or call<a href='tel:+27216833424'>+27(0)216833424</a>",
};
