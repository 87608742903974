import * as React from 'react';
import styled from 'styled-components';
import { spacing } from '../../common';
import Text from '../../components/Base/Text/Text';
import Space from '../../components/Base/Space';
import AnimateHeight from 'react-animate-height';
import Media from 'react-media';

const StyledTileContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const StyledTile = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-bottom: ${(props) => (props.portrait ? '140%' : '80%')};
  margin-bottom: ${spacing.space4};
  background-image: ${(props) =>
    props.image
      ? `url('${props.image}')`
      : "url('https://singita.com/wp-content/uploads/2019/01/Singita-Pattern-200x200px.jpg')"};
  @media (min-width: 640px) {
    padding-bottom: ${(props) => (props.portrait ? '140%' : '60%')};
  }
  @media (min-width: 1200px) {
    padding-bottom: ${(props) => (props.portrait ? '140%' : '65%')};
  }
`;

const StyledCaptionWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: ${spacing.space4};
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 100%
    );
    content: '';
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledCaption = styled.div`
  width: 100%;
  padding: inherit;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export class Tile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  onMouseOver() {
    this.setState({ hovered: true });
  }

  onMouseOut() {
    this.setState({ hovered: false });
  }

  render() {
    const { hovered } = this.state;
    const { title, subTitle, excerpt, link, image, col, portrait } = this.props;

    return (
      <StyledTileContainer className={col}>
        <StyledTile
          image={image}
          portrait={portrait}
          onMouseOver={() => this.onMouseOver()}
          onMouseOut={() => this.onMouseOut()}
        >
          <StyledCaptionWrapper>
            <StyledCaption>
              {subTitle && (
                <Space bottom size={spacing.space1}>
                  <Text display="meta">{subTitle}</Text>
                </Space>
              )}

              <Text html={title} display="titleWhite" />

              <Media query="(max-width: 767px)">
                {(matches) =>
                  matches ? (
                    <Space top size={spacing.space2}>
                      {excerpt && <Text html={excerpt} display="bodyWhite" />}
                      {link && link}
                    </Space>
                  ) : (
                    <AnimateHeight
                      duration={500}
                      height={hovered ? 'auto' : 0}
                      easing="ease"
                    >
                      <Space top size={spacing.space2}>
                        {excerpt && <Text html={excerpt} display="bodyWhite" />}
                        {link && link}
                      </Space>
                    </AnimateHeight>
                  )
                }
              </Media>
            </StyledCaption>
          </StyledCaptionWrapper>
        </StyledTile>
      </StyledTileContainer>
    );
  }
}

Tile.defaultProps = {
  title: null,
  subTitle: null,
  excerpt: null,
  link: null,
  image: null,
  col: null,
  portrait: false,
};
