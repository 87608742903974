import gql from 'graphql-tag';

export const RESULTS_PAGE_QUERY = gql`
  query GetResultsData {
    lodges: lodgeCollection {
      items {
        fullTitle
        code
        slug
        image {
          url
        }
        highlights: highlightsCollection(limit: 10) {
          items {
            ... on Image {
              sys {
                id
              }
              altText
              image {
                url
              }
            }
          }
        }
        region {
          ... on Region {
            name
            country
          }
        }
        units: unitsCollection(limit: 20) {
          items {
            headline
            description {
              json
            }
            shortDescription
            rates
            unitCode
            roomPrefix
            unitRates
            hasChildRates
            ratesSuffix
          }
        }
      }
    }

    promotions: promotionsCollection {
      items {
        sys {
          id
        }
        name
        headline
        slug
        featuredImage {
          url
        }
        content {
          json
        }
        promotionsDates
        highlight
        regions: regionCollection(limit: 10) {
          items {
            name
            headline
            code
            country
          }
        }
        lodges: lodgesCollection(limit: 10) {
          items {
            fullTitle
            code
          }
        }
      }
    }
  }
`;
