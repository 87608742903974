import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledImage = styled.img`
  margin: 0;
  padding: 0;
  flex: none;
  width: ${(props) => (props.fluid ? '100%' : 'auto')};
  height: auto;
  max-width: 100%;
  display: ${(props) => (props.fixed ? 'inline-block' : 'block')};
  border-radius: ${(props) => (props.round ? '100%' : '0')};
`;

function Image(props) {
  const { src, alt, fixed, fluid, round } = props;
  return (
    <StyledImage
      round={round}
      fixed={fixed}
      src={src}
      alt={alt}
      fluid={fluid}
    />
  );
}

Image.defaultProps = {
  fixed: false,
  src: null,
  fluid: true,
  round: false,
};

Image.propTypes = {
  /** Alt text for the image */
  alt: PropTypes.string.isRequired,
  /** Image src */
  src: PropTypes.string.isRequired,
  /** Is this image not fluid */
  fixed: PropTypes.bool.isRequired,
  /** Is the Image responsive? */
  fluid: PropTypes.bool.isRequired,
  /** Is the Image round? */
  round: PropTypes.bool,
};

export default Image;
