import React from 'react';
import PropTypes from 'prop-types';
import StyledBox from './Style/BoxStyle';
import { spacing } from '../../common';
import { Border, Text, Space } from '../../components';

export class Box extends React.Component {
  render() {
    const {
      children,
      display,
      size,
      renderTitle,
      renderDescription,
      renderAction,
      className,
    } = this.props;

    return (
      <StyledBox display={display} size={size} className={className}>
        {renderTitle && (
          <Space bottom size={spacing.space2}>
            <div className="d-flex justify-content-between">
              <Text display="title" level="span">
                {renderTitle}
              </Text>
              {renderAction && renderAction}
            </div>
          </Space>
        )}
        {renderDescription && (
          <div>
            <Text display="body">{renderDescription}</Text>
          </div>
        )}
        {renderTitle && (
          <Space top bottom size={spacing.space3}>
            <Border />
          </Space>
        )}
        {children}
      </StyledBox>
    );
  }
}

Box.propTypes = {
  /** Sets the display style */
  display: PropTypes.oneOf([
    'base',
    'border',
    'background',
    'backgroundDark',
    'backgroundBrand',
    'backgroundLightNew',
  ]),
  /** Sets the size of the component */
  size: PropTypes.oneOf(['base', 'small', 'none']),
  /** Contents of the Box */
  children: PropTypes.node.isRequired,
  /** Renders the Title */
  renderTitle: PropTypes.string,
  /** Renders the action to be added next to Titl */
  renderDescription: PropTypes.string,
};

Box.defaultProps = {
  children: null,
  size: 'base',
  display: 'base',
  renderTitle: null,
  renderDescription: null,
  renderAction: null,
};

export default Box;
