import * as React from 'react';

export class TabsWrapper extends React.PureComponent {
  state = {
    currentTab: 0,
  };

  setTab = (index) => {
    this.setState({
      currentTab: index,
    });
  };

  render() {
    return this.props.render(this.state.currentTab, this.setTab);
  }
}
