import { colors } from '../../../../common';

export const displayStyles = {
  base: {
    bgColor: colors.brandBase,
    textColor: colors.textWhite,
    borderColor: colors.brandBase,
    // For CSS hover
    bgColorHover: colors.bgWhite,
    borderColorHover: colors.borderBase,
    textColorHover: colors.textBrand,
    hover: {
      bgColor: colors.brandBase,
      borderColor: colors.borderBase,
      textColor: colors.textBrand,
    },
  },
  outline: {
    bgColor: colors.bgWhite,
    textColor: colors.textBrand,
    borderColor: colors.brandBase,
    // For CSS hover
    bgColorHover: colors.brandBase,
    borderColorHover: colors.brandBase,
    textColorHover: colors.textWhite,
    hover: {
      bgColor: colors.brandBase,
      borderColor: colors.brandBase,
      textColor: colors.textWhite,
    },
  },
};

export const sizeStyles = {
  base: {
    padding: '18px 50px',
  },
  small: {
    padding: '13px 30px',
  },
};
