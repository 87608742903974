import React from 'react';
import PropTypes from 'prop-types';
import ButtonIconStyle from './Style/ButtonIconStyle';

class ButtonIcon extends React.Component {
  render() {
    const { display, size, icon, onClick, hover, as, ...props } = this.props;

    return (
      <ButtonIconStyle
        display={display}
        size={size}
        hover={hover}
        onClick={onClick}
        as={as}
        {...props}
      >
        <i className={`icon icon-${icon}`} />
      </ButtonIconStyle>
    );
  }
}

ButtonIcon.propTypes = {
  /** Sets the display style */
  size: PropTypes.oneOf(['base', 'small']),
  /** Sets the size of the component */
  display: PropTypes.oneOf(['base', 'simple']),
  /** Renders the Description */
  icon: PropTypes.string,
  /** Click action */
  onClick: PropTypes.func,
  /** Hover state style */
  hover: PropTypes.bool,
  /** Change the HTML element that is rendered */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

ButtonIcon.defaultProps = {
  size: 'small',
  display: 'base',
  hover: false,
  icon: 'close',
  as: 'span',
  onClick: () => {},
};

export default ButtonIcon;
