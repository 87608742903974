import React from 'react';
import TabStyle from './Style/TabStyle';
import { Text } from '../../../components';

class Tab extends React.Component {
  render() {
    const { display, active, disabled, children, hover, onClick } = this.props;

    return (
      <TabStyle
        display={display}
        hover={hover}
        active={active}
        disabled={disabled}
        onClick={onClick}
      >
        <Text display={active ? 'tabActive' : 'tab'} level="span">
          {children}
        </Text>
      </TabStyle>
    );
  }
}

Tab.defaultProps = {
  display: 'base',
  children: null,
  hover: false,
  active: false,
  disabled: false,
};

export default Tab;
