import * as React from 'react';
import { Query } from 'react-apollo';
import { QUERY_LODGE } from './graphql/queries';
import { LodgePage } from './ui/LodgePage';
import { Loader } from '../../components';

export class LodgeContainer extends React.PureComponent {
  componentDidMount() {
    window.scrollTop = 0;
  }
  render() {
    return (
      <Query
        query={QUERY_LODGE}
        variables={{ id: this.props.match.params.lodgeId }}
      >
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`;

          if (loading) return <Loader />;

          const { viewLodge } = data;

          return <LodgePage {...this.props} lodge={viewLodge} />;
        }}
      </Query>
    );
  }
}
