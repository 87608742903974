import { sizes, colors } from '../../../../common';

export const displayStyles = {
  base: {
    color: colors.textBrand,
    borderColor: colors.textBrand,
    bgColor: 'transparent',
    // For CSS hover
    bgColorHover: colors.textBrand,
    colorHover: 'white',
    hover: {
      color: 'white',
      bgColor: colors.textBrand,
    },
  },
  baseBrand: {
    color: colors.textWhite,
    borderColor: colors.textBrand,
    bgColor: colors.textBrand,
    // For CSS hover
    bgColorHover: colors.textBrand,
    colorHover: 'white',
    hover: {
      color: 'white',
      bgColor: colors.textBrand,
    },
  },
  simple: {
    color: colors.textDark,
    borderColor: 'transparent',
    bgColor: 'transparent',
    // For CSS hover
    bgColorHover: 'transparent',
    colorHover: colors.textBrand,
    hover: {
      color: colors.textBrand,
      bgColor: 'transparent',
    },
  },
};

export const sizeStyles = {
  base: {
    fontSize: sizes.textMedium,
    size: '32px',
  },
  small: {
    fontSize: sizes.textTiny,
    size: '24px',
  },
};
