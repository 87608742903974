import * as React from 'react';
import { Query } from 'react-apollo';
import queryString from 'query-string';
import { withUrlSync } from '../../utils/urlSync';
import { formatUrlVariables } from '../../utils/dates';
import gql from 'graphql-tag';
import { TermsConditions } from '../Results/ui/TermsConditions';
import Calendar from './ui/Calendar';
import { ButtonText, Loader } from '../../components';
import moment from 'moment-timezone';
import { Header, theme } from '@singita/components';
class CalendarContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      location: { search },
    } = this.props;
    const values = queryString.parse(search, { arrayFormat: 'bracket' });
    const today = new Date(Date.now());

    this.state = {
      openModal: false,
      openContactModal: false,
      openFilterModal: false,
      startDate: values.startDate || today,
      offset: 0,
      form: {
        additionalLodges: [],
        regions: values.regions || [],
        lodges: values.lodges || [],
        startDate: values.startDate
          ? moment(values.startDate).tz('Africa/Johannesburg')
          : moment().tz('Africa/Johannesburg').startOf('day'),
        endDate: values.endDate
          ? moment(values.endDate).tz('Africa/Johannesburg')
          : moment().tz('Africa/Johannesburg').add(1, 'month').startOf('day'),
      },
    };
  }

  formatQueryVariables = (state) => {
    const { form, offset, startDate } = state;

    const query = {
      ...form,
      startDate: form.startDate.format('YYYY-MM-DD'),
      endDate: form.endDate.format('YYYY-MM-DD'),
      month: moment(startDate).month() + 1,
      year: moment(startDate).year(),
      offset,
    };

    return query;
  };

  toggleFilterModal = () => {
    this.setState({
      openFilterModal: !this.state.openFilterModal,
    });
  };

  handleFormChange = (form) => {
    this.setState(
      (state) => ({
        ...state,
        form: {
          ...state.form,
          ...form,
        },
      }),
      () => this.props.onSearchStateChange(formatUrlVariables(form)),
    );
  };

  setAdditionalOptions = (key, updates) => {
    this.setState(
      (state) => ({
        form: {
          ...state.form,
          [key]: updates,
        },
      }),
      () => this.props.onSearchStateChange(formatUrlVariables(this.state.form)),
    );
  };

  render() {
    return (
      <Query
        query={QUERY_CALENDAR_REGIONS}
        variables={this.formatQueryVariables(this.state)}
      >
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`;

          return (
            <React.Fragment>
              <Header
                logoColor={theme.colors.brandBrown}
                renderButton={(colorScheme) => (
                  <ButtonText
                    as={'a'}
                    href="https://singita.com"
                    iconBefore="login"
                    size="small"
                  >
                    Back to Singita.com
                  </ButtonText>
                )}
              />
              <div className="container-fluid no-overflow">
                {!data || !data.findRegions ? (
                  <Loader />
                ) : (
                  <Calendar
                    loading={loading}
                    form={this.state.form}
                    handleFormChange={this.handleFormChange}
                    rooms={data}
                    offset={this.state.offset}
                    startDate={this.state.startDate}
                    setStartDate={(date) => this.setState({ startDate: date })}
                  />
                )}
              </div>
              <TermsConditions name="calendarToc" languageKey="calendarToc" />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

CalendarContainer = withUrlSync(CalendarContainer);

export { CalendarContainer };

export const QUERY_CALENDAR_REGIONS = gql`
  fragment LodgeDetails on Lodge {
    name
    code
    region {
      name
      country
    }
    lodgeDetails {
      title
    }
    code
    units {
      id
      name
      numberOfUnits
      bookingEngineCode
    }
    getMonthAvailbility(
      startDate: $startDate
      endDate: $endDate
      month: $month
      year: $year
      offset: $offset
    ) {
      date
      units {
        bookingEngineCode
        isThereSpace
      }
    }
  }
  query getRegionResults(
    $regions: [String]
    $lodges: [String]
    $month: Int!
    $year: Int!
    $startDate: String
    $endDate: String
    $offset: Int
  ) {
    findRegions(input: { regions: $regions }) {
      name
      country
      code
      lodges(input: { lodges: $lodges }) {
        ...LodgeDetails
      }
    }
  }
`;
