import { colors } from '../../../../common';

export const displayStyles = {
  base: {
    borderWidth: '0px',
    borderColor: 'transparent',
    cursor: 'pointer',
    // For CSS hover
    borderColorHover: colors.textBrand,
    hover: {
      borderColor: colors.textBrand,
    },
    active: {
      borderColor: colors.textBrand,
    },
    disabled: {
      opacity: '0.5',
      cursor: 'not-allowed',
    },
  },
};
