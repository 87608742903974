import React from 'react';
import styled from 'styled-components';

const Button = ({ as: B, ...props }) => <B {...props} />;

const StyledButtonSelect = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-content: flex-end;
`;

export default StyledButtonSelect;
