import styled, { css } from 'styled-components';
import { displayStyles } from './style';
import { spacing } from '../../../../common';

const defaultProps = {
  display: 'base',
};

const display = (props) => {
  let style = displayStyles[props.display];
  if (props.hover) {
    style = {
      ...style,
      ...displayStyles[props.display].hover,
    };
  }
  if (props.active) {
    style = {
      ...style,
      ...displayStyles[props.display].active,
    };
  }
  if (props.disabled) {
    style = {
      ...style,
      ...displayStyles[props.display].disabled,
    };
  }
  return css`
    border-color: ${style.borderColor};
    opacity: ${style.opacity};
    cursor: ${style.cursor};
    text-decoration: none;
    :focus,
    :hover {
      border-color: ${style.borderColorHover};
      outline: 0;
    }
  `;
};

const StyledTab = styled.span`
  ${display};
  margin: 0 ${spacing.space3} -1px 0;
  padding-bottom: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  flex-shrink: 0;
  flex-wrap: nowrap;
`;

export default StyledTab;

StyledTab.defaultProps = defaultProps;
