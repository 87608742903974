import * as React from 'react';
import { Query } from 'react-apollo';
import { Loader } from '../../components';
import { QUERY_PAGE } from './graphql/queries';
import { ConfirmationPage } from './ui/ConfirmationPage';

export class ConfirmationContainer extends React.PureComponent {
  componentDidMount() {
    window.scrollTop = 0;
  }
  render() {
    return (
      <Query query={QUERY_PAGE} variables={{ slug: 'confirmation' }}>
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`;

          if (loading) return <Loader />;

          const { viewPage } = data;

          return <ConfirmationPage page={viewPage} />;
        }}
      </Query>
    );
  }
}
