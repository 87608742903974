import * as React from 'react';
import { Header } from '../../../components';
import { api } from '../../../common';
import Sticky from 'react-stickynode';
export const Head = (props) => (
  <Sticky enabled={true} top={0} innerZ={999}>
    <Header
      {...props}
      primaryMenuItems={api.header.primary}
      secondaryMenuItems={api.header.secondary.filter(
        (c) => c.name === 'Contact',
      )}
      tertiaryMenuItems={api.header.tertiary}
      primaryCta={null}
    />
  </Sticky>
);
