//import PropTypes from 'prop-types';
import { createElement } from 'react';
import styled, { css } from 'styled-components';
import { displayStyles } from './style';

const defaultProps = {
  children: null,
  display: 'body',
};

const display = (props) => {
  const style = displayStyles[props.display];
  return css`
    font-family: ${style.fontFamily};
    font-weight: ${style.fontWeight};
    font-size: ${style.fontSize};
    color: ${style.fontColor};
    text-transform: ${style.textTransform};
    line-height: ${style.lineHeight};
    letter-spacing: ${style.letterSpacing};
    margin-bottom: ${style.marginBottom};
  `;
};

const truncate = (props) => {
  if (props.truncate) {
    return `
      overflow: hidden;
      whiteSpace: nowrap;
      text-overflow: ellipsis;
    `;
  }
};

const Element = ({ level, children, inline, noWrap, ...props }) =>
  createElement(level, props, children);

const StyledText = styled(Element)`
  text-align: ${(props) => [props.align]};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  white-space: ${(props) => props.noWrap && 'nowrap'};
  ${display};
  ${truncate};
`;

export default StyledText;

StyledText.defaultProps = defaultProps;
