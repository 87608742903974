import React from 'react';
import 'react-dates/initialize';
import { QUERY_ALL_REGIONS_AND_LODGES } from './graphql/queries';
import { Query } from 'react-apollo';
import { Space, Box, Loader, Button, ContentModal } from '../../components';
import { Form } from './ui/Form';
import moment from 'moment';
import * as R from 'ramda';
import { spacing } from '../../common';
import styled from 'styled-components';
const inputOptions = ['regions', 'lodges', 'guests', 'dates'];

class AvailabilityContainer extends React.Component {
  constructor(props) {
    super(props);

    const { search = {} } = this.props;

    const values = search;

    this.state = {
      focusedInput: null,
      isOpen: false,
      form: {
        additionalLodges: [],
        regions: values.regions || [],
        lodges: values.lodges || [],
        startDate: values.startDate
          ? moment.utc(values.startDate)
          : moment.utc().startOf('day').add(10, 'days'),
        endDate: values.endDate
          ? moment.utc(values.endDate)
          : moment.utc().startOf('day').add(15, 'days'),
        guests: {
          adults: parseInt(values.adults, 10) || 2,
          children: parseInt(values.children, 10) || 0,
          toddler: parseInt(values.toddler, 10) || 0,
          baby: parseInt(values.baby, 10) || 0,
        },
      },
    };
  }

  filterStateRegions = (arr) => {
    const {
      form: { regions },
    } = this.state;

    if (!regions.length) return arr;

    return arr.filter((r) => regions.includes(r.code));
  };

  setRegion = (item, key, setLodges) => {
    this.setState(
      (state) => ({
        form: {
          ...state.form,
          [key]: this.updateArr(state.form[key], item.code),
          lodges: setLodges
            ? this.setLodges(
                state.form.lodges,
                item.lodges,
                this.shouldAppendArray(state.form[key], item.code),
              )
            : state.form.lodges,
        },
      }),
      () => this.props.onSearchStateChange(this.state.form),
    );
  };

  setLodges = (currentLodges, newLodges, shouldAppend) => {
    const lodgeCodes = newLodges.map((l) => l.code);
    if (!shouldAppend) {
      return R.uniq(R.concat(currentLodges, lodgeCodes));
    }

    return currentLodges.filter((l) => !lodgeCodes.includes(l));
  };

  setLodge = (item, key) => {
    this.setState(
      (state) => ({
        form: {
          ...state.form,
          [key]: this.updateArr(state.form[key], item.code),
        },
      }),
      () => this.props.onSearchStateChange(this.state.form),
    );
  };

  updateArr(arr, item) {
    const inArray = arr.findIndex((a) => a === item);

    if (inArray > -1) {
      return arr.filter((item, index) => index !== inArray);
    } else {
      return [...arr, item];
    }
  }

  shouldAppendArray(arr, item) {
    const inArray = arr.findIndex((a) => a === item);

    return inArray > -1;
  }

  guestSet = (inc, item) => {
    this.setState(
      (state) => {
        const {
          form: { guests },
        } = state;

        let temp = guests[item.value] + inc;

        if (temp < item.min) {
          temp = item.min;
        }

        return {
          form: {
            ...state.form,
            guests: {
              ...guests,
              [item.value]: temp,
            },
          },
        };
      },
      () => this.props.onSearchStateChange(this.state.form),
    );
  };

  updateState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  dateSet = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.setState(
        (state) => ({
          form: {
            ...state.form,
            startDate: moment
              .utc(startDate.format('YYYY-MM-DD'))
              .startOf('day'),
            endDate: moment.utc(endDate.format('YYYY-MM-DD')).startOf('day'),
          },
        }),
        () => this.props.onSearchStateChange(this.state.form),
      );
    }
  };

  handleNextClick = (itemClicked) => {
    this.setState({
      focusedInput: inputOptions[inputOptions.indexOf(itemClicked) + 1],
    });
  };

  handleDropdownClick = (status, itemClicked) => {
    this.setState({
      isOpen: status,
      focusedInput: itemClicked,
    });
  };

  render() {
    return (
      <Query query={QUERY_ALL_REGIONS_AND_LODGES}>
        {({ loading, error, data = {} }) => {
          if (loading) return <Loader label="Loading Regions" />;
          const { findRegions: regionsList } = data;
          const form = (
            <React.Fragment>
              <Form
                regionsList={regionsList}
                {...this.state.form}
                focusedInput={this.state.focusedInput}
                isOpen={this.state.isOpen}
                toggleDropDown={this.handleDropdownClick}
                onNextClicked={this.handleNextClick}
                filterStateRegions={this.filterStateRegions}
                onSetRegion={this.setRegion}
                onSetLodge={this.setLodge}
                onGuestSet={this.guestSet}
                onUpdateState={this.updateState}
                onDateSet={this.dateSet}
                showRegions={this.props.showregions}
                showLodges={this.props.showlodges}
                showDates={this.props.showDates}
                showGuests={this.props.showGuests}
              />
              {this.props.render ? this.props.render(data, this.state) : null}
            </React.Fragment>
          );

          if (this.props.openFilterModal) {
            return (
              <ContentModal
                isOpen={this.props.openFilterModal}
                size="large"
                render={() => (
                  <Box>
                    {form}
                    <Space top size={spacing.space4}>
                      <StyledWrapper>
                        <Button onClick={this.props.onFilterSubmit}>
                          Filter
                        </Button>
                      </StyledWrapper>
                    </Space>
                  </Box>
                )}
              />
            );
          }

          return form;
        }}
      </Query>
    );
  }
}

AvailabilityContainer.defaultProps = {
  onSearchStateChange: () => {},
  showlodges: true,
  showregions: true,
};

export { AvailabilityContainer };

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
