import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PagerArrow } from './PagerArrow';

export function Pager(props) {
  const { pagerType } = props;

  const StyledPager = styled.div`
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top: ${(props) => (pagerType === 'round' ? '37%' : '50%')};
    margin-top: -32px;
    width: 100%;
  `;

  const StyledPagerArrowLeft = styled.span`
    display: inline-block;
    text-align: center;
    padding: 0;
    width: ${() => (pagerType === 'round' ? '34px' : '48px')};
    height: ${() => (pagerType === 'round' ? '34px' : '64px')};
    cursor: pointer;
    margin: 0;
    float: left;
    margin-left: ${(props) => (pagerType === 'round' ? '17px' : '0px')};
    transform: rotate(180deg);
  `;

  const StyledPagerArrowRight = styled.span`
    display: inline-block;
    text-align: center;
    padding: 0;
    width: ${() => (pagerType === 'round' ? '34px' : '48px')};
    height: ${() => (pagerType === 'round' ? '34px' : '64px')};
    cursor: pointer;
    margin: 0;
    float: right;
    margin-right: ${(props) => (pagerType === 'round' ? '17px' : '0px')};
  `;

  return (
    <StyledPager>
      <StyledPagerArrowLeft onClick={() => props.onPrev()}>
        <PagerArrow />
      </StyledPagerArrowLeft>
      <StyledPagerArrowRight onClick={() => props.onNext()}>
        <PagerArrow />
      </StyledPagerArrowRight>
    </StyledPager>
  );
}

Pager.defaultProps = {
  pagerType: 'round',
};

Pager.propTypes = {
  pagerPosition: PropTypes.string,
  pagerType: PropTypes.string,
  pagerStyle: PropTypes.string,
};
