import React from 'react';
import styled, { css } from 'styled-components';
import { displayStyles, sizeStyles } from './style';
import { fonts, timing, fontWeight } from '../../../../common';

const defaultProps = {
  children: null,
  display: 'base',
};

const display = (props) => {
  let style = displayStyles[props.display];
  if (props.hover) {
    style = {
      ...style,
      ...displayStyles[props.display].hover,
    };
  }
  return css`
    color: ${style.color};
    border-bottom-color: ${style.borderBottomColor};
    :focus,
    :hover {
      color: ${style.colorHover};
      border-bottom-color: ${style.colorHover};
      outline: 0;
      text-decoration: none;
    }
  `;
};

const size = (props) => {
  const style = sizeStyles[props.size];
  return css`
    font-size: ${style.fontSize};
  `;
};

const Button = ({ as: B, ...props }) => <B {...props} />;

const StyledButtonText = styled(Button)`
  ${display};
  ${size};
  margin-bottom: 0;
  padding-bottom: 2px;
  text-transform: none;
  cursor: pointer;
  line-height: 14px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-family: ${fonts.sans};
  font-weight: ${fontWeight.regular};
  transition: all ${timing.slow};
  display: ${(props) => (props.block ? 'inline-block' : 'inline')};
  width: ${(props) => (props.block ? '100%' : 'inherit')};
`;

export default StyledButtonText;

StyledButtonText.defaultProps = defaultProps;
