import 'babel-polyfill';
import 'react-app-polyfill/ie9';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'react-dates/initialize';
//import * as Sentry from '@sentry/browser';
import './App.css';
import 'react-dates/lib/css/_datepicker.css';

import { unregister } from './serviceWorker';
import { ApolloProvider } from 'react-apollo';
import Modal from 'react-modal';
import { ThemeProvider } from '@emotion/react';

import { client } from './apollo';
import { Routes } from './routes';
import { theme } from '@singita/components';

// Sentry.init({
//   dsn: "https://715209100e284680a5164f98cb68f6da@sentry.io/1855220"
// });

Modal.setAppElement('#root');

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
unregister();
