import { colors } from '../../../common';

export const displayStyles = {
  base: {
    cursor: 'pointer',
    color: colors.textBrand,
    border: colors.textBrand,
    background: 'transparent',
    // For CSS hover
    colorHover: 'white',
    bgColorHover: colors.textBrand,
    hover: {
      color: 'white',
      background: colors.textBrand,
    },
    checked: {
      color: 'white',
      background: colors.textBrand,
    },
    disabled: {
      opacity: '0.5',
      cursor: 'not-allowed',
      color: 'white',
      border: colors.textBrand,
    },
  },
};
