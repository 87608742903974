import React from 'react';
import styled, { css } from 'styled-components';
import { displayStyles, sizeStyles } from './style';
import { fonts, tracking, timing, fontWeight } from '../../../../common';

const defaultProps = {
  children: null,
  display: 'base',
  size: 'base',
};

const flex = (props) => {
  if (props.display === 'select') {
    return css`
      display: flex;
      justify-content: space-between;
    `;
  }

  return css`
    display: ${(props) => (props.block ? 'block' : 'inline-block')};
  `;
};

const display = (props) => {
  let style = displayStyles[props.display];
  if (props.hover) {
    style = {
      ...style,
      ...displayStyles[props.display].hover,
    };
  }
  return css`
    border-color: ${style.borderColor};
    background-color: ${style.bgColor};
    color: ${style.textColor};
    :focus,
    :hover {
      background-color: ${style.bgColorHover};
      border-color: ${style.borderColorHover};
      color: ${style.textColorHover};
      outline: 0;
    }
  `;
};

const size = (props) => {
  const style = sizeStyles[props.size];
  return css`
    padding: ${style.padding};
  `;
};

const Button = ({ as: B, ...props }) => <B {...props} />;

const StyledButton = styled(Button)`
  ${display};
  ${size};
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 0;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-family: ${fonts.sans};
  letter-spacing: ${tracking.small};
  font-weight: ${fontWeight.bold};
  transition: all ${timing.slow};
  width: ${(props) => (props.block ? '100%' : 'inherit')};
  ${flex};
`;

export default StyledButton;

StyledButton.defaultProps = defaultProps;
