import React from 'react';
import {
  Space,
  Text,
  Border,
  SelectIncrement,
  SelectBox,
  SelectCheckbox,
} from '../../../components';
import { spacing, language, options, getGuestsText } from '../../../common';
import { DateWrapper } from '../DateWrapper';

export const Form = ({
  regionsList,
  regions,
  lodges,
  guests,
  startDate,
  endDate,
  showRegions,
  showLodges,
  showDates,
  showGuests,
  focusedInput,
  isOpen,
  ...props
}) => {
  return (
    <React.Fragment>
      {showRegions && (
        <Space bottom size={spacing.space1}>
          <Space bottom size={spacing.space1}>
            <Text display="formLabel" level="span">
              Region
            </Text>
          </Space>
          <SelectBox
            selected={regions}
            name={'regions'}
            onNextClicked={(focusedInput) => props.onNextClicked(focusedInput)}
            toggleDropDown={(status, focusedInput) =>
              props.toggleDropDown(status, focusedInput)
            }
            isOpen={isOpen && focusedInput === 'regions'}
            focusedInput={focusedInput}
          >
            {regionsList.map((r) => (
              <SelectCheckbox
                value={r.code}
                key={r.code}
                checked={regions.includes(r.code)}
                label={`${r.name} - ${r.country}`}
                onClick={() => props.onSetRegion(r, 'regions', true)}
              />
            ))}
          </SelectBox>
        </Space>
      )}
      {showLodges && (
        <Space bottom size={spacing.space1}>
          <Space bottom size={spacing.space1}>
            <Text display="formLabel" level="span">
              Lodges
            </Text>
          </Space>
          <SelectBox
            placeholder={'All Lodges'}
            plural={'Lodges'}
            selected={lodges}
            name={'lodges'}
            onNextClicked={(focusedInput) => props.onNextClicked(focusedInput)}
            toggleDropDown={(status, focusedInput) =>
              props.toggleDropDown(status, focusedInput)
            }
            isOpen={isOpen && focusedInput === 'lodges'}
            focusedInput={focusedInput}
          >
            {props.filterStateRegions(regionsList).map((r) => (
              <React.Fragment key={r.code}>
                <Text display="formLabel" level="span">
                  {r.name}
                </Text>
                {r.lodges.map((l) => (
                  <SelectCheckbox
                    key={l.code}
                    value={l.code}
                    checked={lodges.includes(l.code)}
                    label={`${l.name}`}
                    onClick={() => props.onSetLodge(l, 'lodges')}
                  />
                ))}
                <Border size="small" />
              </React.Fragment>
            ))}
          </SelectBox>
        </Space>
      )}
      {showGuests && (
        <Space bottom size={spacing.space1}>
          <Space bottom size={spacing.space1}>
            <Text display="formLabel" level="span">
              Guests
            </Text>
          </Space>
          <SelectBox
            selected={regions}
            renderLabel={() => getGuestsText(guests)}
            showNext={true}
            name={'guests'}
            onNextClicked={(focusedInput) => {
              console.log(focusedInput);
              props.onNextClicked(focusedInput);
            }}
            toggleDropDown={(status, focusedInput) =>
              props.toggleDropDown(status, focusedInput)
            }
            isOpen={isOpen && focusedInput === 'guests'}
            focusedInput={focusedInput}
          >
            {options.guests.map((r) => (
              <SelectIncrement
                key={r.value}
                value={guests[r.value]}
                label={`${r.label}`}
                sub={r.sub || null}
                onIncrement={() => props.onGuestSet(1, r)}
                onDecrement={() => props.onGuestSet(-1, r)}
              />
            ))}
          </SelectBox>
          <Space top bottom size={spacing.space1}>
            <Text display="small" level="span" html={language.childrenText} />
          </Space>
        </Space>
      )}
      {showDates && (
        <Space bottom size={spacing.space1}>
          <Space bottom size={spacing.space1}>
            <Text display="formLabel" level="span">
              Dates
            </Text>
          </Space>
          <Text display="formSelection" level="span">
            <DateWrapper
              name={'dates'}
              focusedInput={
                isOpen && focusedInput === 'dates' ? 'startDate' : null
              }
              startDatePlaceholderText={language.formSelection.labelStartDate}
              endDatePlaceholderText={language.formSelection.labelEndDate}
              block
              startDate={startDate}
              endDate={endDate}
              numberOfMonths={1}
              minimumNights={2}
              hideKeyboardShortcutsPanel
              displayFormat="YYYY-MM-DD"
              onClose={({ startDate, endDate }) => {
                props.onDateSet({
                  startDate,
                  endDate,
                });

                props.toggleDropDown(false, 'dates');
              }}
            />
          </Text>
        </Space>
      )}
    </React.Fragment>
  );
};

Form.defaultProps = {
  showDates: true,
  showGuests: true,
  showRegions: true,
  showLodges: true,
};
