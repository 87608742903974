import React from 'react';
import PropTypes from 'prop-types';
import { default as SlickSlider } from 'react-slick';
import styled from 'styled-components';
import { Pager } from './Pager';
import { Filters } from './Filters';

export class Slider extends React.Component {
  next = () => {
    this.slider.slickNext();
  };
  previous = () => {
    this.slider.slickPrev();
  };

  render() {
    const StyledSlider = styled.div`
      position: relative;
    `;

    const settings = {
      swipeToSlide: true,
      touchMove: false,
      autoplay: this.props.autoplay,
      autoplaySpeed: 5000,
      lazyLoad: this.props.lazyLoad,
      dots: this.props.dots,
      arrows: this.props.arrows,
      infinite: this.props.infinite,
      fade: this.props.fade,
      speed: this.props.speed,
      slidesToScroll: this.props.slidesToScroll,
      slidesToShow: this.props.slidesToShow,
      centerMode: this.props.centerMode,
      centerPadding: this.props.centerPadding,
    };

    return (
      <StyledSlider>
        {this.props.filter && <Filters items={this.props.filters} />}
        <SlickSlider ref={(c) => (this.slider = c)} {...settings}>
          {this.props.children}
        </SlickSlider>
        <Pager
          onPrev={this.previous}
          onNext={this.next}
          pagerPosition={this.props.pagerPosition}
          pagerType={this.props.pagerType}
          pagerStyle={this.props.pagerStyle}
        />
      </StyledSlider>
    );
  }
}

Slider.defaultProps = {
  dots: false,
  arrows: false,
  infinite: true,
  fade: false,
  speed: 2000,
  slidesToScroll: 1,
  slidesToShow: 1,
  centerMode: false,
  lazyLoad: false,
  filter: false,
  autoplay: true,
};

Slider.propTypes = {
  fade: PropTypes.bool,
  slidesToShow: PropTypes.number,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  centerMode: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToScroll: PropTypes.number,
  lazyLoad: PropTypes.bool,
  filter: PropTypes.bool,
  autoplay: PropTypes.bool,
};
