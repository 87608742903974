import gql from 'graphql-tag';

export const QUERY_CART = gql`
  query getCart {
    getCart {
      items {
        id
        startDate
        adults
        numberOfUnits
        unit {
          bookingEngineCode
          code
          baseRate
          lodge {
            name
          }
          name
          currency
        }
        children
        endDate
        rate
      }
    }
  }
`;
