export function groupPromotions(data) {
  if (!data) return;

  return data.reduce(
    (acc, prom) => {
      if (prom.lodges.items.length > 0) {
        for (const lodge of prom.lodges.items) {
          if (!(lodge.code in acc.lodges)) acc.lodges[lodge.code] = [prom];
          else acc.lodges[lodge.code].push(prom);
        }
      } else if (prom.regions.items.length > 0) {
        for (const region of prom.regions.items) {
          if (!(region.code in acc.regions)) acc.regions[region.code] = [prom];
          else acc.regions[region.code].push(prom);
        }
      }

      return acc;
    },
    { lodges: {}, regions: {} },
  );
}

export function shuffle(array) {
  let m = array.length,
    t,
    i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
}
