import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../common/styles/variables';
import Space from '../Base/Space';
import Text from '../Base/Text/Text';
import Image from '../Base/Image/Image';
import styled from 'styled-components';

export class CardProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  toggleItem = () => {
    this.setState({
      hover: !this.state.hover,
    });
  };

  render() {
    const { title, img, description, meta, content } = this.props;

    const StyledCard = styled.div`
      display: inline-block;
      width: 100%;
      margin-bottom: ${spacing.space6} 0;
    `;

    const StyledImage = styled.div`
      width: 60%;
      margin: auto;
    `;

    return (
      <StyledCard
        onMouseEnter={() => this.toggleItem()}
        onMouseLeave={() => this.toggleItem()}
      >
        {img && (
          <Space bottom size={spacing.space2}>
            <StyledImage>
              <Image fixed round src={img} />
            </StyledImage>
          </Space>
        )}

        {meta && (
          <Space bottom size={spacing.space1}>
            <Text align="center" display="meta" html={meta} />
          </Space>
        )}

        {title && (
          <Space bottom size={spacing.space1}>
            <Text align="center" display="title" html={title} />
          </Space>
        )}

        {description && (
          <Space bottom size={spacing.space1}>
            <Text align="center" display="meta" html={description} />
          </Space>
        )}

        {content && <Text align="center" display="body" html={content} />}
      </StyledCard>
    );
  }
}

CardProfile.defaultProps = {
  title: null,
  meta: null,
  description: null,
  img: null,
  content: null,
};

CardProfile.propTypes = {
  /** Title of Card */
  title: PropTypes.string.isRequired,
  /** Meta text of Card */
  meta: PropTypes.string,
  /** Description of Card */
  description: PropTypes.string,
  /** ImageURL of Card */
  img: PropTypes.string,
  /** Contgent of Card */
  content: PropTypes.string,
};

export default CardProfile;
