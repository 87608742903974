import styled, { css } from 'styled-components';
import { displayStyles } from './style';
import { timing } from '../../../common';

const defaultProps = {
  display: 'base',
};

const display = (props) => {
  let style = displayStyles[props.display];
  if (props.hover) {
    style = {
      ...style,
      ...displayStyles[props.display].hover,
    };
  }
  if (props.checked) {
    style = {
      ...style,
      ...displayStyles[props.display].checked,
    };
  }
  if (props.disabled) {
    style = {
      ...style,
      ...displayStyles[props.display].disabled,
    };
  }
  return css`
    color: ${style.color};
    border-color: ${style.border};
    background-color: ${style.background};
    opacity: ${style.opacity};
    cursor: ${style.cursor};
    text-decoration: none;
    :focus,
    :hover {
      background-color: ${style.bgColorHover};
      color-hover: ${style.colorHover};
      outline: 0;
    }
  `;
};

const StyledInputCheckBox = styled.span`
  ${display};
  margin: 0;
  line-height: 1;
  border-radius: 50%;
  transition: all ${timing.slow};
  display: inline-block;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  width: 32px;
  height: 32px;
`;

export default StyledInputCheckBox;

StyledInputCheckBox.defaultProps = defaultProps;
