import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonIcon } from '../components';
import { fonts } from '../common';

export default class InputNumber extends Component {
  render() {
    const { disabled, onDecrement, onIncrement, ...props } = this.props;
    const onSubtract = !disabled ? onDecrement : undefined;
    const onAdd = !disabled ? onIncrement : undefined;

    const StyledInputNumber = styled.div`
      display: flex;
      justify-content: space-between;
    `;

    const StyledInput = styled.input`
      cursor: pointer;
      max-width: 50px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;
      border: none;
      font-family: ${fonts.sans};
    `;

    return (
      <StyledInputNumber>
        <ButtonIcon size="small" icon={'minus'} onClick={onSubtract} />
        <StyledInput
          ref={(ref) => (this._inputRef = ref)}
          type="number"
          tabIndex="0"
          disabled={disabled}
          {...props}
        />
        <ButtonIcon size="small" icon={'plus'} onClick={onAdd} />
      </StyledInputNumber>
    );
  }
}

InputNumber.propTypes = {
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

InputNumber.defaultProps = {
  name: null,
  id: null,
  value: null,
  defaultValue: 2,
  disabled: false,
  min: 0,
  max: 8,
  step: 1,
};
