import React from 'react';
import PropTypes from 'prop-types';
import StyledButtonSelect from './Style/ButtonSelectStyle';
import StyledDescription from './Style/DescriptionStyle';
import { Space, ButtonText, InputCheckBox } from '../../../components';
import { spacing } from '../../../common';

class ButtonSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      selected: false,
    };
  }

  toggleItem = (state) => {
    this.setState({
      hover: state,
    });
  };

  toggleSelect = () => {
    this.setState({
      selected: !this.state.selected,
    });
  };

  render() {
    const { description, addDescription, removeDescription, as, ...props } =
      this.props;

    const hover = this.state.hover || this.props.hover;
    const selected = this.state.selected || this.props.selected;

    return (
      <StyledButtonSelect
        hover={hover}
        onMouseEnter={() => this.toggleItem(true)}
        onMouseLeave={() => this.toggleItem(false)}
        onClick={() => this.props.onClick()}
        as={as}
        {...props}
      >
        {description && (
          <StyledDescription hover={hover}>
            <div className="d-sm-block">
              <Space right size={spacing.space1} inline>
                <ButtonText hover={hover} size="small">
                  {selected ? removeDescription : addDescription}
                </ButtonText>
              </Space>
            </div>
          </StyledDescription>
        )}

        <InputCheckBox hover={hover} checked={selected} />
      </StyledButtonSelect>
    );
  }
}

ButtonSelect.propTypes = {
  /** Click action */
  onClick: PropTypes.func,
  /** Hover state */
  hover: PropTypes.bool,
  /** Selected state */
  selected: PropTypes.bool,
  /** Should the description be shown? */
  addDescription: PropTypes.bool,
  /** Description for adding option */
  description: PropTypes.string,
  /** Description for removing option */
  removeDescription: PropTypes.string,
  /** Change the HTML element that is rendered */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

ButtonSelect.defaultProps = {
  display: 'base',
  addDescription: 'Select this option',
  removeDescription: 'Remove option',
  hover: false,
  selected: false,
  description: true,
  as: 'div',
  onClick: () => {},
};

export default ButtonSelect;
