import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../common/styles/variables';
import { StyledCard, StyledFigure } from './Style/CardStyle';
import Space from '../Base/Space';
import Text from '../Base/Text/Text';

/** This Card component is to replace the use of CardNEW components */

//const createMarkup = (content) => ({ __html: `${content}` });

export class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  toggleItem = () => {
    this.setState({
      hover: !this.state.hover,
    });
  };

  render() {
    const { title, imageUrl, subtitle, excerpt, children, large, renderVideo } =
      this.props;

    return (
      <StyledCard
        onMouseEnter={() => this.toggleItem()}
        onMouseLeave={() => this.toggleItem()}
      >
        {imageUrl && (
          <Space bottom size={spacing.space2}>
            <StyledFigure style={{ backgroundImage: `url(${imageUrl})` }} />
          </Space>
        )}

        {renderVideo ? (
          <Space bottom size={spacing.space2}>
            {renderVideo()}
          </Space>
        ) : null}

        <header>
          {subtitle && (
            <Space bottom size={spacing.space1}>
              <Text display="meta">{subtitle}</Text>
            </Space>
          )}
          {title && (
            <Space bottom size={spacing.space1}>
              <Text
                display={large ? 'titleLarge' : 'title'}
                level="h3"
                hoverState={this.state.hover}
              >
                {title}
              </Text>
            </Space>
          )}
        </header>

        {excerpt && (
          <Space bottom size={spacing.space3}>
            <Text display="body" html={excerpt} />
          </Space>
        )}

        {children}
      </StyledCard>
    );
  }
}

Card.defaultProps = {
  title: null,
  subtitle: null,
  imageUrl: null,
  excerpt: null,
  large: false,
};

Card.propTypes = {
  /** Title of Card */
  title: PropTypes.string.isRequired,
  /** Meta text of Card */
  subtitle: PropTypes.string,
  /** ImageURL of Card */
  imageUrl: PropTypes.string,
  /** Paragraph content of Card */
  excerpt: PropTypes.string,
  /** Is the Card large? */
  large: PropTypes.bool,
};
