import * as React from 'react';
import { Query } from 'react-apollo';
import { QUERY_REGIONS } from './graphql/queries';
import { ResultsPage } from './ui/ResultsPage';
import * as moment from 'moment';
import queryString from 'query-string';
import { CartContainer } from '../Cart/CartContainer';
import { Switch, Route } from 'react-router-dom';
import { LodgeContainer } from '../Lodge/LodgeContainer';
import { withUrlSync } from '../../utils/urlSync';
import { formatUrlVariables } from '../../utils/dates';
import { TermsConditions } from './ui/TermsConditions';
import { Foot } from '../Navigation/ui/Footer';
import { RESULTS_PAGE_QUERY } from './graphql/contentful-queries';

class ResultsContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      location: { search },
    } = this.props;

    const values = queryString.parse(search, { arrayFormat: 'bracket' });

    this.state = {
      openModal: false,
      openContactModal: false,
      openFilterModal: false,
      form: {
        additionalLodges: [],
        regions: values.regions || [],
        lodges: values.lodges || [],
        startDate: values.startDate
          ? moment.utc(values.startDate)
          : moment.utc().add(10, 'days').startOf('day'),
        endDate: values.endDate
          ? moment.utc(values.endDate)
          : moment.utc().add(15, 'days').startOf('day'),
        guests: {
          adults: parseInt(values.adults, 10) || 2,
          children: parseInt(values.children, 10) || 0,
          toddler: parseInt(values.toddler, 10) || 0,
          baby: parseInt(values.baby, 10) || 0,
        },
      },
    };
  }

  formatQueryVariables = (form) => {
    const { guests, additionalLodges, ...restForm } = form;

    const query = {
      ...restForm,
      ...guests,
      hasToddler: guests.toddler > 0 || guests.baby > 0,
    };

    if (additionalLodges.length) {
      query.lodges = [...restForm.lodges, ...additionalLodges];
    }

    return query;
  };

  openBookSubmit = () => {
    this.setState({
      openBookModal: !this.state.openBookModal,
    });
  };

  openContactSubmit = () => {
    this.setState({
      openContactModal: !this.state.openContactModal,
    });
  };

  toggleFilterModal = () => {
    this.setState({
      openFilterModal: !this.state.openFilterModal,
    });
  };

  handleFormChange = (form) => {
    this.setState(
      {
        form,
      },
      () => this.props.onSearchStateChange(formatUrlVariables(form)),
    );
  };

  setAdditionalOptions = (key, updates) => {
    this.setState(
      (state) => ({
        form: {
          ...state.form,
          [key]: updates,
        },
      }),
      () => this.props.onSearchStateChange(formatUrlVariables(this.state.form)),
    );
  };

  render() {
    const {
      location: { search },
    } = this.props;
    console.log('state', this.state);
    return (
      <Query query={RESULTS_PAGE_QUERY} context={{ clientName: 'contentful' }}>
        {({ loading: loadingPromotions, data: contentfulData }) => {
          const promotions =
            contentfulData && contentfulData.promotions
              ? contentfulData.promotions.items
              : [];
          const lodges =
            contentfulData && contentfulData.lodges
              ? contentfulData.lodges.items
              : [];

          return (
            <Query
              query={QUERY_REGIONS}
              variables={this.formatQueryVariables(this.state.form)}
            >
              {({ loading, error, data }) => {
                if (error) return `Error! ${error.message}`;

                return (
                  <React.Fragment>
                    <Switch>
                      <Route
                        exact
                        path="/search"
                        render={(routeProps) => (
                          <CartContainer
                            render={(cart) => (
                              <ResultsPage
                                {...routeProps}
                                search={queryString.parse(search, {
                                  arrayFormat: 'bracket',
                                })}
                                onSearchStateChange={this.handleFormChange}
                                onBookSubmit={this.openBookSubmit}
                                onContactSubmit={this.openContactSubmit}
                                onFilterSubmit={this.toggleFilterModal}
                                loading={loading}
                                results={data}
                                cart={cart}
                                form={this.state.form}
                                openBookModal={this.state.openBookModal}
                                openContactModal={this.state.openContactModal}
                                openFilterModal={this.state.openFilterModal}
                                onAdditional={this.setAdditionalOptions}
                                promotions={promotions}
                                lodges={lodges}
                              />
                            )}
                          />
                        )}
                      />
                      <Route
                        path="/search/lodge/:lodgeId"
                        render={(routeProps) => (
                          <LodgeContainer {...routeProps} />
                        )}
                      />
                    </Switch>
                    <TermsConditions />
                    <Foot />
                  </React.Fragment>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

ResultsContainer = withUrlSync(ResultsContainer);

export { ResultsContainer };
