import React from 'react';
import styled, { css } from 'styled-components';
import { displayStyles, sizeStyles } from './style';
import { timing } from '../../../../common';

const defaultProps = {
  display: 'base',
  size: 'base',
};

const display = (props) => {
  let style = displayStyles[props.display];
  if (props.hover) {
    style = {
      ...style,
      ...displayStyles[props.display].hover,
    };
  }
  return css`
    color: ${style.color};
    border-color: ${style.borderColor};
    background-color: ${style.bgColor};
    text-decoration: none;
    :focus,
    :hover {
      background-color: ${style.bgColorHover};
      color: ${style.colorHover};
      outline: 0;
    }
  `;
};

const size = (props) => {
  const style = sizeStyles[props.size];
  return css`
    font-size: ${style.fontSize};
    width: ${style.size};
    height: ${style.size};
  `;
};

const Button = ({ as: B, ...props }) => <B {...props} />;

const StyledButtonIcon = styled(Button)`
  ${display};
  ${size};
  margin: 0;
  cursor: pointer;
  line-height: 1;
  border-radius: 50%;
  transition: all ${timing.slow};
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  ${(props) => props.css && css(...props.css)};
`;

export default StyledButtonIcon;

StyledButtonIcon.defaultProps = defaultProps;
