import * as React from 'react';
import { SelectCheckbox } from '../../components';
import { language } from '../../common';

export class OtherLodges extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked || false,
    };
  }

  componentDidMount() {
    if (this.props.checked) {
      const updateLodges = this.selectAdditionalLodges();
      this.props.onSelectUpdate(updateLodges);

      this.setState({
        checked: this.props.checked,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.form.regions !== prevProps.form.regions) {
      this.props.onSelectUpdate([]);
      this.setState({
        checked: false,
      });
    }

    if (this.props.form.lodges !== prevProps.form.lodges) {
      this.props.onSelectUpdate([]);
      this.setState({
        checked: false,
      });
    }
  }

  checkForAdditionalLodges() {
    const {
      results,
      form: { regions, lodges },
    } = this.props;

    let filteredRegions = [];

    if (regions.length === 0) {
      filteredRegions = [];
    } else {
      filteredRegions = results.findRegions.filter((r) =>
        regions.includes(r.code),
      );
    }

    if (lodges.length > 0) {
      return filteredRegions.some((region) => {
        const notFilteredLodges = region.lodges.filter(
          (l) => !lodges.includes(l.code),
        );

        return notFilteredLodges.length > 0;
      });
    }

    return false;
  }

  selectAdditionalLodges() {
    const {
      results,
      form: { regions, lodges },
    } = this.props;

    let filteredRegions = [];

    if (regions.length === 0) {
      filteredRegions = [];
    } else {
      filteredRegions = results.findRegions.filter((r) =>
        regions.includes(r.code),
      );
    }

    if (lodges.length > 0) {
      let updateLodges = [];
      filteredRegions.forEach((region) => {
        const notFilteredLodges = region.lodges.filter(
          (l) => !lodges.includes(l.code),
        );

        updateLodges = [
          ...updateLodges,
          ...notFilteredLodges.map((l) => l.code),
        ];
      });

      return updateLodges;
    }

    return [];
  }

  onSelect = () => {
    const { checked } = this.state;

    if (!checked) {
      const updateLodges = this.selectAdditionalLodges();

      this.setState(
        (state) => ({
          checked: !state.checked,
        }),
        this.props.onSelectUpdate(updateLodges),
      );
    } else {
      this.setState(
        (state) => ({
          checked: !state.checked,
        }),
        this.props.onSelectUpdate([]),
      );
    }
  };

  render() {
    return this.checkForAdditionalLodges() ? (
      <SelectCheckbox
        disabled={this.props.loading}
        value={'otherLodges'}
        checked={this.state.checked}
        label={language.formSelection.filterRegion}
        onClick={this.onSelect}
      />
    ) : null;
  }
}
