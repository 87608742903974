import {
  fontWeight,
  fonts,
  sizes,
  tracking,
  colors,
  spacing,
} from '../../../../common';

export const displayStyles = {
  body: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textMedium}*1.4)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textBase,
  },
  bodyWhite: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textMedium}*1.4)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textWhite,
  },
  label: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textMedium}*1.2)`,
    fontWeight: fontWeight.medium,
    fontColor: colors.textDark,
  },
  labelWhite: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textMedium}*1.2)`,
    fontWeight: fontWeight.medium,
    fontColor: colors.textWhite,
  },
  labelBrand: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textMedium}*1.2)`,
    fontWeight: fontWeight.bold,
    fontColor: colors.textBrand,
  },
  formLabel: {
    fontFamily: fonts.sans,
    fontSize: sizes.textSmall,
    lineHeight: `calc(${sizes.textSmall}*1.2)`,
    fontWeight: fontWeight.bold,
    fontColor: colors.textDark,
    marginBottom: spacing.space1,
  },
  formLabelNoInput: {
    fontFamily: fonts.sans,
    fontSize: sizes.textSmall,
    lineHeight: `calc(${sizes.textSmall}*1.2)`,
    fontWeight: fontWeight.bold,
    fontColor: colors.textDark,
  },
  formSelection: {
    fontFamily: fonts.serif,
    fontSize: sizes.textLarge,
    lineHeight: `calc(${sizes.textLarge}*1.2)`,
    fontColor: colors.textDark,
  },
  legal: {
    fontFamily: fonts.serif,
    fontSize: sizes.textSmall,
    lineHeight: `calc(${sizes.textSmall}*1.2)`,
    fontWeight: fontWeight.normal,
    fontColor: colors.textBase,
  },
  small: {
    fontFamily: fonts.sans,
    fontSize: sizes.textSmall,
    lineHeight: `calc(${sizes.textSmall}*1.2)`,
    fontWeight: fontWeight.normal,
    fontColor: colors.textDark,
  },
  smallWhite: {
    fontFamily: fonts.sans,
    fontSize: sizes.textSmall,
    lineHeight: `calc(${sizes.textSmall}*1.2)`,
    fontWeight: fontWeight.normal,
    fontColor: colors.textWhite,
  },
  smallBrand: {
    fontFamily: fonts.sans,
    fontSize: sizes.textSmall,
    lineHeight: `calc(${sizes.textSmall}*1.2)`,
    fontWeight: fontWeight.normal,
    fontColor: colors.textBrand,
  },
  title: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingSmall,
    lineHeight: `calc(${sizes.headingSmall}*1.1)`,
    fontWeight: fontWeight.regular,
  },
  titleWhite: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingSmall,
    lineHeight: `calc(${sizes.headingSmall}*1.1)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textWhite,
  },
  titleLarge: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingLarge,
    lineHeight: `calc(${sizes.headingLarge}*1.2)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.headingBlack,
  },
  intro: {
    fontFamily: fonts.serif,
    fontSize: sizes.textLarge,
    lineHeight: `calc(${sizes.textLarge}*1.4)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textDark,
  },
  introWhite: {
    fontFamily: fonts.serif,
    fontSize: sizes.textLarge,
    lineHeight: `calc(${sizes.textLarge}*1.4)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textWhite,
  },
  secondary: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textLarge}*1.2)`,
    fontWeight: fontWeight.bold,
    fontColor: colors.headingDark,
  },
  meta: {
    fontFamily: fonts.sans,
    fontSize: sizes.textTiny,
    lineHeight: `calc(${sizes.textTiny}*1.2)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.headingBrand,
    letterSpacing: tracking.medium,
    textTransform: 'uppercase',
  },
  metaWhite: {
    fontFamily: fonts.sans,
    fontSize: sizes.textTiny,
    lineHeight: `calc(${sizes.textTiny}*1.2)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textWhite,
    letterSpacing: tracking.medium,
    textTransform: 'uppercase',
  },
  headline: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingLarge,
    lineHeight: `calc(${sizes.headingLarge}*1.2)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.headingBlack,
  },
  headlineWhite: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingLarge,
    lineHeight: `calc(${sizes.headingLarge}*1.2)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textWhite,
  },
  hero: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingHuge,
    lineHeight: `calc(${sizes.headingHuge}*1)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.headingDark,
  },
  heroMobile: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingLarge,
    lineHeight: `calc(${sizes.headingLarge}*1)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.headingDark,
  },
  heroWhite: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingHuge,
    lineHeight: `calc(${sizes.headingHuge}*1)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textWhite,
  },
  heroWhiteMobile: {
    fontFamily: fonts.serif,
    fontSize: sizes.headingLarge,
    lineHeight: `calc(${sizes.headingLarge}*1)`,
    fontWeight: fontWeight.regular,
    fontColor: colors.textWhite,
  },
  tab: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textMedium}*1.2)`,
    fontWeight: fontWeight.bold,
    fontColor: colors.textDark,
  },
  tabActive: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textMedium}*1.2)`,
    fontWeight: fontWeight.bold,
    fontColor: colors.textBrand,
  },
  navItem: {
    fontFamily: fonts.sans,
    fontSize: sizes.textMedium,
    lineHeight: `calc(${sizes.textLarge}*1.2)`,
    fontWeight: fontWeight.bold,
    fontColor: colors.headingDark,
  },
};
