import React, { Component } from 'react';
import {
  Button,
  ButtonText,
  ContentModal,
  Text,
  Box,
  Space,
} from '../../../components';
import { spacing, language } from '../../../common';

export class TermsConditions extends Component {
  constructor(props) {
    super(props);
    let storedShowModal = true;

    const { name } = this.props;

    if (sessionStorage.getItem(name) === 'false') {
      storedShowModal = JSON.parse(sessionStorage.getItem(name));
    }

    this.state = {
      showTnCSingit: storedShowModal,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    const { name } = this.props;
    sessionStorage.setItem(name, JSON.stringify(true));
    this.setState({ showTnCSingit: true });
  }

  handleCloseModal() {
    const { name } = this.props;
    sessionStorage.setItem(name, JSON.stringify(false));
    this.setState({ showTnCSingit: false });
  }

  render() {
    const { languageKey } = this.props;
    return (
      <ContentModal
        size="small"
        isOpen={this.state.showTnCSingit}
        render={() => (
          <React.Fragment>
            <Box display="border">
              <Space top bottom left right size={spacing.space1}>
                <div className="text-right">
                  <ButtonText iconAfter="close" onClick={this.handleCloseModal}>
                    Close
                  </ButtonText>
                </div>
                <div className="text-center">
                  <Space left right size={spacing.space1}>
                    <Space bottom size={spacing.space2}>
                      <Text align="center" display="title">
                        Please note:
                      </Text>
                    </Space>
                    <Space bottom size={spacing.space2}>
                      <Text
                        display="intro"
                        align="center"
                        html={language[languageKey]}
                      />
                    </Space>
                    <Space top bottom size={spacing.space3}>
                      <Button onClick={this.handleCloseModal}>Confirm</Button>
                    </Space>
                  </Space>
                </div>
              </Space>
            </Box>
          </React.Fragment>
        )}
      />
    );
  }
}

TermsConditions.defaultProps = {
  name: 'showTnCSingita',
  languageKey: 'bookingInfo',
};
