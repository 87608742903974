import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Space,
  Box,
  ButtonText,
  Button,
  Text,
  ResultItinerary,
  Border,
  ButtonIcon,
} from '../../../components';
import { spacing, language } from '../../../common';
import posed, { PoseGroup } from 'react-pose';
import { RemoveFromCartContainer } from '../CartContainer';

const Item = posed.div({
  flip: { transistion: 'tween' },
  enter: {
    opacity: 1,
    height: 'auto',
  },
  exit: {
    opacity: 0,
    height: 0,
  },
});
function getUnits(lodgeCollection) {
  let result = {};
  lodgeCollection.forEach((lodge) => {
    lodge.units.items.forEach((unit) => {
      result[unit.unitCode] = unit.roomPrefix;
    });
  });
  return result;
}
export const ItenaryResults = ({
  cart: { items },
  lodges = [],
  onBookSubmit,
  onContactSubmit,
  showSubmit = true,
  showContact = true,
  renderTitle = this.props,
  renderDescription = this.props,
}) => {
  const unitCodes = getUnits(lodges);

  return (
    <Box
      display="backgroundBaseWhite"
      renderTitle={renderTitle}
      renderDescription={renderDescription}
    >
      <Space bottom size={spacing.space4}>
        {items.length > 0 ? null : (
          <Text display="small">{language.itinerary.noteEmpty}</Text>
        )}
        <PoseGroup flipMove={false}>
          {items.map((item) => (
            <Item key={item.id}>
              <ResultItinerary
                key={item.id}
                lodge={item.unit.lodge.name}
                unit={item.unit.name}
                rate={`${item.unit.baseRate} ${
                  unitCodes[item.unit.bookingEngineCode]
                }`}
                dates={`${item.startDate} ⟶ ${item.endDate}`}
                renderRemove={() => (
                  <RemoveFromCartContainer
                    selected={item}
                    render={(removeFromCart) => (
                      <ButtonIcon
                        display="baseBrand"
                        size="small"
                        onClick={() =>
                          removeFromCart({ variables: { id: item.id } })
                        }
                      />
                    )}
                  />
                )}
              />
              <Border />
            </Item>
          ))}
        </PoseGroup>
      </Space>
      {items.length > 0 && showSubmit ? (
        <React.Fragment>
          <Button
            style={{ textTransform: 'none' }}
            block
            onClick={() => onBookSubmit()}
          >
            {language.itinerary.btnSubmit}
          </Button>
        </React.Fragment>
      ) : null}
      <Space top size={spacing.space3}>
        {showContact && (
          <ButtonText
            iconAfter="chevron-right"
            onClick={() => onContactSubmit()}
          >
            {language.formEnquiry.btnContact}
          </ButtonText>
        )}
      </Space>
    </Box>
  );
};

ItenaryResults.propTypes = {
  renderTitle: PropTypes.string,
  renderDescription: PropTypes.string,
};

ItenaryResults.defaultProps = {
  renderTitle: null,
  renderDescription: null,
};
