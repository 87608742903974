import gql from 'graphql-tag';

export const QUERY_FORM = gql`
  fragment FormParts on Fields {
    label
    placeholder
    description
    name
    type
    fieldId
    className
    required
    validate
    options {
      label
      value
    }
    conditional
    conditionalLogic {
      actionType
      logic {
        field
        match
        value
      }
    }
  }
  query getForm($formId: String!) {
    viewForm(id: $formId) {
      title
      id
      description
      buttonText
      buttonClass
      fields {
        ...FormParts
      }
    }
  }
`;
