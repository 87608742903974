import React from 'react';
import styled, { css } from 'styled-components';
import { displayStyles, sizeStyles } from './style';
import { fonts, timing, fontWeight } from '../../../../common';

const defaultProps = {
  children: null,
  display: 'select',
  size: 'base',
  type: 'button',
};

const display = (props) => {
  let style = displayStyles[props.display];
  if (props.hover) {
    style = {
      ...style,
      ...displayStyles[props.display].hover,
    };
  }
  return css`
    border-color: ${style.borderColor};
    background-color: ${style.bgColor};
    color: ${style.textColor};
    :focus,
    :hover {
      background-color: ${style.bgColorHover};
      border-color: ${style.borderColorHover};
      color: ${style.textColorHover};
      outline: 0;
    }
  `;
};

const size = (props) => {
  const style = sizeStyles[props.size];
  return css`
    padding: ${style.padding};
    font-size: ${style.fontSize};
  `;
};

const Button = ({ as: B, ...props }) => <B {...props} />;

const StyledButtonDropdown = styled(Button)`
  ${display};
  ${size};
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  margin-bottom: 0;
  cursor: pointer;
  text-align: left;
  text-transform: normal;
  font-family: ${fonts.serif};
  font-weight: ${fontWeight.medium};
  transition: all ${timing.slow};
  width: ${(props) => (props.block ? '100%' : 'inherit')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default StyledButtonDropdown;

StyledButtonDropdown.defaultProps = defaultProps;
