import * as React from 'react';
import { Query, Mutation } from 'react-apollo';
import { QUERY_CART } from './graphql/queries';
import { Loader } from '../../components';

import {
  MUTATION_ADD_TO_CART,
  MUTATION_REMOVE_FROM_CART,
} from './graphql/mutations';

export class CartContainer extends React.PureComponent {
  render() {
    return (
      <Query query={QUERY_CART}>
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`;

          if (loading) return <Loader />;

          const { getCart } = data;

          return this.props.render(getCart);
        }}
      </Query>
    );
  }
}

export class AddToCartContainer extends React.PureComponent {
  render() {
    const {
      lodge,
      unit: u,
      startDate,
      endDate,
      rate,
      guests,
      contentfulUnit,
    } = this.props;
    //console.log(contentfulUnit);
    return (
      <Mutation
        variables={{
          input: {
            unit: u.code,
            lodgeName: lodge.name,
            regionName: lodge.region.name,
            unitName: u.name,
            currency: u.currency,
            //unitType: u.unitType,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            numberOfUnits: u.available.neededUnits,
            ...guests,
            rate,
            formattedRate: `${u.baseRate} ${contentfulUnit.roomPrefix}`,
          },
        }}
        mutation={MUTATION_ADD_TO_CART}
        update={(cache, { data: { addToCart } }) => {
          cache.writeQuery({
            query: QUERY_CART,
            data: { getCart: addToCart },
          });
        }}
      >
        {(addToCart) => this.props.render(addToCart)}
      </Mutation>
    );
  }
}

export class RemoveFromCartContainer extends React.PureComponent {
  render() {
    const {
      selected: { id = null },
    } = this.props;
    return (
      <Mutation
        mutation={MUTATION_REMOVE_FROM_CART}
        variables={{
          id,
        }}
        update={(cache, { data: { removeFromCart } }) => {
          cache.writeQuery({
            query: QUERY_CART,
            data: { getCart: removeFromCart },
          });
        }}
      >
        {(addToCart) => this.props.render(addToCart)}
      </Mutation>
    );
  }
}
