import saveFile from 'save-as-file';
import React, { useRef, useEffect, useCallback } from 'react';

import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

import './style.css';
import {
  Loader,
  ButtonIcon,
  ButtonText,
  Space,
  Text,
} from '../../../components';
import { AvailabilityContainer } from '../../Availability/AvailabilityContainer';
import { formatAvailabilityCSV } from '../../../common/utils/options';
import Views from './Views';
import { spacing } from '../../../common';
import { useLockBodyScroll } from '../hooks/useLocalStorage';
import { useState } from 'react';

const moment = extendMoment(Moment);

const Calendar = ({
  loading,
  rooms,
  startDate,
  setStartDate,
  offset,
  ...props
}) => {
  const [downloadLoading, setDownloading] = useState(false);
  const el = useRef(null);
  useLockBodyScroll();
  useEffect(() => {
    if (!!el.current.scrollTo) {
      el.current.scrollTo({ left: 0, behavior: 'smooth' });
    } else {
      el.current.scrollLeft = 0;
    }
  }, [startDate]);

  useEffect(() => {
    el.current.focus();
  }, []);

  const handleMouseScroll = useCallback((event) => {
    var maxX = el.current.scrollWidth - el.current.offsetWidth;

    if (
      el.current.scrollLeft + event.deltaX < 0 ||
      el.current.scrollLeft + event.deltaX > maxX
    ) {
      event.preventDefault();

      el.current.scrollLeft = Math.max(
        0,
        Math.min(maxX, el.current.scrollLeft + event.deltaX),
      );
    }
  }, []);

  useEffect(() => {
    const el = document.getElementById('calendar-container');
    el.addEventListener('mousewheel', handleMouseScroll);

    return () => {
      el.removeEventListener('mousewheel', handleMouseScroll);
    };
  }, [handleMouseScroll]);

  const sideScroll = (direction, step) => {
    if (direction === 'left') {
      el.current.scrollLeft -= step;
    } else {
      el.current.scrollLeft += step;
    }
  };

  const regions = rooms.findRegions;
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];

  const beginDate = props.form.startDate;
  const endDate = props.form.endDate;

  const range = moment.range(beginDate.toString(), endDate.toString());
  const arrayOfDates = Array.from(range.by('days')).map((r) =>
    r.format('YYYY-MM-DD'),
  );

  const getDateInfo = (date) => {
    const _date = new Date(moment(date).tz('Africa/Johannesburg').toString());

    const dayOfWeek = days[_date.getDay()];
    const dayOfMonth = _date.getDate();

    const isToday =
      _date.toLocaleDateString() === new Date(Date.now()).toLocaleDateString();

    return {
      dayOfWeek,
      dayOfMonth,
      isToday,
      label: moment(_date).format('DD/MM'),
      isWeekend: ['Sun', 'Sat'].includes(dayOfWeek) ? 'weekend' : '',
    };
  };

  const downloadPDF = () => {
    setDownloading(true);
    fetch(process.env.REACT_APP_PDF_SERVER, {
      body: JSON.stringify(
        formatAvailabilityCSV(regions, arrayOfDates, 'DD/MM'),
      ),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        setDownloading(false);
        saveFile(res, 'singita-calendar.pdf');
      })
      .catch((err) => {
        setDownloading(false);
        alert(err);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2 pt-3 left-wrapper">
          <div className="left-p ">
            <AvailabilityContainer
              onSearchStateChange={props.handleFormChange}
              search={props.form}
              showDates={true}
              showGuests={false}
            />

            <Space top size={spacing.space1}>
              <ButtonText disabled={downloadLoading} onClick={downloadPDF}>
                {downloadLoading ? 'Loading' : 'Download PDF'}
              </ButtonText>
            </Space>

            <Views {...props} />
          </div>
        </div>

        <div className="col-md-10">
          <div
            className="calendar-container"
            id="calendar-container"
            tabIndex="-1"
            ref={el}
          >
            {loading ? (
              <div className="loader">
                <Loader />
              </div>
            ) : null}

            <div className="calendar-header dates sticky">
              {arrayOfDates.map((date) => {
                const { dayOfWeek, label, isToday, isWeekend } = getDateInfo(
                  date,
                );

                return (
                  <div
                    key={`header-cell-${date}`}
                    className={`header-cell ${
                      isWeekend && !isToday ? 'weekend' : ''
                    } ${isToday ? 'today' : ''}`}
                  >
                    <span className="header-cell-day">{dayOfWeek}</span>
                    <span className="header-cell-label">{label}</span>
                  </div>
                );
              })}
            </div>

            <div className="grid">
              <div className="left-panel">
                <div className="d-flex scrollArrows">
                  <div>
                    <div className="block block-success ml-1">
                      <Text display="small">Available</Text>
                    </div>
                    <div className="block block-danger ml-1">
                      <Text display="small">Unavailable</Text>
                    </div>
                  </div>
                  <div className="d-flex">
                    <ButtonIcon
                      display="baseBrand"
                      icon="chevron-left"
                      onClick={() => {
                        sideScroll('left', 300);
                      }}
                      css={`
                        border-radius: 0;
                        right: 51px;
                        top: 0;
                        bottom: 0;
                        height: 50px;
                        margin-right: 1px;
                        width: 50px;
                        color: #fff;
                        font-size: 16px;
                      `}
                    ></ButtonIcon>

                    <ButtonIcon
                      display="baseBrand"
                      onClick={() => {
                        sideScroll('right', 300);
                      }}
                      icon="chevron-right"
                      css={`
                        border-radius: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        height: 50px;
                        width: 50px;
                        font-size: 16px;
                      `}
                    ></ButtonIcon>
                  </div>
                </div>
                {regions.map((region) => {
                  return (
                    <>
                      {region.lodges.map(({ name, units }) => (
                        <div key={name} className="room-group">
                          <div className="room-header">
                            <div className="d-flex justify-content-between">
                              <div>{name}</div>
                              <small>{region.name}</small>
                            </div>
                          </div>
                          {units
                            .filter((unit) => unit.numberOfUnits > 0)
                            .map(({ name }) => (
                              <div key={name} className="room-item">
                                <div>{name}</div>
                              </div>
                            ))}
                        </div>
                      ))}
                    </>
                  );
                })}
              </div>
              <div className="right-panel" id="right-panel">
                {regions.map((region) => {
                  return (
                    <>
                      {region.lodges.map(
                        ({ _, units, getMonthAvailbility }) => (
                          <>
                            <div className="calendar-header">
                              {arrayOfDates.map((date) => {
                                const { isWeekend, isToday } = getDateInfo(
                                  date,
                                );

                                return (
                                  <div
                                    className={`header-cell header-lodge ${
                                      isWeekend && !isToday ? 'weekend' : ''
                                    } ${isToday ? 'today' : ''}`}
                                  ></div>
                                );
                              })}
                            </div>
                            {units
                              .filter((unit) => unit.numberOfUnits > 0)
                              .map((unit) => (
                                <div className="calendar-row">
                                  {arrayOfDates.map((date) => {
                                    const { isWeekend, isToday } = getDateInfo(
                                      date,
                                    );

                                    const lodgeAvailabilityOnDay = getMonthAvailbility.find(
                                      ({ date: availabilityDate }) =>
                                        availabilityDate === date,
                                    );

                                    const unitAvailabilityOnDay = lodgeAvailabilityOnDay
                                      ? lodgeAvailabilityOnDay.units.find(
                                          (u) =>
                                            u.bookingEngineCode ===
                                            unit.bookingEngineCode,
                                        )
                                      : 0;

                                    return (
                                      <div
                                        className={`entry-cell ${
                                          isWeekend && !isToday ? 'weekend' : ''
                                        } ${isToday ? 'today' : ''} ${
                                          unitAvailabilityOnDay &&
                                          unitAvailabilityOnDay.isThereSpace > 0
                                            ? 'cell-success'
                                            : 'cell-empty'
                                        }`}
                                      >
                                        {unitAvailabilityOnDay &&
                                        unitAvailabilityOnDay.isThereSpace > 0
                                          ? `${unitAvailabilityOnDay.isThereSpace}`
                                          : ''}
                                        <span />
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}
                          </>
                        ),
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
