import React from 'react';
import { Formik, Form, Field } from 'formik';
import { graphql } from 'react-apollo';
import parsePhoneNumber from 'libphonenumber-js';
import {
  Button,
  BSInputComponent,
  BSCheckComponent,
  BSTextareaComponent,
  BSSelectComponent,
  BSPhoneComponent,
  Space,
} from '../../../components';
import { spacing } from '../../../common';
import { MUTATION_SUBMIT_FORM } from '../graphql/mutations';

class EnquiryForm extends React.Component {
  getFieldByType(field, errors) {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'tel':
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            component={BSInputComponent}
            errors={errors}
          />
        );
      case 'phone':
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            component={BSPhoneComponent}
            errors={errors}
          />
        );
      case 'radio':
      case 'checkbox':
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            component={BSCheckComponent}
            errors={errors}
          />
        );
      case 'textarea':
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            component={BSTextareaComponent}
            errors={errors}
          />
        );
      case 'select':
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            options={field.options}
            component={BSSelectComponent}
            errors={errors}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { form } = this.props;

    return (
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          const { form, search } = this.props;
          setSubmitting(true);
          values.email = values.email
            ? values.email.trim().toLowerCase()
            : null;
          this.props
            .submitForm({
              variables: {
                input: {
                  fields: {
                    ...values,
                  },
                  form: form.id,
                  search,
                },
              },
            })
            .then(({ data }) => {
              setSubmitting(false);
              window.dataLayer = window.dataLayer || [];
              if (data.submitTransactionForm) {
                window.dataLayer.push({
                  event: 'formSubmission',
                  category: 'Form',
                  action: 'Success',
                  label: `availability_${form.id}`,
                  form: data.submitTransactionForm,
                });
              }
              this.props.onSubmitComplete();
            });
        }}
        validate={(values) => {
          let errors = {};
          form.fields.forEach((field) => {
            if (field.required && !values[field.name]) {
              errors[field.name] = 'Required Field';
            } else if (
              field.type === 'email' &&
              values[field.name] &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                values[field.name],
              )
            ) {
              errors.email = 'Invalid email address';
            } else if (field.type === 'phone' && values[field.name]) {
              const phoneNumber = parsePhoneNumber(values[field.name]);

              if (!phoneNumber) {
                errors[field.name] = 'Invalid phone number';
              } else if (phoneNumber && !phoneNumber.isValid()) {
                errors[field.name] = 'Invalid phone number';
              }
            }
          });

          return errors;
        }}
        render={({ errors, status, touched, isSubmitting }) => (
          <Form>
            {status && status.msg && <div>{status.msg}</div>}
            <div className="row">
              {form.fields.map((field) => (
                <div
                  className={field.className}
                  key={`${form.id}-${field.fieldId}`}
                >
                  {this.getFieldByType(field, errors)}
                </div>
              ))}
            </div>

            {this.props.renderAfterFields
              ? this.props.renderAfterFields()
              : null}

            <Space top size={spacing.space3}>
              <div className="d-flex align-items-center justify-content-between">
                {
                  <Space
                    right
                    size={this.props.renderButton ? spacing.space2 : 0}
                    inline
                  >
                    <Button type="submit" disabled={isSubmitting}>
                      {!isSubmitting ? form.buttonText : 'Sumbitting'}
                    </Button>
                  </Space>
                }

                {this.props.renderButton ? this.props.renderButton() : null}
              </div>
            </Space>
          </Form>
        )}
      />
    );
  }
}

export default graphql(MUTATION_SUBMIT_FORM, { name: 'submitForm' })(
  EnquiryForm,
);

EnquiryForm.defaultProps = {
  onSubmitComplete: () => {},
};
