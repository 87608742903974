import * as React from 'react';
import { ButtonText } from '../../../components';
import { spacing, colors } from '../../../common';
import Sticky from 'react-stickynode';
import styled from 'styled-components';

const StyledHeaderModal = styled.div`
  display: block;
  padding: ${spacing.space3} 0;
  border-bottom: 1px solid ${colors.borderLight};
  background-color: ${colors.baseWhite};
`;

export const HeaderModal = (props) => (
  <Sticky enabled={true} top={0} innerZ={999}>
    <StyledHeaderModal>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">{props.title}</div>
          <ButtonText onClick={props.closeModal} iconBefore="long-arrow-left">
            Back to Search Results
          </ButtonText>
        </div>
      </div>
    </StyledHeaderModal>
  </Sticky>
);
