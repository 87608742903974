import * as React from 'react';
import { Query } from 'react-apollo';
import { QUERY_UNIT_RATE } from './graphql/queries';
import { formatCurrency } from '../../utils/dates';

export class RateContainer extends React.PureComponent {
  getRate = (rates) => {
    const { unit } = this.props;

    const total = rates.reduce((result, item) => {
      if (unit.hasGreenSeason && !item.isHighSeason) {
        result += item.green;
      } else {
        result += item.high;
      }
      return result;
    }, 0);

    return {
      totalFormatted: formatCurrency(total, unit.currency),
      total: total,
    };
  };

  render() {
    return (
      <Query
        variables={{
          input: {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            adults: this.props.adults,
            children: this.props.children,
            toddler: this.props.toddler,
            baby: this.props.baby,
            unitCode: this.props.unit.code,
          },
        }}
        query={QUERY_UNIT_RATE}
      >
        {({ loading, error, data = {} }) => {
          if (error) return `Error! ${error.message}`;
          if (loading) return null;
          const { getUnitRate = [] } = data;
          return this.props.render(loading, this.getRate(getUnitRate));
        }}
      </Query>
    );
  }
}

RateContainer.defaultProps = {
  format: true,
};
