import * as React from 'react';
import { Space, Text, ButtonText } from '../../../components';
import { spacing } from '../../../common';
import { Announcement, Header, theme } from '@singita/components';
import { TermsConditions } from '../../Results/ui/TermsConditions';
import { Foot } from '../../Navigation/ui/Footer';

export const ConfirmationPage = (props) => {
  const {
    page: { content },
  } = props;

  return (
    <React.Fragment>
      <Header
        logoColor={theme.colors.brandBrown}
        renderButton={() => (
          <ButtonText
            as={'a'}
            href="https://singita.com"
            iconBefore="login"
            size="small"
          >
            Back to Singita.com
          </ButtonText>
        )}
      />
      <Space bottom top size={spacing.space6}>
        <div className="container">
          <Announcement
            headline={'Confirmation of Enquiry'}
            children={
              <Text display="label">
                You are officially one step closer to your safari. Thanks for
                sending us your details, keep an eye on your inbox for a message
                from one of our Singita Travel Advisors.
              </Text>
            }
          />
        </div>
      </Space>
      <TermsConditions />
      <Foot />
    </React.Fragment>
  );
};
