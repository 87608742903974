export const options = {
  guests: [
    {
      value: 'adults',
      label: 'Guests',
      min: 1,
    },
    /*{
      value: "children",
      label: "Children",
      sub: "Ages 10–16",
      min: 0
    },
    {
      value: "toddler",
      label: "Children",
      sub: "Ages 2–9",
      min: 0
    }*/
  ],
};

export const getGuestsText = (guests) => {
  const guestsCount = Object.keys(guests).reduce((result, item) => {
    result += guests[item];
    return result;
  }, 0);

  return `${guestsCount} ${guestsCount > 1 ? 'Guests' : 'Guest'}`;
};
