import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../../common';

export class PagerArrow extends Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  onMouseOver = () => {
    this.setState({ hovered: true });
  };

  onMouseOut = () => {
    this.setState({ hovered: false });
  };

  render() {
    const { hovered } = this.state;
    const { title } = this.props;

    const StyledIcon = styled.div`
      width: 48px;
      height: 64px;
      :hover {
        cursor: pointer;
      }
    `;

    let fillColor;
    if (hovered === true) {
      fillColor = colors.baseBlack;
    } else {
      fillColor = 'rgba(0,0,0,0.35)';
    }

    return (
      <StyledIcon>
        <svg
          title={title}
          width={'48px'}
          height={'64px'}
          viewBox="0 0 48 64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h48v64H0z" fill={fillColor} />
            <path fill={fillColor} d="M0 0h48v64H0z" />
            <path
              d="M31 32.5c0-.128-.05-.256-.146-.354l-12-12c-.196-.195-.512-.195-.708 0-.195.196-.195.512 0 .708L29.793 32.5 18.146 44.146c-.195.196-.195.512 0 .708.196.195.512.195.708 0l12-12c.097-.098.146-.226.146-.354z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      </StyledIcon>
    );
  }
}

PagerArrow.defaultProps = {
  title: 'Arrow',
  fillColor: colors.baseBlack,
};
