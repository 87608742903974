import React from 'react';
import PropTypes from 'prop-types';

export class CollapseWrapper extends React.Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
  };

  static defaultProps = {
    allowMultipleOpen: false,
  };

  constructor(props) {
    super(props);

    const openSections = {};

    this.state = { openSections };
  }

  handleCollapse = (id) => {
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this;

    const isOpen = openSections[id];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [id]: !isOpen,
        },
      });
    } else {
      this.setState({
        openSections: {
          [id]: !isOpen,
        },
      });
    }
  };

  render() {
    const {
      props: { renderCollapse },
      state: { openSections },
    } = this;

    return (
      <div>
        {renderCollapse &&
          renderCollapse({
            handleCollapse: this.handleCollapse,
            openSections,
          })}
      </div>
    );
  }
}
