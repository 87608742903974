import React from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../common';
import { Space, Text } from '../../components';

class ResultItinerary extends React.Component {
  render() {
    const { lodge, unit, rate, dates, renderRemove } = this.props;

    return (
      <Space bottom size={spacing.space3}>
        <div className="d-flex justify-content-between">
          {lodge && <Text display="intro">{lodge}</Text>}
          {renderRemove ? renderRemove() : null}
        </div>

        {unit && (
          <Text display="label" inline level="span">
            {unit}
          </Text>
        )}

        {rate && (
          <Space size={spacing.space2}>
            <Text display="small" inline level="space">
              {rate}
            </Text>
          </Space>
        )}

        {dates && (
          <Space top size={spacing.space1}>
            <Text display="small" level="small">
              {dates}
            </Text>
          </Space>
        )}
      </Space>
    );
  }
}

ResultItinerary.propTypes = {
  /** Lodge name */
  lodge: PropTypes.string,
  /** Unit name */
  unit: PropTypes.string,
  /** Unit rate */
  rate: PropTypes.string,
  /** Dates */
  dates: PropTypes.string,
};

ResultItinerary.defaultProps = {
  lodge: null,
  unit: null,
  rate: null,
  dates: null,
};

export default ResultItinerary;
