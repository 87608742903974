import gql from 'graphql-tag';

export const QUERY_UNIT_RATE = gql`
  query getUnitRate($input: InputUnitRate) {
    getUnitRate(input: $input) {
      date
      high
      green
      isHighSeason
      base
      children
      adults
    }
  }
`;
