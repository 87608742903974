import React from 'react';
import PropTypes from 'prop-types';

export class Collapse extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    handleCollapse: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.handleCollapse(this.props.id);
  };

  render() {
    const {
      props: { isOpen, renderHeader, renderBody },
    } = this;

    return (
      <div>
        <div onClick={() => this.onClick()} style={{ cursor: 'pointer' }}>
          {renderHeader &&
            renderHeader({
              isOpen,
            })}
        </div>
        {isOpen && renderBody}
      </div>
    );
  }
}
