import * as React from 'react';
import styled from 'styled-components';
import {
  tracking,
  fontWeight,
  sizes,
  fonts,
  colors,
  spacing,
  timing,
} from '../../../common';

export function TertiaryMenu(props) {
  if (!props.show) return null;

  return (
    <StyledHeaderTertiary>
      <StyledTertiaryMenu>
        {props.items && props.items.title && (
          <li className="tertiaryMenuTitle" key={'title'}>
            {props.items.title}
          </li>
        )}
        {props.items &&
          props.items.items &&
          props.items.items.map((m) => (
            <li key={m.name}>
              <a href={m.url} title={m.name}>
                {m.name}
              </a>
            </li>
          ))}
        {props.renderItems ? props.renderItems() : null}
      </StyledTertiaryMenu>
    </StyledHeaderTertiary>
  );
}

const StyledHeaderTertiary = styled.div`
  display: block;
  padding: ${spacing.space1} 0;
  background-color: ${colors.bgDark};
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${spacing.space3};
    background: linear-gradient(
      to right,
      rgba(86, 88, 87, 0) 0%,
      rgba(86, 88, 87, 1) 99%,
      rgba(86, 88, 87, 1) 100%
    );
  }
`;

const StyledTertiaryMenu = styled.ul`
  margin: ${spacing.space0};
  padding: 0 ${spacing.space2};
  list-style: none;
  display: flex;
  align-items: center;
  overflow: scroll;
  white-space: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  li {
    text-transform: none;
    margin: 0;
    margin-right: ${spacing.space1};
    margin-left: ${spacing.space1};
    font-family: ${fonts.sans};
    font-size: ${sizes.textSmall};
    letter-spacing: ${tracking.small};
    line-height: 1;
    font-weight: ${fontWeight.normal};
    display: flex;
    &.tertiaryMenuTitle {
      position: relative;
      flex-wrap: nowrap;
      flex-shrink: 0;
      text-transform: uppercase;
      letter-spacing: ${tracking.medium};
      margin-left: ${spacing.space0};
      font-weight: ${fontWeight.bold};
      margin-right: ${spacing.space3};
      color: ${colors.textWhite};
      &::after {
        /* Adds the separator line after the title */
        content: '';
        position: absolute;
        right: -${spacing.space2};
        top: -${spacing.space05};
        bottom: -${spacing.space05};
        width: 1px;
        background-color: ${colors.borderLight};
      }
    }
    &:last-child {
      /* Gets the last menu item out of the overflow gradient to allow clicking on it */
      padding-right: ${spacing.space4};
    }

    a {
      color: ${colors.textLightO};
      transition: color ${timing.base};
      text-decoration: none;
      padding-top: ${spacing.space1};
      padding-bottom: ${spacing.space1};
      :hover {
        color: ${colors.textLight};
        text-decoration: none;
      }
    }
  }
`;
