import React from 'react';
import PropTypes from 'prop-types';
import TextStyle from './Style/TextStyle';
import { displayStyles } from './Style/style';

class Text extends React.Component {
  render() {
    const { children, level, html, display, inline, align, noWrap, truncate } =
      this.props;

    if (html) {
      return (
        <TextStyle
          display={display}
          level={level}
          inline={inline ? inline : undefined}
          align={align}
          noWrap={noWrap}
          truncate={truncate ? truncate : undefined}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    }
    return (
      <TextStyle
        display={display}
        level={level}
        inline={inline ? inline : undefined}
        align={align}
        noWrap={noWrap}
        truncate={truncate ? truncate : undefined}
      >
        {children}
      </TextStyle>
    );
  }
}

Text.propTypes = {
  /** Sets the display style */
  display: PropTypes.oneOf(Object.keys(displayStyles)).isRequired,
  /** Sets the HTML element */
  level: PropTypes.string,
  children: PropTypes.node,
  html: PropTypes.string,
  inline: PropTypes.bool,
  align: PropTypes.string,
  noWrap: PropTypes.bool,
  truncate: PropTypes.bool,
};

Text.defaultProps = {
  display: 'body',
  level: 'span',
  children: null,
  html: null,
  inline: false,
  align: 'left',
  noWrap: false,
  truncate: false,
};

export default Text;
